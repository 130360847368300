import {
    faEye,
    faFileExcel,
    faFilePdf,
    faFilter,
    faPrint,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { useEffect, useMemo, useRef, useState } from "react";
  import { ReactTabulator } from "react-tabulator";
  import { connect, useDispatch } from "react-redux";
  import ClosingStockReportForm from "../closingStockReport/ClosingStockReportForm";
  import ClosingStockField from "../closingStockReport/ClosingStockField";
  import { fetchAcYear } from "../../../store/action/acYearAction";
  import { Button, InputGroup } from "react-bootstrap-v5";
  import ReactSelect from "../../../shared/select/reactSelect";
  import { placeholderText } from "../../../shared/sharedMethod";
  import { fetchGstrReport } from "../../../store/action/gstrReportAction";
  import { fetchDailySales } from '../../../store/action/dailySalesAction';
  import { generatePDF, printPDF } from "../pdfUtil";
  import { fetchCompanyConfig } from "../../../store/action/companyConfigAction";
  import Loader from "../../loader/Loader";
  import moment from "moment";
  import { prepareGstArray } from "../../../shared/prepareArray/prepareGstArray";
  import { addToast } from "../../../store/action/toastAction";
  import Select from "react-select";
  // import * as XLSX from 'xlsx';
  
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  
  const ProductSalesTab = (props) => {
    const { stockMovement, acYear, fetchAcYear, fetchGstrReport, dailySales, fetchCompanyConfig, companyConfig, setFromDate, setDateto, fetchDailySales } = props;
    const today = new Date();
    const formattedDate = formatDate(today);
    const numOfDaysAdded = 0;
    const date = today.setDate(today.getDate() + numOfDaysAdded);
    const defaultValue = new Date(date).toISOString().split("T")[0]; // YYYY-MM-dd
  
    const formatDecimal = (cell, formatterParams, onRendered) => {
      const value = cell.getValue();
  
      return value?.toFixed(2);
    };
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [filterdailySales, setFilterdailySales] = useState([]);
    const [fieldShow, setFieldShow] = useState(false);
    const handleFieldClose = () => setFieldShow(!fieldShow);
    const [showField, setShowField] = useState();
    const [minYear, setMinYear] = useState();
    const [maxYear, setMaxYear] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(!show);
    const year = useMemo(() => acYear, [acYear]);
    const [label, setLabel] = useState(false);
    const [payMode, setPayMode] = useState({ value: "", label: "All" });
    const [fetchRangeValue, setFetchRangeValue] = useState({
      category1Name: "",
      category2Name: "",
      category3Name: "",
      unitName: ""
    });
    const paySlipRef = useRef(null);
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const yearRange = `${currentYear}-${nextYear}`;
    const [selectedYearRange, setSelectedYearRange] = useState({
      value: yearRange,
      label: yearRange,
    });
    const [totalData, setTotalData] = useState({});
  
    const [range, setRange] = useState({
      category1Name: "",
      category2Name: "",
      category3Name: "",
      unitName: "",
    });
  
    const [dates, setDate] = useState({
      fromDate: defaultValue,
      toDate: defaultValue,
    });
  
    const columns = [
      {
        title: "Date", field: "date", headerSort: false, bottomCalc: () => 'Total', width: 150, // Custom text for the bottom of this column
      },
      {
        title: "Inv.No", field: "invNo", headerSort: false, bottomCalc: () => 'Total', hozAlign: "center", headerHozAlign: "center", width: 250, // Custom text for the bottom of this column
        bottomCalc: (values, data) => {
          // Filter unique values
          const uniqueValues = [...new Set(values)];
  
          // Custom calculation logic
          let total = 0;
          uniqueValues.forEach(value => {
            total += value || 0; // Ensure values are parsed as numbers
          });
  
          return `Total (${0} invoices)`; // Return total with two decimal places
        }
      },
      {
        title: "Pay Mode", field: "payMode", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 250
      },
      {
        title: "Customer", field: "customer", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 250
      },    
      {
        title: "Sales Value", field: "salesValue", headerSort: false, hozAlign: "right", headerHozAlign: "right", width: 50, bottomCalc: (values, data) => {
          // Filter unique values
          const uniqueValues = [...new Set(values)];
  
          // Custom calculation logic
          let total = 0;
          uniqueValues.forEach(value => {
            total += parseFloat(value) || 0; // Ensure values are parsed as numbers
          });
  
          return total.toFixed(2); // Return total with two decimal places
        }
      },
    ];
  
    const companyDetails = {
      companyName: companyConfig?.companyName || 'Company Name',
      address: `${companyConfig?.attributes?.address1 || ''} , ${companyConfig?.attributes?.address2 || ''}`,
      phoneNumber: companyConfig?.attributes?.phoneNo || 'Phone Number'
    };
  
    const fromDate = useRef();
    const toDate = useRef();
  
    const loadValues = (filter) => {
      return "?fromDate=" + filter + "&toDate=" + filter + "&payMode=";
    }
  
    useEffect(() => {
      fetchAcYear();
    //   fetchCompanyConfig();
      // fetchDailySales(loadValues(defaultValue));
      setFromDate(defaultValue);
      setDateto(defaultValue);
    }, []);
  
    useEffect(() => {
      setFilterdailySales(dailySales);
    }, [dailySales]);
  
    useEffect(() => {
      setMinYear(selectedYearRange?.label?.split("-")[0] + "-04-01");
      setMaxYear(selectedYearRange?.label?.split("-")[1] + "-03-31");
    }, [selectedYearRange]);
  
    const onChange = (e) => {
      const { name, value } = e.target;
      setDate({ ...dates, [name]: value });
      setFromDate(name === "fromDate" ? value : dates.fromDate);
      setDateto(name === "toDate" ? value : dates.toDate);
    }
  
    const fetchData = () => {
      let values = "?fromDate=" + dates?.fromDate + "&toDate=" + dates?.toDate + "&payMode=" + payMode?.value;
      if (moment(dates?.fromDate).isAfter(dates?.toDate)) {
        dispatch(addToast({ text: 'Invalid date range.!', type: 'error' }));
      } else {
        fetchDailySales(values);
      }
    }
  
    const sortInvoices = (b2b) => {
      return b2b.sort((a, b) => {
        // Compare invDate (convert date strings to Date objects)
        const dateA = new Date(a.invDate);
        const dateB = new Date(b.invDate);
        if (dateA - dateB !== 0) {
          return dateA - dateB;
        }
  
        // Compare invNo (alphanumeric comparison)
        if (a.invNo !== b.invNo) {
          return a.invNo.localeCompare(b.invNo);
        }
  
        // Compare customerRegno (alphanumeric comparison)
        return a.customerRegno.localeCompare(b.customerRegno);
      });
    }
  
    const itemsValue = dailySales?.length > 0 &&
      dailySales?.map(data => ({
        date: moment(data?.Date).format('DD-MM-YYYY'),
        invNo: data?.InvNo,
        payMode: data?.PayMode,
        customer: data?.Customer,
        salesValue: data?.SalesValue
      })) || [];
  
    const handlePrintPDF = () => {
      printPDF(paySlipRef, companyDetails, 'Landscape', 'b2bReport', "GSTR1 Summary for B2B,SEZ,DE Invoices - 4A, 4B ,6B ,6C", itemsValue, columns);
    };
  
    const handleExportToPDF = () => {
      generatePDF(paySlipRef, companyDetails, 'Landscape', 'b2bReport', "GSTR1 Summary for B2B,SEZ,DE Invoices - 4A, 4B ,6B ,6C", itemsValue, columns)
        .then(pdf => {
          pdf.save('b2bReport.pdf');
        })
        .catch(error => {
          console.error('Error generating PDF:', error);
        });
    };
  
    const generateJSONFile = (data, filename) => {
      const jsonString = JSON.stringify(data);
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
  
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  
    const handlePaymode = (e) => {
      setPayMode(e);
    }
  
    return (
      <>
        <Loader />
        <div style={{ backgroundColor: "white", height: "100%" }}>
  
          {fieldShow && <ClosingStockField setShowField={setShowField} fieldShow={fieldShow}
            handleFieldClose={handleFieldClose} Title={"Stock Movement Report Form"} />}
  
          <div className="row">
            <div className="col-md-8">
              <div className="row" style={{ marginTop: "-10px" }}>
                <div className="col-md-4">
                  <h4 className="mt-2">From Date</h4>
                  <input
                    id1="dateInput"
                    type="date"
                    className=" form-control rounded text-align-center ml-2 align-item-center mr-15 mb-5"
                    ref={fromDate}
                    defaultValue={defaultValue}
                    name="fromDate"
                    onChange={onChange}
                  ></input>
                </div>
                <div className="col-md-4 mt-2">
                  <h4>To Date</h4>
                  <input
                    id2="dateRequired2"
                    type="date"
                    className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
                    ref={toDate}
                    defaultValue={defaultValue}
                    name="toDate"
                    onChange={onChange}
                    min={dates?.fromDate}
                  ></input>
                </div>
                <div className="col-md-4 mt-2">
                  <h4>Pay Mode</h4>
                  <Select
                    // errors={errors["sales_unit_id"]}
                    value={payMode}
                    options={[{ label: 'All', value: "" },{ label: 'Card', value: "card" }, { label: 'Cash', value: "cash" }, { label: 'Credit', value: "credit" }, { label: 'UPI', value: "upi" }]}
                    id="payMode"
                    onChange={(e) => handlePaymode(e)}
                  // onKeyDown={(e) => keyDown1(e, "payment-method")}
                  // autoFocus
                  />
                </div>
                <div className="col-md-4 mt-2">
                  <h4>Search</h4>
                  <input
                    id2="dateRequired2"
                    type="text"
                    className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  ></input>
                </div>
                <div className="col-md-4 gstrGenerateBtn">
                  <button
                    type="button"
                    className="btn btn-success "
                    onClick={() => fetchData()}
                  >
                    Generate
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-2 ">
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  borderRadius: "10px",
                  width: "220px",
                  height: "60px",
                  gap: "13px",
                  background: "white",
                  marginTop: "-20px",
                  fontSize: "11px",
                  marginLeft: "-20px"
                }}
                ref={paySlipRef}
              >
                <FontAwesomeIcon
                  icon={faPrint}
                  className="fa-2x search-icon"
                  style={{ color: "black", paddingTop: "8px" }}
                  onClick={handlePrintPDF}
                ></FontAwesomeIcon>
  
                <FontAwesomeIcon
                  icon={faFileExcel}
                  className="fa-2x search-icon "
                  style={{
                    color: "green",
                    paddingLeft: "10px",
                    paddingTop: "7px",
                  }}
                //   onClick={downloadUpdatedExcel}
                ></FontAwesomeIcon>
  
                <FontAwesomeIcon
                  icon={faFilePdf}
                  className="fa-2x search-icon"
                  style={{ color: "red", paddingLeft: "10px", paddingTop: "7px" }}
                  onClick={handleExportToPDF}
                ></FontAwesomeIcon>
                {/* <span onClick={handleGenerateJSON}>Json</span> */}
              </button>
            </div>
          </div>
          <h2
            style={{
              // color: "green",
              fontSize: "17px",
              fontWeight: "700"
            }}
          >
            {`Sales Report from ${dates?.fromDate} To ${dates?.toDate}`}
          </h2>
          <div className="row">
            <div className="col-md-9"></div>
            <div
              className="col-md-3"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "-50px",
              }}
            >
            </div>
          </div>
  
          <div className="row">
            <div className="col-md-12 w-100 tabulator-container gstrTable">
              <ReactTabulator
                columns={columns}
                data={itemsValue?.filter((data) => {
                  if (search === "") {
                    return data;
                  } else if (
                    data?.invNo?.toLowerCase().includes(search.toLowerCase()) ||
                    data?.payMode?.toLowerCase().includes(search.toLowerCase()) ||
                    data?.customer?.toLowerCase().includes(search.toLowerCase()) 
                    // data?.salesValue?.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return data;
                  }
                })}
                // ref={pdfRef}
                options={{
                  columnHeaderVertAlign: "bottom",
                  layout: 'fitDataStretch',
                  // responsiveLayout: "collapse",
                  placeholder: "No records found",
                  height: "420px",
                }}
              />
            </div>
          </div>
  
          {show ? <ClosingStockReportForm
            stockMovement={stockMovement}
            show={show} handleClose={handleClose}
            // setItemValues={setItemValues}
            singleStock={itemsValue}
            setRange={setRange}
            Title={"Stock Movement Report Form"}
          // search={search}
          /> : null}
        </div>
      </>
    );
  };
  
  const mapStateToProps = (state) => {
    const { stockMovement, acYear, dailySales, companyConfig } = state;
    return { stockMovement, acYear, dailySales, companyConfig }
  }
  
  export default connect(mapStateToProps, { fetchAcYear, fetchGstrReport, fetchCompanyConfig, fetchDailySales })(ProductSalesTab);
  
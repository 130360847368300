export const environment = {
    // URL: window.location.protocol + '//' + window.location.hostname,
    // URL:"https://infypos.infyom.com"
    //URL:"http://cogitate-001-site18.gtempurl.com"
    URL: "https://cogitate-001-site21.gtempurl.com",
    // URL: "http://cogitate-001-site48.otempurl.com",

    //URL:"http://localhost/VstorePosApi/",
    GoogleAPIKey: "AIzaSyDju7OfsHfK9kzy6RaGSSCS8tueZ93R6aI",
    LOCAL_URL: "http://192.168.0.167:3000"
};

import React from "react";
import { Permissions } from "../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPieChart,
    faUser,
    faTruck,
    faUserGroup,
    faHome,
    faBoxes,
    faPrint,
    faBookmark,
    faBoxOpen,
    faMoneyCheckDollar,
    faMoneyBills,
    faQuoteRight,
    faDollarSign,
    faReceipt,
    faArrowRight,
    faArrowLeft,
    faEnvelope,
    faCartShopping,
    faChartColumn,
    faGear,
    faMapLocation,
    faBasketShopping,
    faSms,
    faCube,
    faFile,
    faRulerHorizontal,
    faLanguage,
    faShieldHalved,
    faClipboardList,
    faPercent,
    faAddressBook,
    faPeopleArrows,
    faTemperatureLow,
    faLayerGroup
} from "@fortawesome/free-solid-svg-icons";
import { getFormattedMessage } from "../shared/sharedMethod";
import { title } from "faker/lib/locales/az";
import { Code } from "@mui/icons-material";

const storedFormData = localStorage.getItem("UserFormCode") || "[]";

const parsedFormData = JSON.parse(storedFormData) || [];

const formCodeItems = parsedFormData.filter(
  (item) => item?.attributes?.visibility === true
);

const userRole=localStorage.getItem("loginUserArray")
    const role =JSON.parse(userRole);
    const roleName =role?.role;

export default [
    {
        title: "dashboard.title",
        name: "dashboard",
        fontIcon: <FontAwesomeIcon icon={faPieChart} />,
        to: "/app/dashboard",
        Code:["R01"],
        // class :formCodeItems?.some(item => item?.attributes?.formCode == 'R01') ? 'd-flex' : 'd-none',
        class:"d-flex",
        permission: Permissions.MANAGE_DASHBOARD,
        items: [
            {
                title: getFormattedMessage("dashboard.title"),
                to: "/app/dashboard",
            },
        ],
    },
    {
        title: "Products.title",
        to: "/app/classification",
        name: "classification",
        Code:["M01"],
        // class :formCodeItems?.some(item => item?.attributes?.formCode == 'M01') ? 'd-flex' : 'd-none',
        class:"d-flex",
        fontIcon: <FontAwesomeIcon icon={faBoxes} />,
        permission: Permissions.MANAGE_STOCKS,
        is_submenu: "true",
        subPath: {
            productsSubPath: "/app/products",
            brandsSubPath: "/app/brands",
            unitsSubPath: "/app/units",
            productGroupsSubPath: "/app/product-groups",
            categoriesSubPath: "/app/product-categories",
        },
        subMenu: [
            {
                title: "product.title",
                to: "/app/products",
                name: "products",
                Code:["M01"],
                // class: formCodeItems?.some(item => item?.attributes?.formCode == "M01" )? "d-flex" : "d-none",
                class:"d-flex",
                fontIcon: <FontAwesomeIcon icon={faBoxes} />,
                permission: Permissions.MANAGE_PRODUCTS,
            },
            {
                title: "brands.title",
                name: "brands",
                fontIcon: <FontAwesomeIcon icon={faBookmark} />,
                to: "/app/brands",
                path: "/app/create-brand",
                Code:["M01"],
                // class: formCodeItems?.some(item => item?.attributes?.formCode == "M01" )? "d-flex" : "d-none",
                class:"d-flex",
                permission: Permissions.MANAGE_BRANDS,
            },
            {
                title: "product.categories.title",
                name: "product categories",
                fontIcon: <FontAwesomeIcon icon={faBoxOpen} />,
                to: "/app/product-categories",
                Code:["M01"],
                // class: formCodeItems?.some(item => item?.attributes?.formCode == "M01" )? "d-flex" : "d-none",
                class:"d-flex",
                permission: Permissions.MANAGE_PRODUCT_CATEGORIES,
            },
            {
                title: "product-groups.title",
                name: "product groups",
                fontIcon: <FontAwesomeIcon icon={faLayerGroup} />,
                to: "/app/product-groups",
                Code:["M01"],
                // class: formCodeItems?.some(item => item?.attributes?.formCode == "M01") ? "d-flex" : "d-none",
                class:"d-flex",
                permission: Permissions.MANAGE_UNITS,
            },
            {
                title: "units.title",
                name: "units",
                fontIcon: <FontAwesomeIcon icon={faQuoteRight} />,
                to: "/app/units",
                Code:["M01"],
                // class: formCodeItems?.some(item => item?.attributes?.formCode == "M01") ? "d-flex" : "d-none",
                class:"d-flex",
                permission: Permissions.MANAGE_UNITS,
            },
            {
                title: "TaxSetup.title",
                name: "TaxSetup",
                fontIcon: <FontAwesomeIcon icon={faPercent} />,
                to: "/app/taxSetup",
                Code:["M01"],
                // class: formCodeItems?.some(item => item?.attributes?.formCode == "M01" )? "d-flex" : "d-none",
                class:"d-flex",
                permission: Permissions.MANAGE_UNITS,
                items: [
                    {
                        title: getFormattedMessage("TaxSetup.title"),
                        to: "/app/taxSetup",
                    },
                ],
            },
            {
                title: "HsnCode.title",
                name: "HsnCode",
                fontIcon: <FontAwesomeIcon icon={faQuoteRight} />,
                to: "/app/hsnCode",
                Code:["M01"],
                // class: formCodeItems?.some(item => item?.attributes?.formCode == "M01") ? "d-flex" : "d-none",
                class:"d-flex",
                permission: Permissions.MANAGE_UNITS,
            },
        ]
    },
    {
        title: "priceList.title",
        to: "/app/price-list",
        name: "products",
        Code:["M05"],
        // class :formCodeItems?.some(item => item?.attributes?.formCode == 'M05') ? 'd-flex' : 'd-none',
        class:"d-flex",
        fontIcon: <FontAwesomeIcon icon={faClipboardList} />,
        permission: Permissions.MANAGE_PRODUCTS,
    },
    // {
    //     title: "priceList.level.title",
    //     to: "/app/pricelevellist",
    //     name: "products",
    //     Code:["M05"],
    //     // class :formCodeItems?.some(item => item?.attributes?.formCode == 'M05') ? 'd-flex' : 'd-none',
    //     class:"d-flex",
    //     fontIcon: <FontAwesomeIcon icon={faClipboardList} />,
    //     permission: Permissions.MANAGE_PRODUCTS,
    // },
    {
        title: "Vendors.title",
        name: "Vendors",
        fontIcon: <FontAwesomeIcon icon={faUser} />,
        to: "/app/Vendors",
        Code:["M02" , "M03", "M04"],
        // class :formCodeItems?.some(item => item?.attributes?.formCode == 'M02' || item?.attributes?.formCode == 'M03'
        //   || item?.attributes?.formCode == 'M04' ) ? 'd-flex' : 'd-none',
        class:"d-flex",
        is_submenu: "true",
        permission: Permissions.MANAGE_PRODUCTS,
        // subPath: {
        //     cutomerSubPath: "/app/posCustomer",
        //     supplierSubpath: "/app/supplier",
        // },
        subMenu: [
            {
                title: "customer.title",
                name: "customer",
                fontIcon: <FontAwesomeIcon icon={faUserGroup} />,
                to: "/app/posCustomer",
                Code:["M02"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'M02') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_CUSTOMERS,
            },
            {
                title: "supplier.title",
                name: "supplier",
                fontIcon: <FontAwesomeIcon icon={faTruck} />,
                to: "/app/supplier",
                Code:["M03"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'M03') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_CUSTOMERS,
            },
            {
                title: "ledger.title",
                name: "ledger",
                fontIcon: <FontAwesomeIcon icon={faUserGroup} />,
                to: "/app/ledger",
                Code:["M04"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'M04') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_CUSTOMERS,
            },
            {
                title: "zone.title",
                name: "zone",
                fontIcon: <FontAwesomeIcon icon={faUserGroup} />,
                to: "/app/zone",
                Code:["M04"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'M04') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_CUSTOMERS,
            },
        ]
    },
    {
        title: "inventory.title",
        name: "masterdata",
        fontIcon: <FontAwesomeIcon icon={faReceipt} />,
        to: "/app/products",
        Code:["T02" ,"T03" , "T01", "S02"],
        // class :formCodeItems?.some(item => item?.attributes?.formCode == 'T02' || item?.attributes?.formCode == 'T01' 
        //     ||item?.attributes?.formCode == 'T03' 
        // ) ? 'd-flex' : 'd-none',
        class:"d-flex",
        is_submenu: "true",
        permission: Permissions.MANAGE_PRODUCTS,
        subPath: {
            purchaseSubPath: "/app/purchases",
            posSubpath: "/app/pos",
        },
        subMenu: [
            {
                title: "Sales (POS)",
                // to: "/app/posMain",
                to: "/app/posSales",
                name: "sales",
                Code:["T02"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'T02') ? 'd-flex' : 'd-none',
                class:"d-flex",
                fontIcon: <FontAwesomeIcon icon={faCartShopping} />,
                permission: Permissions.MANAGE_POS_SCREEN,
            },
            {
                title: "purchase.title",
                to: "/app/purchases",
                name: "purchase",
                Code:["T01"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'T01') ? 'd-flex' : 'd-none',
                class:"d-flex",
                fontIcon: <FontAwesomeIcon icon={faReceipt} />,
                permission: Permissions.MANAGE_PRODUCTS,
            },
            {
                title: "wholesale.title",
                to: "/app/wholesale",
                name: "wholesale",
                Code:["T03"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'T03') ? 'd-flex' : 'd-none',
                class:"d-flex",
                fontIcon: <FontAwesomeIcon icon={faCartShopping} />,
                permission: Permissions.MANAGE_PRODUCTS,
            },
            {
                title: "Sales Order",
                // to: "/app/posMain",
                to: "/app/salesOrder",
                name: "salesOrder",
                Code:["T04"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'T02') ? 'd-flex' : 'd-none',
                class:"d-flex",
                fontIcon: <FontAwesomeIcon icon={faCartShopping} />,
                permission: Permissions.MANAGE_PRODUCTS,
            },
        ],
    },
    {
        title: "accounts.title",
        name: "masterdata",
        fontIcon: <FontAwesomeIcon icon={faReceipt} />,
        to: "/app/products",
        Code:["T02"],
        // class :formCodeItems?.some(item => item?.attributes?.formCode == 'T02' || item?.attributes?.formCode == 'T01' 
        //     ||item?.attributes?.formCode == 'T03' 
        // ) ? 'd-flex' : 'd-none',
        class:"d-flex",
        is_submenu: "true",
        permission: Permissions.MANAGE_PRODUCTS,
        subPath: {
            // purchaseSubPath: "/app/purchases",
            // posSubpath: "/app/pos",
            collectionSubPath: "/app/collection"
        },
        subMenu: [
            {
                title: "Collection",
                // to: "/app/posMain",
                to: "/app/collection",
                name: "collection",
                Code:["T02"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'T02') ? 'd-flex' : 'd-none',
                class:"d-flex",
                fontIcon: <FontAwesomeIcon icon={faCartShopping} />,
                permission: Permissions.MANAGE_PRODUCTS,
            },
        ],
    },
    {
        title: "Payroll.title",
        name: "Payroll",
        fontIcon: <FontAwesomeIcon icon={faMoneyBills} />,
        to: "/app/Payroll",
        Code:["HR01","HR02"],
        // class :formCodeItems?.some(item => item?.attributes?.formCode == 'HR01' || item?.attributes?.formCode == 'HR02') ? 'd-flex' : 'd-none',
        class:"d-flex",
        is_submenu: "true",
        permission: Permissions.MANAGE_PRODUCTS,
        subMenu: [
            {
                title: "employee.title",
                name: "ledger",
                fontIcon: <FontAwesomeIcon icon={faUserGroup} />,
                to: "/app/employees",
                Code:["HR01"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'HR01') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_CUSTOMERS,
            },
            {
                title: "empDepartment.title",
                name: "empDepartment",
                fontIcon: <FontAwesomeIcon icon={faBoxOpen} />,
                to: "/app/empDepartment",
                Code:["HR01"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'HR01') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_CUSTOMERS,
            },
            {
                title: "empDesignation.title",
                name: "empDesignation",
                fontIcon: <FontAwesomeIcon icon={faLayerGroup} />,
                to: "/app/empDesignation",
                Code:["HR01"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'HR01') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_CUSTOMERS,
            },
            // MARK FROM RAM [02-09-2024]
            {
                title: "salaryStructure.title",
                name: "salarystructure",
                fontIcon: <FontAwesomeIcon icon={faUserGroup} />,
                to: "/app/salarystructure",
                Code:["HR02"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'HR02') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_CUSTOMERS,
            },
            // MARK TO RAM [02-09-2024]
            {
                title: "PatRollProcess.title",
                name: "salaryPreparation",
                fontIcon: <FontAwesomeIcon icon={faMoneyBills} />,
                to: "/app/salaryPreparation",
                Code:["HR02"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'HR02') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_CUSTOMERS,
            },
        ]
    },
    {
        title: "reports.title",
        name: "reports",
        fontIcon: <FontAwesomeIcon icon={faChartColumn} />,
        to: "/app/report/pos-salesreport",
        Code:["T03" , "T01" , "R02" , "HR02"],
        // class :formCodeItems?.some(item => item?.attributes?.formCode == 'T03' ||  item?.attributes?.formCode == 'T01' 
        //     ||  item?.attributes?.formCode == 'R02' ||  item?.attributes?.formCode == 'HR02'
        // ) ? 'd-flex' : 'd-none',
        class:"d-flex",
        permission: Permissions.MANAGE_REPORTS,
        subMenu: [
            {
                title: "sales.title",
                name: "Sales",
                fontIcon: <FontAwesomeIcon icon={faCartShopping} />,
                to: "/app/report/pos-salesreport",
                Code:["T03"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'T03') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_REPORTS,
                items: [
                    {
                        title: getFormattedMessage("monthlySales.title"),
                        to: "/app/report/pos-salesreport",
                    },
                ],
            },
            {
                title: "Purchase.title",
                name: "Purchase",
                fontIcon: <FontAwesomeIcon icon={faReceipt}></FontAwesomeIcon>,
                to: "/app/report/pos-purchasereport",
                Code:["T01"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'T01') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_REPORTS,
                items: [
                    {
                        title: getFormattedMessage("monthlyPurchase.title"),
                        to: "/app/report/pos-purchasereport"
                    }
                ]
            },
            {
                title: "stock.reports.title",
                name: "Stock",
                fontIcon: <FontAwesomeIcon icon={faLayerGroup} />,
                to: "/app/report/closingStock",
                Code:["R02"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'R02') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_REPORTS,
                items: [
                    {
                        title: getFormattedMessage("Stock"),
                        to: "/app/report/closingStock",
                    },
                ],
            },
            {
                title: "payrollreport.title",
                name: "Payroll",
                fontIcon: <FontAwesomeIcon icon={faMapLocation}></FontAwesomeIcon>,
                to: "/app/report/payroll",
                Code:["HR02"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'HR02') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_REPORTS,
                items: [
                    {
                        title: getFormattedMessage("payrollreport.title"),
                        to: "/app/report/payroll"
                    }
                ]
            },
            {
                title: "gst.reports.title",
                name: "Gst",
                fontIcon: <FontAwesomeIcon icon={faLayerGroup} />,
                to: "/app/report/gstReport",
                Code:["R03"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'R02') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_REPORTS,
                items: [
                    {
                        title: getFormattedMessage("Gst"),
                        to: "/app/report/gstReport",
                    },
                ],
            },
            {
                title: "gst.summary.title",
                name: "GstSummary",
                fontIcon: <FontAwesomeIcon icon={faLayerGroup} />,
                to: "/app/report/gstSummaryReport",
                Code:["R03"],
                // class :formCodeItems?.some(item => item?.attributes?.formCode == 'R02') ? 'd-flex' : 'd-none',
                class:"d-flex",
                permission: Permissions.MANAGE_REPORTS,
                items: [
                    {
                        title: getFormattedMessage("GstSummary"),
                        to: "/app/report/gstSummaryReport",
                    },
                ],
            },
        ]
    },
    // {
    //     title: "masterdata.title",
    //     name: "masterdata",
    //     fontIcon: <FontAwesomeIcon icon={faBoxes} />,
    //     to: "/app/products",
    //     class: "d-flex",
    //     is_submenu: "true",
    //     permission: Permissions.MANAGE_PRODUCTS,
    //     subPath: {
    //         productsSubPath: "/app/products",
    //         barcodeSubPath: "/app/print/barcode",
    //         monthlysalesSubPath: "/app/monthlysales",
    //         employees: "/app/employees",
    //     },
    //     subMenu: [
    //         {
    //             title: "User.title",
    //             name: "Users",
    //             fontIcon: <FontAwesomeIcon icon={faUser} />,
    //             to: "/app/users",
    //             class: "d-flex",
    //             permission: Permissions.MANAGE_UNITS,
    //             items: [
    //                 {
    //                     title: getFormattedMessage("User.title"),
    //                     to: "/app/users",
    //                 },
    //             ],
    //         },
    //     ],
    // },

    {
        title: "setup.title",
        name: "masterdata",
        fontIcon: <FontAwesomeIcon icon={faGear} />,
        to: "/app/products",
        class: "d-flex",
        Code:["U01"],
        is_submenu: "true",
        permission: Permissions.MANAGE_PRODUCTS,
        subPath: {
            purchaseSubPath: "/app/purchases",
            posSubpath: "/app/pos",
        },
        subMenu: [
            {
                title: "companyconfig.list.title",
                name: "companyconfig",
                fontIcon: <FontAwesomeIcon icon={faUserGroup} />,
                to: "/app/companies",
                class: "d-flex",
                permission: Permissions.MANAGE_CUSTOMERS,
            },
            // {
            //     title: "companyconfig.title",
            //     name: "companyconfig",
            //     fontIcon: <FontAwesomeIcon icon={faUserGroup} />,
            //     to: "/app/companyconfig",
            //     class: "d-flex",
            //     permission: Permissions.MANAGE_CUSTOMERS,
            // },
            {
                title: "User.title",
                name: "Users",
                fontIcon: <FontAwesomeIcon icon={faUser} />,
                to: "/app/users",
                // class: roleName?.toUpperCase() == "ADMINISTRATOR" ? "d-flex" : "d-none",
                class:"d-flex",
                permission: Permissions.MANAGE_UNITS,
                items: [
                    {
                        title: getFormattedMessage("User.title"),
                        to: "/app/users",
                    },
                ],
            },
        ],
    },
];

import apiConfig from "../../config/apiConfig";
import { apiBaseURL, saleActionType, toastType, cartItem, saleSingleActionType, collectionActionType } from "../../constants";
import { addToast } from "./toastAction";
import {
    addInToTotalRecord,
    removeFromTotalRecord,
    setTotalRecord,
} from "./totalRecordAction";
import { setLoading } from "./loadingAction";
import requestParam from "../../shared/requestParam";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { callSaleApi } from "./saleApiAction";
import { setSavingButton } from "./saveButtonAction";
import { setLoader } from "./loaderAction";

export const fetchCollectionListing =
    (filter = {}, isLoading = true) =>
        async (dispatch) => {
            dispatch(setLoader(true));
            if (isLoading) {
                // dispatch(setLoading(true));
            }
            let url = apiBaseURL.COLLECTION;
            apiConfig
                .get(url)
                .then((response) => {
                    if (response?.data?.success) {
                        dispatch({
                            type: collectionActionType.FETCH_COLLECTIONS,
                            payload: response?.data?.data,
                        });

                        if (isLoading) {
                            dispatch(setLoading(false));
                        }
                        dispatch(setLoader(false));
                    } else {
                        dispatch(setLoader(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };
import { validate } from "email-validator";
import apiConfig from "../../config/apiConfig";
import { apiBaseURL, ledgerInvoiceActionType, ledgerSalesSummaryActionType, ledgerStatementActionType, toastType } from "../../constants";
import { setLoading } from "./loadingAction";
import { addToast } from "./toastAction";
import { setLoader } from "./loaderAction";

export const fetchLedgerSalesSummaryReport =
    (values, filter = {}, isLoading = true) =>
        async (dispatch) => {
            if (isLoading) {
                dispatch(setLoading(true));
                dispatch(setLoader(true));
            }

            let url = apiBaseURL.LEDGER_SALE_SUMMARY + values;
            apiConfig
                .get(url)
                .then((response) => {
                    if (response?.data?.success) {
                        dispatch({
                            type: ledgerSalesSummaryActionType.FETCH_LEDGER_SALE_SUMMARY,
                            payload: response?.data?.data,
                        });
                        // dispatch(addToast({ text: response?.data?.message, type: toastType.SUCCESS }));
                    } else {
                        // dispatch(addToast({ text: response?.data?.message, type: toastType.ERROR }));   
                    }
                    if (isLoading) {
                        dispatch(setLoader(false));
                        dispatch(setLoading(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };

export const fetchLedgerInvoiceReport =
    (values, filter = {}, isLoading = true) =>
        async (dispatch) => {
            if (isLoading) {
                dispatch(setLoading(true));
                dispatch(setLoader(true));
            }

            let url = apiBaseURL.LEDGER_INVOICE + values;
            apiConfig
                .get(url)
                .then((response) => {
                    if (response?.data?.success) {
                        dispatch({
                            type: ledgerInvoiceActionType.FETCH_LEDGER_INVOICE,
                            payload: response?.data?.data,
                        });
                        // dispatch(addToast({ text: response?.data?.message, type: toastType.SUCCESS }));
                    } else {
                        // dispatch(addToast({ text: response?.data?.message, type: toastType.ERROR }));   
                    }
                    if (isLoading) {
                        dispatch(setLoader(false));
                        dispatch(setLoading(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };

export const fetchLedgerStatementReport =
    (values, filter = {}, isLoading = true) =>
        async (dispatch) => {
            if (isLoading) {
                dispatch(setLoading(true));
                dispatch(setLoader(true));
            }

            let url = apiBaseURL.LEDGER_INVOICE + values;
            apiConfig
                .get(url)
                .then((response) => {
                    if (response?.data?.success) {
                        dispatch({
                            type: ledgerStatementActionType.FETCH_LEDGER_STATEMENT,
                            payload: response?.data?.data,
                        });
                        // dispatch(addToast({ text: response?.data?.message, type: toastType.SUCCESS }));
                    } else {
                        // dispatch(addToast({ text: response?.data?.message, type: toastType.ERROR }));   
                    }
                    if (isLoading) {
                        dispatch(setLoader(false));
                        dispatch(setLoading(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };
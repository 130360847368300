import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import UsersForm from './UsersForm';
import { getFormattedMessage } from '../../shared/sharedMethod';
import { useNavigate } from 'react-router';
import MasterLayout from '../MasterLayout';
import HeaderTitle from '../header/HeaderTitle';
import { Filters } from '../../constants';
import EmployeeForm from './EmployeeForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from '../loader/Loader';

export default function CreateEmployees() {

    const [formcode, setFormCode] = useState("HR01");
    const navigate =useNavigate();
    useEffect(() => {
      ;
      const storedFormData = localStorage.getItem("UserFormCode");
  
      if (storedFormData) {
        const parsedFormData = JSON.parse(storedFormData);
  
        if (parsedFormData.length > 0) {
          const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permissionAdd);
          if(!formCodeItems.length > 0){
              navigate("/app/dashboard");
          }
        } else {
          navigate("/app/dashboard");
        }
      } 
    }, []);

    return (
        <MasterLayout>
            {/* <HeaderTitle title={getFormattedMessage('emp.creation')} to='/app/employees' /> */}
            {/* <UsersForm addUsersData={addUsersData} /> */}
            <Loader/>
            <EmployeeForm/>
        </MasterLayout>
    )
}

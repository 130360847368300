import { priceListActionType } from "../../constants";

export default (state = [], action) => {
  switch (action.type) {
    case priceListActionType.GET_ALL_PRICELIST:
      return action.payload;
    case priceListActionType.DELETE_PRICE:
      const filtered = state.filter((list) => {
        return (
          list?.attributes?.mrp !== action?.payload?.mrp ||
          list.itemId !== action?.payload?.priceId ||
          list?.attributes?.batchNo !== action?.payload?.batchNo
        );
      });
      return filtered;
    case priceListActionType.EDIT_PRICE:
      const edited_response = state.map((list) => {
        return list?.itemId === action?.payload?.itemId &&
          list?.attributes?.mrp === action?.payload?.attributes?.mrp &&
          list?.attributes?.batchNo === action?.payload?.attributes?.batchNo
          ? action?.payload
          : list;
      });
      return edited_response;
    case priceListActionType.ADD_PRICE:
      return [...state, action.payload];
    default:
      return state;
  }
};

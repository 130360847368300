import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { fetchSale, fetchSingleSale } from '../../store/action/salesAction';
import HeaderTitle from '../header/HeaderTitle';
import MasterLayout from '../MasterLayout';
import WholeSaleForm from './WholeSaleForm';
import { getFormattedMessage } from '../../shared/sharedMethod';
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import {fetchAllWarehouses} from '../../store/action/warehouseAction';
import {fetchAllSuppliers} from '../../store/action/supplierAction';
import { fetchLedgers, fetchLedger } from '../../store/action/ledgerAction';

const EditWholeSales = (props) => {

    const { fetchSingleSale, saleSingle, fetchAllWarehouses, fetchAllSuppliers, fetchLedger, warehouses, suppliers, ledger } = props;
    const { id } = useParams();

    // console.log('Sales id', id);

    const [formcode, setFormCode] = useState("T03");
      const navigate =useNavigate();
      useEffect(() => {
        ;
        const storedFormData = localStorage.getItem("UserFormCode");
    
        if (storedFormData) {
          const parsedFormData = JSON.parse(storedFormData);
    
          // console.log("Parsed Form Data:", parsedFormData);
          if (parsedFormData.length > 0) {
            const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permisssionUpdate);
            // console.log("Form Code Items:", formCodeItems);
            if(!formCodeItems.length > 0){
                navigate("/app/dashboard");
            }
          } else {
            navigate("/app/dashboard");
          }
        } 
      }, []);

    useEffect(() => {
        fetchAllWarehouses();
        fetchAllSuppliers();
        fetchLedger();
        fetchSingleSale(id);
    }, []);

    useEffect(() => {
        console.log(saleSingle);
    }, [saleSingle]);

    const itemsValue = saleSingle;

    return (
        <MasterLayout>
            <TopProgressBar />
            <WholeSaleForm singleSale={itemsValue} id={id} warehouses={warehouses} ledger={ledger}
                          suppliers={suppliers}/>
        </MasterLayout>
    )
};

const mapStateToProps = (state) => {
    const { saleSingle, suppliers, totalRecord, ledger, warehouses } = state;
    return { saleSingle, suppliers, totalRecord, ledger, warehouses }
};

export default connect(mapStateToProps, { fetchSingleSale, fetchAllWarehouses, fetchAllSuppliers, fetchLedgers, fetchLedger })(EditWholeSales);


import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Image } from "react-bootstrap-v5";
import * as EmailValidator from "email-validator";
import { loginAction } from "../../store/action/authAction";
import TabTitle from "../../shared/tab-title/TabTitle";
import { fetchFrontSetting } from "../../store/action/frontSettingAction";
import { Tokens, userActionType } from "../../constants";
import { createBrowserHistory } from "history";
import {
  getFormattedMessage,
  placeholderText,
} from "../../shared/sharedMethod";
import FooterLogin from "../footer/FooterLogin";
import Footer from "../footer/Footer";
// import VStoreImage from "../../assets/images/vstore.png";
import VStoreImage from "../../assets/images/Billq.jpeg";
import Logindes from "../../assets/images/logindes1.png";
// import multitaskingconcept from "../../assets/images/multitaskingconcept.png";
import "../../assets/scss/custom/custom.scss";
import { fetchCompanyConfig } from "../../store/action/companyConfigAction";

const Login = (props) => {
  const { fetchCompanyConfig,companyConfig} =props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const { frontSetting } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem(Tokens.ADMIN);

  const words = ["Billings", "Inventory", "Business"];
  let i = 0;
  let timer;

  const [loginInputs, setLoginInputs] = useState({
    email: "",
    userName:"",
    password: "",
    
  });

  useEffect(() => {
    dispatch(fetchFrontSetting());
    if (token) {
      history.push(window.location.pathname);
    }
  }, []);


  useEffect(() => {
    const wordElement = document.getElementById('word');
    if (wordElement) {
      typingEffect(); 
    }
  }, []);
  
  const typingEffect = () => {
    const wordElement = document.getElementById('word');
    if (!wordElement) return; 
  
    let word = words[i].split("");
    var loopTyping = function () {
      if (word.length > 0) {
        wordElement.innerHTML += `<span class="${"word" + i}">${word.shift()}</span>`;
      } else {
        deletingEffect();
        return false;
      }
      timer = setTimeout(loopTyping, 126);
    };
    loopTyping();
  };
  
  function deletingEffect() {
    const wordElement = document.getElementById('word');
    if (!wordElement) return; 
  
    let word = words[i].split("");
    var loopDeleting = function () {
      if (word.length > 0) {
        word.pop();
        wordElement.innerHTML = `<span class="${"word" + i}">${word.join("")}</span>`;
      } else {
        if (words.length > (i + 1)) {
          i++;
        } else {
          i = 0;
        }
        typingEffect();
        return false;
      }
      timer = setTimeout(loopDeleting, 126);
    };
    loopDeleting();
  }
  

  const [errors, setErrors] = useState({
    email: "",
    userName:"",
    password: "",
  });

  const handleValidation = () => {
    let errorss = {};
    let isValid = false;
    if ((loginInputs["email"])&&(loginInputs["userName"])) {
      if (!loginInputs["email"]) {
        errorss["email"] = getFormattedMessage(
          "globally.input.email.validate.label"
        );
      } 
      else (!loginInputs["userName"]) 
        errorss["userName"] = getFormattedMessage(
          "globally.input.email.validate.label"
        );
       
  
  }
    else if (!loginInputs["password"]) {
      errorss["password"] = getFormattedMessage(
        "user.input.password.validate.label"
      );
    } else {
      isValid = true;
    }
    setErrors(errorss);
    setLoading(false);
    return isValid;
  };

  const prepareFormData = () => {
    // const formData = new FormData();
    let formData = {
      "email": loginInputs.email,
    //  "userName":loginInputs.userName,
      "password": loginInputs.password
    }
    // formData.append("email", loginInputs.email);
    // formData.append("password", loginInputs.password);
    // formData.append("language_code", localStorage.getItem("updated_language"));
    return formData;
  };

  const [keepMeLoggedIn, setkeepMeLoggedIn] = useState(false);
    // {
    //    keepMeLoggedIn: false 
    // }
  // );

  

  const checkedChange = (e) => {
    const { checked } = e.target;
    setkeepMeLoggedIn(checked); 
  };
  


  const onLogin = async (e) => {
    
    e.preventDefault();
    const valid = handleValidation();
    if (valid) {
      setLoading(true);
      dispatch(loginAction(prepareFormData(loginInputs), navigate, setLoading
      // ,keepMeLoggedIn
    ));
      const dataBlank = {
        email: "",
        userName:"",
        password: "",
      };
       setLoginInputs(dataBlank);
    }

   };

  const handleChange = (e) => {
    e.persist();
    setLoginInputs((inputs) => ({
      ...inputs,
      [e.target.name]: e.target.value,
    }));
    setErrors("");
  };

 

  const emailInputRef = useRef(null); // Ref for Email/UserName input
  const passwordInputRef = useRef(null); // Ref for Password input


  const handleKeyDown = (e, inputName) => {
    if (e.key === "ArrowDown") {
    
      if (inputName === "email") {
        passwordInputRef.current.focus();
      }
    } else if (e.key === "ArrowUp") {
  
      if (inputName === "password") {
        emailInputRef.current.focus();
      }
    }
  };


  const hello = () => {
    alert('hello')
  }

  useEffect(() => {
    fetchCompanyConfig();
  },[])

  const companyName = companyConfig?.companyName

  return (
    <div className="content d-flex flex-column flex-column-fluid login-bg">
      <div className="d-flex flex-column-fluid">
        <div className="d-flex w-30 flex-grow-1 align-items-center justify-content-center">
          <div className="px-5 width-450 px-sm-7 py-10 mx-auto">
            <h1 className="text-dark text-center mb-7 login-title">
              {/* Manage your */}
              {/* <span>Billings / Inventory / Business</span> */}
              {/* <div id="flip">
                <div><div><b>your Billings</b></div></div>
                <div><div><b>your Inventory</b></div></div>
                <div><div><b>your Business</b></div></div>
              </div> */}
              <div className="flex">
                <span className="logintitle1" >Manage your</span>
                <p className="header-sub-title" id="word"></p>
                <p className="header-sub-title blink">&nbsp;</p>
              </div>
              {/* <span id="app"></span> */}
              <span className="logintitle3">Smartly.!</span>
            </h1>
            <img
              className="logindes"
              src={Logindes}
              width="40%"
              height="auto"
            />
            {/* <img
              className="multitaskingconcept"
              src={multitaskingconcept}
            /> */}
            <img
              className="login-logo"
              src={VStoreImage}
              alt="Description"
              width="40%"
              height="auto"
            />
           {/* <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-5"> */}
            <label className="login-version fw-bold ">
            <i className="version">v2.0 Oct 2024</i>
            </label>
            {/* </div>
           </div> */}
          </div>
        </div>

        <div className="d-flex flex-grow-1 align-items-center justify-content-end login-right">
          <div className="bg-theme-white rounded-15 width-450 shadow-md px-3 px-sm-4 py-10 mxStyle">
            <h1 className="text-dark text-center mb-7">
               {companyName ? ("Hello,"+companyName ):'Hello,' }
            </h1>

            <form>
              <div className="mb-sm-7 mb-4">
                <label className="form-label">
                  {getFormattedMessage("globally.input.login.email.label")} :
                </label>
                <span className="required" />
                <input
                  placeholder={placeholderText(
                    "globally.input.login.email.placeholder.label"
                  )}
                  required
                  value={loginInputs.email }
                  value1={loginInputs.userName}
                  className="form-control"
                  type="text"
                  name="email"
                  name1="userName"
                  autoComplete="off"
                  onChange={(e) => handleChange(e)}
                  ref={emailInputRef}
                  onKeyDown={(e) => handleKeyDown(e, "email")}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2">
                  {errors["email"] ? errors["email"] : null}
                  {errors["userName"] ? errors["userName"] : null}
                </span>
              </div>

              <div className="mb-sm-7 mb-4">
                <div className="d-flex justify-content-between mt-n5">
                  <div className="d-flex justify-content-between w-100">
                    <label className="form-label">
                      {getFormattedMessage("user.input.password.label")}
                      :
                      <span className="required" />
                    </label>
                    <Link
                      to="/forgot-password"
                      className="link-info fs-6 text-decoration-none"
                    >
                      {getFormattedMessage("login-form.forgot-password.label")}
                    </Link>
                  </div>
                </div>
                <input
                  className="form-control"
                  type="password"
                  name="password"
                  placeholder={placeholderText(
                    "user.input.password.placeholder.label"
                  )}
                  autoComplete="off"
                  required
                  value={loginInputs.password}
                  onChange={(e) => handleChange(e)}
                  ref={passwordInputRef}
                  onKeyDown={(e) => handleKeyDown(e, "password")}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2">
                  {errors["password"] ? errors["password"] : null}
                </span>
              </div>
              <div className="d-flex justify-content-between mt-n5">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <label className="form-label mt-2">
                    <input className="mx-1" 
                      type="checkbox"
                      name="keepMeLoggedIn" 
                      checked={keepMeLoggedIn}
                      onChange={checkedChange}
                    />
                    {getFormattedMessage("user.input.keepme.label")}
                  </label>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary w-100 loginbtn"
                      onClick={(e) => onLogin(e)}
                    >
                      {loading ? (
                        <span className="d-block">
                          {getFormattedMessage("globally.loading.label")}
                        </span>
                      ) : (
                        <span>
                          {getFormattedMessage("login-form.login-btn.label")}
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <div className='container-fluid'> */}
      <FooterLogin />
      {/* </div> */}
    </div>
  );
};

const mapStateToProps =(state) => {
  const {companyConfig} =state;
  return {companyConfig}
}

export default connect(mapStateToProps,{fetchCompanyConfig}) (Login);

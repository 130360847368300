import { employeeActionType } from '../../constants';

export default (state = [], action) => {
    switch (action.type) {
        case employeeActionType.ADD_EMPLOYEE:
            return action.payload;

        // case "CLEAR_SINGLE_EMPLOYEE":
        //     return {
        //           singleEmployee: {},  
        //         };
        default:
            return state;
        }
    };
import React, { useState, useEffect } from 'react';
import { Col, Form, InputGroup, Row, Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addCustomer } from '../../store/action/customerAction';
import { connect, useDispatch } from 'react-redux';
import { addLedger } from '../../store/action/ledgerAction';
import { addToast } from '../../store/action/toastAction';
import { toastType } from '../../constants';
import moment from 'moment';

const CreatePayment = (props) => {
    const { show, handleClose, addLedger, setPayment, cartCalc, purchase, editdata } = props;
    const dispatch = useDispatch();
    const [orderValue, setOrderValue] = useState(10500);
    const [receivedAmount, setReceivedAmount] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [refNumber, setRefNumber] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [cash, setCash] = useState(false);
    const [cashPay, setCashPay] = useState(false);
    const [bankPay, setBankPay] = useState(false);
    const [qrPay, setQrPay] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    // Validation states
    const [errors, setErrors] = useState({});

    const handleSave = () => {
        let validationErrors = {};

        if (!receivedAmount) {
            validationErrors.receivedAmount = "Received amount is required.";
        } else if (isNaN(receivedAmount) || receivedAmount <= 0) {
            validationErrors.receivedAmount = "Enter a valid positive number.";
        }

        if (receivedAmount > orderValue) {
            validationErrors.receivedAmount = "Received amount cannot be greater than order value.";
        }

        if (!cashPay && !bankPay && !qrPay) {
            validationErrors.paymentMode = "Select a payment mode.";
        }

        if (paymentMode === 'Bank' && (!refNumber || !paymentDate)) {
            validationErrors.refDetails = "Bank mode requires Ref. No and Date.";
        }

        if ((bankPay || cashPay) && refNumber === '') {
            validationErrors.refDetails = "Ref. No & Date is required.";
        }

        if (Object.keys(validationErrors).length === 0) {
            // Submit the form data

            const formValue = {
                orderValue: cartCalc[0]?.bill ? cartCalc[0]?.bill : 0.00,
                receivedAmount: receivedAmount,
                paymentMode: paymentMode,
                refNumber: refNumber,
                paymentDate: paymentDate
            };
            setPayment(formValue);
            handleClose(); // Close modal after saving
            setReceivedAmount('');
            setPaymentMode('');
            setRefNumber('');
            setPaymentDate('');
            validationErrors = {};
        } else {
            setErrors(validationErrors);
        }
    };

    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1; // JavaScript months are 0-based

        let startYear, endYear;

        if (month >= 4) {
            startYear = year;
            endYear = year + 1;
        } else {
            startYear = year - 1;
            endYear = year;
        }

        const startDate = new Date(`${startYear}-04-01`);
        const endDate = new Date(`${endYear}-03-31`);

        // Helper function to format date as DD/MM/YYYY
        const formatDate = (date) => {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };

        setStartDate(startDate);
        setEndDate(endDate);
        return {
            startDate: formatDate(startDate),
            endDate: formatDate(endDate)
        };
    },[]);

    useEffect(() => {
        // console.log("editdata", editdata);
        if(editdata){
            setRefNumber(editdata?.attributes?.salesOrder4[0]?.refNo);
            setPaymentMode(editdata?.attributes?.salesOrder4[0]?.paymentType === 'cash' ? true : editdata?.attributes?.salesOrder4[0]?.paymentType === 'bank' ? true : editdata?.attributes?.salesOrder4[0]?.paymentType === 'qr' ? true : false);
            setReceivedAmount(editdata?.attributes?.salesOrder4[0]?.amount);
            setPaymentDate(moment(editdata?.attributes?.salesOrder4[0]?.refDate).format('YYYY-MM-DD'));
        }
    }, [editdata]);

    const keyDown = (e, name) => {
        if ((e.key === 'Enter' || e.key === 'ArrowRight' || e.key === 'Tab' || e.key === 'ArrowDown') && name === 'receivedAmount') {
            e.preventDefault();
            document.getElementById('cash-payment').focus();
            document.getElementById('cash-payment').classList.add('active');
        }
        if ((e.key === 'Enter' || e.key === 'ArrowRight' || e.key === 'Tab' || e.key === 'ArrowDown') && name === 'refNo') {
            document.getElementById('refDate').focus();
            document.getElementById('refDate').select();
        }
        if ((e.key === 'Enter' || e.key === 'ArrowRight' || e.key === 'Tab' || e.key === 'ArrowDown') && name === 'refDate') {
            e.preventDefault();
            document.getElementById('pay-submit').focus();
        }

        if ((e.key === 'ArrowLeft' || e.key === 'ArrowUp') && name === 'refDate') {
            e.preventDefault();
            document.getElementById('refNo').focus();
            document.getElementById('refNo').select();
        }
        if ((e.key === 'ArrowLeft' || e.key === 'ArrowUp') && name === 'refNo') {
            e.preventDefault();
            document.getElementById('qr-payment').focus();
            document.getElementById('qr-payment').classList.add('active');
        }
        if ((e.key === 'ArrowLeft' || e.key === 'ArrowUp') && name === 'receivedAmount') {
            e.preventDefault();
        }
    }

    const cashPressHandler = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            document.getElementById('cash-img').click();
            // document.getElementById('cash_received').focus();
            // setPaySelect(true);
            setCashPay(true);
            document.getElementById('cash-payment').classList.add('active');
            document.getElementById('bank-payment').classList.remove('active');
            document.getElementById('qr-payment').classList.remove('active');
            document.getElementById('pay-submit').focus();

        }
        else if (e.key === 'ArrowUp') {
            e.preventDefault();
            document.getElementById('receivedAmount').focus();
            document.getElementById('receivedAmount').select();
            document.getElementById('cash-payment').classList.remove('active');
            setCash(false);
            // setPaySelect(false);
        }
        else if (e.key == 'ArrowRight' || e.key === 'Tab' || e.key === 'ArrowDown') {
            e.preventDefault();
            document.getElementById('bank-payment').focus();
            document.getElementById('bank-payment').classList.add('active');
            document.getElementById('cash-payment').classList.remove('active');
        }
    }

    const cardPressHandler = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // setPaySelect(true);
            // document.getElementById('pay-submit').click();
            // onCashPayment(e);
            setBankPay(true);
            document.getElementById('cash-payment').classList.remove('active');
            document.getElementById('bank-payment').classList.add('active');
            document.getElementById('qr-payment').classList.remove('active');
            setTimeout(() => {
                document.getElementById('refNo').focus();
                document.getElementById('refNo').select();
            }, 1000);
        }
        else if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
            e.preventDefault();
            document.getElementById('cash-payment').focus();
            document.getElementById('cash-payment').classList.add('active');
            document.getElementById('bank-payment').classList.remove('active');
        }
        else if (e.key === 'ArrowRight' || e.key === 'Tab' || e.key === 'ArrowDown') {
            e.preventDefault();
            document.getElementById('qr-payment').focus();
            document.getElementById('qr-payment').classList.add('active');
            document.getElementById('bank-payment').classList.remove('active');
        }

    }

    const qrPressHandler = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // setPaySelect(true);
            // document.getElementById('pay-submit').click();
            // onCashPayment(e);
            setQrPay(true);
            document.getElementById('cash-payment').classList.remove('active');
            document.getElementById('bank-payment').classList.remove('active');
            document.getElementById('qr-payment').classList.add('active');
            document.getElementById('pay-submit').focus();
        }
        else if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
            e.preventDefault();
            document.getElementById('bank-payment').focus();
            document.getElementById('bank-payment').classList.add('active');
            document.getElementById('qr-payment').classList.remove('active');
        }
        else if (e.key === 'ArrowRight' || e.key === 'Tab' || e.key === 'ArrowDown') {
            e.preventDefault();
            // document.getElementById('qr-payment').focus();
            // document.getElementById('qr-payment').classList.add('active');
            // document.getElementById('cash-payment').classList.remove('active');
            document.getElementById('qr-payment').classList.remove('active');
            document.getElementById('pay-submit').focus();
            // setPaySelect(true);
        }

    }

    const handlePayment = (method) => {
        
        if (method === 'cash') {
            setCash(true);
        }
        else {
            setCash(false);
        }

        if (method === 'cash') {
            setCashPay(true);
            setBankPay(false);
            setQrPay(false);
        }
        else if (method === 'bank') {
            setBankPay(true);
            setCashPay(false);
            setQrPay(false);
        }
        else if (method === 'qr') {
            setQrPay(true);
            setBankPay(false);
            setCashPay(false);
        }
        // setPaySelect(true);
        // setBalance(parseFloat(0).toFixed(2));
        // setData(userdata);
    }

    const negativeKeydown = (e) => {
        if (['e', 'E', '+', '-', '.'].includes(e.key)) {
            e.preventDefault();
        }
    }

    const buttonKeydown = (e, name) => {
        if (e.key === 'Enter' && name === 'pay-submit') {
            e.preventDefault();
            handleSave();
        } else if (e.key === 'Enter' && name === 'pay-cancel') {
            handleClose();
        }

        if (e.key === 'ArrowRight' && name === 'pay-submit') {
            document.getElementById('pay-cancel').focus();
        }

        if (e.key === 'ArrowLeft' || e.key === 'ArrowUp' && name === 'pay-submit') {
            if (bankPay) {
                document.getElementById('refDate').focus();
                document.getElementById('refDate').select();
            } else {
                document.getElementById('qr-payment').focus();
                document.getElementById('qr-payment').classList.add('active');
            }
        } else if (e.key === 'ArrowLeft' && name === 'pay-cancel') {
            document.getElementById('pay-submit').focus();
        }
    }

    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header className='px-2 py-4 customer-modal' closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                    <h3 className='' style={{ fontWeight: 'bold' }}>Payment</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <h1 className='mb-6'>001 | Mr. {purchase?.supplier?.label} | ₹ {parseFloat(cartCalc[0]?.bill).toFixed(2)}</h1>
                </div>
                <Form>
                    <Form.Group as={Row} className="mb-6" controlId="formPlaintextEmail">
                        <Form.Label column sm="3">
                            Order Value:
                        </Form.Label>
                        <Col sm="4">
                            <InputGroup>
                                <InputGroup.Text>₹</InputGroup.Text>
                                <Form.Control type="text" value={parseFloat(cartCalc[0]?.bill).toFixed(2)} readOnly disabled />
                            </InputGroup>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-6" controlId="formPlaintextPassword">
                        <Form.Label column sm="3">
                            Received:
                        </Form.Label>
                        <Col sm="4">
                            <InputGroup>
                                <InputGroup.Text>₹</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    id="receivedAmount"
                                    value={receivedAmount}
                                    onChange={(e) => setReceivedAmount(e.target.value)}
                                    isInvalid={!!errors.receivedAmount}
                                    onKeyDown={(e) => {
                                        negativeKeydown(e);
                                        keyDown(e, 'receivedAmount');
                                    }}
                                    onWheel={(e) => e.target.blur()}
                                    autoFocus
                                />
                                <Form.Control.Feedback type="invalid">{errors.receivedAmount}</Form.Control.Feedback>
                            </InputGroup>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-6" controlId="formPlaintextEmail">
                        <Form.Label column sm="3">
                            Payment Mode:
                        </Form.Label>
                        <Col sm="8">
                            <div className='row payment_mode'>
                                {/* <img src={Cash_coins} alt="Payment Mode Icon" /> */}
                                <button id="cash-payment" type='button' className={cashPay ? 'col-3 pay_icon_div active' : 'col-3 pay_icon_div'} style={{ border: "none" }} onKeyDown={(e) => cashPressHandler(e)}>
                                    <img id="cash-img" src="/images/Cash_coins.png" className='cashImg' style={{ width: "100%" }} alt="Payment Mode Icon" onClick={() => handlePayment('cash')} />
                                </button>
                                <button id="bank-payment" type='button' className={bankPay ? 'col-3 pay_icon_div active' : 'col-3 pay_icon_div'} style={{ border: "none" }} onKeyDown={(e) => cardPressHandler(e)}>
                                    <img src="/images/creditCard.png" id="card-img" className='creditCardImg' style={{ width: "100%" }} alt="Payment Mode Icon" onClick={() => handlePayment('bank')} />
                                </button>
                                <button id="qr-payment" type='button' className={qrPay ? 'col-3 pay_icon_div active' : 'col-3 pay_icon_div'} style={{ border: "none" }} onKeyDown={(e) => qrPressHandler(e)}>
                                    <img src="/images/qrCode.png" id='qr-img' className='qrImg' style={{ width: "100%" }} alt="Payment Mode Icon" onClick={() => handlePayment('qr')} />
                                </button>
                            </div>
                            {errors.paymentMode && <div className="text-danger">{errors.paymentMode}</div>}
                        </Col>
                    </Form.Group>

                    {(bankPay || qrPay) && (
                        <Form.Group as={Row} className="mb-6" controlId="formPlaintextEmail">
                            <Form.Label column sm="3">
                                Ref.No & Date:
                            </Form.Label>
                            <Col sm="4">
                                {/* <InputGroup>
                                <Form.Control type="text" value={orderValue.toFixed(2)} readOnly />
                            </InputGroup> */}
                                <Form.Control
                                    type="text"
                                    id="refNo"
                                    value={refNumber}
                                    onChange={(e) => setRefNumber(e.target.value)}
                                    isInvalid={!!errors.refDetails}
                                    onKeyDown={(e) => keyDown(e, 'refNo')}
                                />
                            </Col>
                            <Col sm="4">
                                <Form.Control
                                    type="date"
                                    id="refDate"
                                    value={paymentDate}
                                    onChange={(e) => setPaymentDate(e.target.value)}
                                    isInvalid={!!errors.refDetails}
                                    onKeyDown={(e) => keyDown(e, 'refDate')}
                                    // min={moment(endDate).format('YYYY-MM-DD')}
                                    // max={moment(startDate).format('YYYY-MM-DD')}
                                />
                                <Form.Control.Feedback type="invalid">{errors.refDetails}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>
                    )}

                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" id="pay-submit" onClick={() => handleSave()} onKeyDown={(e) => buttonKeydown(e, "pay-submit")}>Save</Button>
                <Button variant="danger" id="pay-cancel" onClick={handleClose} onKeyDown={(e) => buttonKeydown(e, "pay-cancel")}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    const {  } = state;
    return {  }
};

export default connect(mapStateToProps, { addLedger })(CreatePayment);
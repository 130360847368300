import moment from "moment";
import apiConfig from "../../config/apiConfig";
import { apiBaseURL, designationActionType, toastType } from "../../constants";
import { setLoading } from "./loadingAction";
import { setSavingButton } from "./saveButtonAction";
import { addToast } from "./toastAction";
import { addInToTotalRecord, removeFromTotalRecord } from "./totalRecordAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { setLoader } from "./loaderAction";

export const fetchDesignation =
    (filter = {}, isLoading = true) =>
        async (dispatch) => {
            dispatch(setLoader(true));
            let url = apiBaseURL.DESIGNATIONS;
            apiConfig
                .get(url)
                .then((response) => {
                    dispatch({
                        type: designationActionType.ADD_DESIGNATION,
                        payload: response.data.data,
                    });
            dispatch(setLoader(false));
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response.data.message,
                            type: toastType.ERROR,
                        })
                    );
                    dispatch(setLoader(false));
                });
        };


        export const addDesignation = (data, isLoading = true) => async (dispatch) => {
            
            dispatch(setSavingButton(true));
            await apiConfig
                .post(apiBaseURL.DESIGNATIONS, data)
                .then((response) => {
                    dispatch({
                        type: designationActionType.ADD_DESIGNATION,
                        payload: response.data.data,
                    });
                    if (response?.data?.success) {
                        dispatch(
                            addToast({
                                text: getFormattedMessage(
                                    response?.data?.message
                                ),
                            })
                        );
                    //    fetchDepartment();
                    } else {
                        dispatch(
                            addToast({
                                type: toastType.ERROR,
                                text: getFormattedMessage(response?.data?.message),
                            })
                        );
                    }
        
                })
                .catch(({ response }) => {
                    dispatch(setSavingButton(false));
                    response &&
                        dispatch(
                            addToast({
                                text: response.data.message,
                                type: toastType.ERROR,
                            })
                        );
                });
            }
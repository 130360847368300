import React from 'react';
import Spinner from "../../shared/components/loaders/Spinner";
import {
    getFormattedMessage,
    placeholderText,
    currencySymbolHendling,
} from "../../shared/sharedMethod";

const ProfileTab = (props) => {
    const { isLoading, itemsValue } = props;

    return (
        <>
            <div className="row">
                {isLoading ? (
                    <Spinner />
                ) : (
                    <>
                        {/* <div className="col-md-12">
                            <div className="d-inline-block text-center">
                                <p>{product &&
                                    product?.attributes &&
                                    product?.attributes?.name}</p>
                                <div className="mt-3">
                                    {product &&
                                        product?.attributes &&
                                        product?.attributes?.code_barcode}
                                </div>
                            </div>
                        </div> */}
                        <div className="col-xxl-7">
                            <table className="table table-responsive gy-7">
                                <tbody>
                                    <tr>
                                        <th className="py-4" scope="row">
                                            {getFormattedMessage(
                                                "Under Group"
                                            )}
                                        </th>
                                        <td className="py-4">
                                            {itemsValue?.underGroup}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="py-4" scope="row">
                                            {getFormattedMessage("Address")}
                                        </th>
                                        <td className="py-4">
                                            {itemsValue && itemsValue?.address + (itemsValue?.city ? ", " + itemsValue?.city : "") + (itemsValue?.state ? ", " + itemsValue?.state : "")}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="py-4" scope="row">
                                            {getFormattedMessage(
                                                "Area"
                                            )}
                                        </th>
                                        <td className="py-4">
                                            {itemsValue?.area }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="py-4" scope="row">
                                            {getFormattedMessage("City")}
                                        </th>
                                        <td className="py-4">
                                            {itemsValue?.city}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="py-4" scope="row">
                                            {getFormattedMessage(
                                                "State"
                                            )}
                                        </th>
                                        <td className="py-4">
                                            {itemsValue?.state}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="py-4" scope="row">
                                            {getFormattedMessage(
                                                "Location"
                                            )}
                                        </th>
                                        <td className="py-4">
                                            {itemsValue?.location}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="py-4" scope="row">
                                            {getFormattedMessage(
                                                "Mobile No"
                                            )}
                                        </th>
                                        <td className="py-4">
                                            {itemsValue?.mobileNo}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="py-4" scope="row">
                                            {getFormattedMessage(
                                                "Email"
                                            )}
                                        </th>
                                        <td className="py-4">
                                            {itemsValue?.email}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="py-4" scope="row">
                                            {getFormattedMessage(
                                                "GSTIN"
                                            )}
                                        </th>
                                        <td className="py-4">
                                            {itemsValue?.gstNo}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

export default ProfileTab
import {
    faFileExcel,
    faFilePdf,
    faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactTabulator } from "react-tabulator";
import { connect, useDispatch } from "react-redux";
import ClosingStockReportForm from "../closingStockReport/ClosingStockReportForm";
import ClosingStockField from "../closingStockReport/ClosingStockField";
import { fetchAcYear } from "../../../store/action/acYearAction";
import { fetchGstrReport } from "../../../store/action/gstrReportAction";
import { generatePDF, printPDF } from "../pdfUtil";
import { fetchCompanyConfig } from "../../../store/action/companyConfigAction";
import Loader from "../../loader/Loader";
import moment from "moment";
import { addToast } from "../../../store/action/toastAction";
import Select from "react-select";
import { fetchLedger } from '../../../store/action/ledgerAction';
import { fetchInvoiceSummary } from "../../../store/action/invoiceSummaryAction";
// import * as XLSX from 'xlsx';

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const InvoiceSummary = (props) => {
    const { stockMovement, acYear, fetchAcYear, fetchGstrReport, invoiceSummary, fetchCompanyConfig, companyConfig, setFromDate, setDateto, fetchInvoiceSummary, fetchLedger, ledger } = props;
    const today = new Date();
    const formattedDate = formatDate(today);
    const numOfDaysAdded = 0;
    const date = today.setDate(today.getDate() + numOfDaysAdded);
    const defaultValue = new Date(date).toISOString().split("T")[0]; // YYYY-MM-dd

    const formatDecimal = (cell, formatterParams, onRendered) => {
        const value = cell.getValue();

        return value?.toFixed(2);
    };
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [filterdailySales, setFilterdailySales] = useState([]);
    const [fieldShow, setFieldShow] = useState(false);
    const handleFieldClose = () => setFieldShow(!fieldShow);
    const [showField, setShowField] = useState();
    const [minYear, setMinYear] = useState();
    const [maxYear, setMaxYear] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(!show);
    const year = useMemo(() => acYear, [acYear]);
    const [label, setLabel] = useState(false);
    const [payMode, setPayMode] = useState({ value: "", label: "All" });
    const [fetchRangeValue, setFetchRangeValue] = useState({
        category1Name: "",
        category2Name: "",
        category3Name: "",
        unitName: ""
    });
    const paySlipRef = useRef(null);
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    const yearRange = `${currentYear}-${nextYear}`;
    const [selectedYearRange, setSelectedYearRange] = useState({
        value: yearRange,
        label: yearRange,
    });
    const [totalData, setTotalData] = useState({});

    const [range, setRange] = useState({
        category1Name: "",
        category2Name: "",
        category3Name: "",
        unitName: "",
    });

    const [dates, setDate] = useState({
        fromDate: defaultValue,
        toDate: defaultValue,
    });

    const columns = [
        {
            title: "Date", field: "Date", headerSort: false, width: 125, // Custom text for the bottom of this column
        },
        {
            title: "Inv.No", field: "InvNo", headerSort: false, bottomCalc: () => 'Total', hozAlign: "center", headerHozAlign: "center", width: 125, // Custom text for the bottom of this column
            bottomCalc: (values) => {
                // Count unique invoice numbers
                const uniqueCount = new Set(values).size;
                return `Total (${uniqueCount} invoices)`; // Return the count of unique invoices
            },
        },
        {
            title: "Pay Mode", field: "PayMode", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 100,
        },
        {
            title: "Customer", field: "Customer", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 250,
        },
        {
            title: "Product", field: "Product", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 250,
        },
        {
            title: "Qty", field: "Qty", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 100,
        },
        {
            title: "Price", field: "Price", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 100,
        },
        {
            title: "Disc(₹)", field: "Disc", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 100,
        },
        {
            title: "Tax",
            columns: [
                {
                    title: "(%)", field: "TaxPercentage", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 100
                },
                {
                    title: "(₹)", field: "TaxAmount", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 100
                },
            ]
        },
        {
            title: "Cost of Sale", field: "CostOfSale", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 100,
        },
        {
            title: "Invoice Value", field: "InvoiceValue", headerSort: false, hozAlign: "right", headerHozAlign: "right", width: 150, bottomCalc: (values, data) => {
                // Filter unique values
                const uniqueValues = [...new Set(values)];

                // Custom calculation logic
                let total = 0;
                uniqueValues.forEach(value => {
                    total += parseFloat(value) || 0; // Ensure values are parsed as numbers
                });

                return total.toFixed(2); // Return total with two decimal places
            }
        },
    ];

    const companyDetails = {
        companyName: companyConfig?.companyName || 'Company Name',
        address: `${companyConfig?.attributes?.address1 || ''} , ${companyConfig?.attributes?.address2 || ''}`,
        phoneNumber: companyConfig?.attributes?.phoneNo || 'Phone Number'
    };

    const fromDate = useRef();
    const toDate = useRef();

    const loadValues = (filter) => {
        return "?fromDate=" + filter + "&toDate=" + filter + "&payMode=";
    }

    useEffect(() => {
        fetchAcYear();
        // fetchCompanyConfig();
        // fetchInvoiceSummary(loadValues(defaultValue));
        setFromDate(defaultValue);
        setDateto(defaultValue);
        fetchLedger();
    }, []);

    useEffect(() => {
        setFilterdailySales(invoiceSummary);
    }, [invoiceSummary]);

    useEffect(() => {
        setMinYear(selectedYearRange?.label?.split("-")[0] + "-04-01");
        setMaxYear(selectedYearRange?.label?.split("-")[1] + "-03-31");
    }, [selectedYearRange]);

    const onChange = (e) => {
        const { name, value } = e.target;
        setDate({ ...dates, [name]: value });
        setFromDate(name === "fromDate" ? value : dates.fromDate);
        setDateto(name === "toDate" ? value : dates.toDate);
    }

    const fetchData = () => {
        let values = "?fromDate=" + dates?.fromDate + "&toDate=" + dates?.toDate + "&customerName=" + payMode?.value;
        if (moment(dates?.fromDate).isAfter(dates?.toDate)) {
            dispatch(addToast({ text: 'Invalid date range.!', type: 'error' }));
        } else {
            fetchInvoiceSummary(values);
        }
    }

    const sortInvoices = (b2b) => {
        return b2b.sort((a, b) => {
            // Compare invDate (convert date strings to Date objects)
            const dateA = new Date(a.invDate);
            const dateB = new Date(b.invDate);
            if (dateA - dateB !== 0) {
                return dateA - dateB;
            }

            // Compare invNo (alphanumeric comparison)
            if (a.invNo !== b.invNo) {
                return a.invNo.localeCompare(b.invNo);
            }

            // Compare customerRegno (alphanumeric comparison)
            return a.customerRegno.localeCompare(b.customerRegno);
        });
    }

    const itemsValue = invoiceSummary?.length > 0 &&
    invoiceSummary?.map(data => ({
            Date: moment(data?.Date).format('DD-MM-YYYY'),
            InvNo: data?.InvNo,
            PayMode: data?.PayMode,
            Customer: data?.Customer,
            Qty: data?.Qty,
            Price: data?.Price,
            Disc: data?.Disc,
            TaxPercentage: data?.TaxPercentage,
            TaxAmount: data?.TaxAmount,
            CostOfSale: data?.CostOfSale,
            InvoiceValue: data?.InvoiceValue
        })) || [];

    const handlePrintPDF = () => {
        printPDF(paySlipRef, companyDetails, 'Landscape', 'b2bReport', "GSTR1 Summary for B2B,SEZ,DE Invoices - 4A, 4B ,6B ,6C", itemsValue, columns);
    };

    const handleExportToPDF = () => {
        generatePDF(paySlipRef, companyDetails, 'Landscape', 'b2bReport', "GSTR1 Summary for B2B,SEZ,DE Invoices - 4A, 4B ,6B ,6C", itemsValue, columns)
            .then(pdf => {
                pdf.save('b2bReport.pdf');
            })
            .catch(error => {
                console.error('Error generating PDF:', error);
            });
    };

    const generateJSONFile = (data, filename) => {
        const jsonString = JSON.stringify(data);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handlePaymode = (e) => {
        setPayMode(e);
    }

    // const data = [
    //     // Your response data here, e.g., fetch it via an API
    //     {
    //         Date: "2024-11-28T00:00:00",
    //         InvNo: "VT032Z/24-25",
    //         PayMode: "CREDIT",
    //         Customer: "ANU BHARATHI",
    //         Product: "AACHI IDLY CHILLI POWDER 50G RS42",
    //         Qty: 5.0,
    //         Price: 19.0476,
    //         Disc: 3.9048,
    //         TaxPercentage: 5.0,
    //         TaxAmount: 4.5667,
    //         CostOfSale: 19.18,
    //         InvoiceValue: 96.0,
    //     },
    //     {
    //         "Date": "2024-11-28T00:00:00",
    //         "InvNo": "VT032Z/24-25",
    //         "PayMode": "CREDIT",
    //         "Customer": "ANU BHARATHI",
    //         "Product": "AACHI IDLY CHILLI POWDER 50G RS42",
    //         "Qty": 5.0000,
    //         "Price": 19.0476,
    //         "Disc": 3.9048,
    //         "TaxPercentage": 5.00,
    //         "TaxAmount": 4.5667,
    //         "CostOfSale": 19.1800,
    //         "InvoiceValue": 96.0000
    //     },
    //     {
    //         "Date": "2024-11-29T00:00:00",
    //         "InvNo": "VT033Z/24-25",
    //         "PayMode": "CREDIT",
    //         "Customer": "ANU BHARATHI",
    //         "Product": "AACHI IDLY CHILLI POWDER 50G RS42",
    //         "Qty": 5.0000,
    //         "Price": 54.6571,
    //         "Disc": 0.0000,
    //         "TaxPercentage": 5.00,
    //         "TaxAmount": 13.6643,
    //         "CostOfSale": 57.3900,
    //         "InvoiceValue": 287.0000
    //     },
    //     {
    //         "Date": "2024-11-29T00:00:00",
    //         "InvNo": "VT034Z/24-25",
    //         "PayMode": "CREDIT",
    //         "Customer": "ANU BHARATHI",
    //         "Product": "AACHI IDLY CHILLI POWDER 50G RS42",
    //         "Qty": 5.0000,
    //         "Price": 54.6571,
    //         "Disc": 0.0000,
    //         "TaxPercentage": 5.00,
    //         "TaxAmount": 13.6643,
    //         "CostOfSale": 57.3900,
    //         "InvoiceValue": 287.0000
    //     },
    //     {
    //         "Date": "2024-12-03T00:00:00",
    //         "InvNo": "VT036Z/24-25",
    //         "PayMode": "CREDIT",
    //         "Customer": "ANU BHARATHI",
    //         "Product": "AACHI IDLY CHILLI POWDER 50G RS42",
    //         "Qty": 1.0000,
    //         "Price": 28.5700,
    //         "Disc": 0.0000,
    //         "TaxPercentage": 5.00,
    //         "TaxAmount": 1.4300,
    //         "CostOfSale": 30.0000,
    //         "InvoiceValue": 244.0000
    //     },
    //     {
    //         "Date": "2024-12-03T00:00:00",
    //         "InvNo": "VT036Z/24-25",
    //         "PayMode": "CREDIT",
    //         "Customer": "ANU BHARATHI",
    //         "Product": "AACHI IDLY CHILLI POWDER 50G RS42",
    //         "Qty": 5.0000,
    //         "Price": 28.5700,
    //         "Disc": 4.8600,
    //         "TaxPercentage": 5.00,
    //         "TaxAmount": 6.9000,
    //         "CostOfSale": 28.9800,
    //         "InvoiceValue": 244.0000
    //     },
    //     {
    //         "Date": "2024-12-03T00:00:00",
    //         "InvNo": "VT036Z/24-25",
    //         "PayMode": "CREDIT",
    //         "Customer": "ANU BHARATHI",
    //         "Product": "AACHI BRIYANI MASALA 50G",
    //         "Qty": 2.0000,
    //         "Price": 33.3300,
    //         "Disc": 1.3300,
    //         "TaxPercentage": 5.00,
    //         "TaxAmount": 3.2700,
    //         "CostOfSale": 34.3000,
    //         "InvoiceValue": 244.0000
    //     },
    // ];
    // const tableData = data.map((row) => ({
    //     ...row,
    //     Date: new Date(row.Date).toLocaleDateString("en-GB"), // Format date
    // }));

    return (
        <>
            <Loader />
            <div style={{ backgroundColor: "white", height: "100%" }}>

                {fieldShow && <ClosingStockField setShowField={setShowField} fieldShow={fieldShow}
                    handleFieldClose={handleFieldClose} Title={"Stock Movement Report Form"} />}

                <div className="row">
                    <div className="col-md-8">
                        <div className="row" style={{ marginTop: "-10px" }}>
                            <div className="col-md-4">
                                <h4 className="mt-2">From Date</h4>
                                <input
                                    id1="dateInput"
                                    type="date"
                                    className=" form-control rounded text-align-center ml-2 align-item-center mr-15 mb-5"
                                    ref={fromDate}
                                    defaultValue={defaultValue}
                                    name="fromDate"
                                    onChange={onChange}
                                ></input>
                            </div>
                            <div className="col-md-4 mt-2">
                                <h4>To Date</h4>
                                <input
                                    id2="dateRequired2"
                                    type="date"
                                    className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
                                    ref={toDate}
                                    defaultValue={defaultValue}
                                    name="toDate"
                                    onChange={onChange}
                                    min={dates?.fromDate}
                                ></input>
                            </div>
                            <div className="col-md-4 mt-2">
                                <h4>Customer</h4>
                                <Select
                                    // errors={errors["sales_unit_id"]}
                                    value={payMode}
                                    options={ledger?.filter((item) => item?.attributes?.underGroup.toLowerCase() === "customers")?.sort((a, b) => {
                                        if (a?.attributes?.ledgerName < b?.attributes?.ledgerName) return -1;
                                        if (a?.attributes?.ledgerName > b?.attributes?.ledgerName) return 1;
                                        return 0;
                                    })?.map((data) => ({ value: data?.attributes?.ledgerName, label: (data?.attributes?.salutation != "" ? (data?.attributes?.salutation + ".") : "") + data?.attributes?.ledgerName }))}
                                    id="payMode"
                                    onChange={(e) => handlePaymode(e)}
                                // onKeyDown={(e) => keyDown1(e, "payment-method")}
                                // autoFocus
                                />
                            </div>
                            <div className="col-md-4 mt-2">
                                <h4>Search</h4>
                                <input
                                    id2="dateRequired2"
                                    type="text"
                                    className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                ></input>
                            </div>
                            <div className="col-md-4 gstrGenerateBtn">
                                <button
                                    type="button"
                                    className="btn btn-success "
                                    onClick={() => fetchData()}
                                >
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-2 ">
                        <button
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                border: "none",
                                borderRadius: "10px",
                                width: "220px",
                                height: "60px",
                                gap: "13px",
                                background: "white",
                                marginTop: "-20px",
                                fontSize: "11px",
                                marginLeft: "-20px"
                            }}
                            ref={paySlipRef}
                        >
                            <FontAwesomeIcon
                                icon={faPrint}
                                className="fa-2x search-icon"
                                style={{ color: "black", paddingTop: "8px" }}
                                onClick={handlePrintPDF}
                            ></FontAwesomeIcon>

                            <FontAwesomeIcon
                                icon={faFileExcel}
                                className="fa-2x search-icon "
                                style={{
                                    color: "green",
                                    paddingLeft: "10px",
                                    paddingTop: "7px",
                                }}
                            //   onClick={downloadUpdatedExcel}
                            ></FontAwesomeIcon>

                            <FontAwesomeIcon
                                icon={faFilePdf}
                                className="fa-2x search-icon"
                                style={{ color: "red", paddingLeft: "10px", paddingTop: "7px" }}
                                onClick={handleExportToPDF}
                            ></FontAwesomeIcon>
                            {/* <span onClick={handleGenerateJSON}>Json</span> */}
                        </button>
                    </div>
                </div>
                <h2
                    style={{
                        // color: "green",
                        fontSize: "17px",
                        fontWeight: "700"
                    }}
                >
                    {`Sales Report from ${dates?.fromDate} To ${dates?.toDate}`}
                </h2>
                <div className="row">
                    <div className="col-md-9"></div>
                    <div
                        className="col-md-3"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "-50px",
                        }}
                    >
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 w-100 tabulator-container invoiceSummary">
                        <ReactTabulator
                            columns={columns}
                            data={itemsValue?.filter((data) => {
                                if (search === "") {
                                    return data;
                                } else if (
                                    data?.InvNo?.toLowerCase().includes(search.toLowerCase()) ||
                                    data?.PayMode?.toLowerCase().includes(search.toLowerCase()) ||
                                    data?.Customer?.toLowerCase().includes(search.toLowerCase()) || 
                                    data?.Product?.toLowerCase().includes(search.toLowerCase())
                                    // data?.salesValue?.toLowerCase().includes(search.toLowerCase())
                                ) {
                                    return data;
                                }
                            })}
                            // ref={pdfRef}
                            // data={tableData}
                            options={{
                                columnHeaderVertAlign: "bottom",
                                layout: 'fitColumns',
                                // responsiveLayout: "collapse",
                                placeholder: "No records found",
                                height: "420px",
                            }}
                        />
                    </div>
                </div>

                {show ? <ClosingStockReportForm
                    stockMovement={stockMovement}
                    show={show} handleClose={handleClose}
                    // setItemValues={setItemValues}
                    singleStock={itemsValue}
                    setRange={setRange}
                    Title={"Stock Movement Report Form"}
                // search={search}
                /> : null}
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { stockMovement, acYear, invoiceSummary, companyConfig, ledger } = state;
    return { stockMovement, acYear, invoiceSummary, companyConfig, ledger }
}

export default connect(mapStateToProps, { fetchAcYear, fetchGstrReport, fetchCompanyConfig, fetchInvoiceSummary, fetchLedger })(InvoiceSummary);

import { collectionActionType, saleActionType } from '../../constants';

export default (state = {}, action) => {
    switch (action.type) {
        case collectionActionType.FETCH_COLLECTIONS:
            return action.payload;
        // case collectionActionType.FETCH_SALES_LISTING:
        //     return action.payload;
        // case collectionActionType.FETCH_SALE:
        //     return action.payload;
        // case collectionActionType.ADD_SALE:
        //     return action.payload;
        // case collectionActionType.EDIT_SALE:
        //     return state.map(item => item.id === +action.payload.id ? action.payload : item);
        // case collectionActionType.DELETE_SALE:
        //     return state.filter(item => item.id !== action.payload);
        default:
            return state;
    }
};

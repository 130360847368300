import {invoiceSummaryActionType} from '../../constants';

export default (state = [], action) => {
    switch (action.type) {
        case invoiceSummaryActionType.FETCH_INVOICE_SUMMARY:
            return action.payload;
        
        default:
            return state;
    }
};

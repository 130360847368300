import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchProduct } from "../../store/action/productAction";
import ProductForm from "./ProductForm";
import HeaderTitle from "../header/HeaderTitle";
import MasterLayout from "../MasterLayout";
import { productUnitDropdown } from "../../store/action/productUnitAction";
import { fetchAllunits } from "../../store/action/unitsAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import { fetchAllProductGroups } from "../../store/action/productGroupsAction";
import { fetchOpeningStock } from "../../store/action/openingStockAction";

const EditProduct = (props) => {
  const { fetchProduct, products, fetchAllProductGroups, base,fetchOpeningStock,openingStock } = props;
  const { id } = useParams();

  const [formcode, setFormCode] = useState("M01");
    const navigate =useNavigate();
    useEffect(() => {
      ;
      const storedFormData = localStorage.getItem("UserFormCode");
  
      if (storedFormData) {
        const parsedFormData = JSON.parse(storedFormData);
  
        if (parsedFormData.length > 0) {
          const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permisssionUpdate);
          if(!formCodeItems.length > 0){
              navigate("/app/dashboard");
          }
        } else {
          navigate("/app/dashboard");
        }
      } 
    }, []);

  useEffect(() => {
    fetchAllProductGroups();
    fetchProduct(id);
     fetchOpeningStock(id)
  }, []);
  const getSaleUnit =
    products.length >= 1 && products[0]?.attributes?.sales_unit_name
      ? {
          label: products[0]?.attributes?.sales_unit_name,
          value: products[0]?.attributes?.sales_unit_id,
        }
      : "";
  const getPurchaseUnit =
    products.length >= 1 && products[0]?.attributes?.purchase_unit_name
      ? {
          label: products[0]?.attributes?.purchase_unit_name,
          value: products[0]?.attributes?.purshase_unit_id,
        }
      : "";

  const itemsValue =
    products.length === 1 &&
    products.map((product) => ({
      items_id: product?.items_id,
      name: product?.attributes?.name,
      name_print: product?.attributes?.name_print,
      name_tamil: product?.attributes?.name_tamil,
      code_barcode: product?.attributes?.code_barcode,
      brand_id: {
        value: product?.attributes?.category1_id,
        label: product?.attributes?.category1_name,
      },
      product_category_id: {
        value: product?.attributes?.category2_id,
        label: product?.attributes?.category2_name,
      },
      product_group_id: {
        value: product?.attributes?.category3_id,
        label: product?.attributes?.category3_name,
      },
      decimalPortion:product?.attributes?.decimalPortion,
      pack_count: product?.attributes?.pack_count,
      stock_alert: product?.attributes?.stock_alert,
      isactive: product?.attributes?.isActive,
      product_image: product?.attributes?.product_image,
      remarks: product?.attributes?.remarks,
      created_by: product?.attributes?.created_by,
      updated_by: product?.attributes?.updated_by,
      sale_unit: getSaleUnit,
      purchase_unit: getPurchaseUnit,
      tax_name: {
        value: product?.attributes?.tax_id,
        label: product?.attributes?.tax_name,
      },
      hsnCode: {label: product?.attributes?.hsnCode, value: product?.attributes?.hsnCode},
      is_Edit: true,
      isEditable: product?.attributes?.isEditable,
      // mrp:product?.attributes?.mrp,
      // salesPrice:product?.attributes?.salesPrice,
      // purchaseCost:product?.attributes?.purchaseCost,
      // stock:product?.attributes?.stock
    }));
  const getProductUnit =
    itemsValue &&
    base?.filter(
      (fill) => Number(fill?.id) === Number(itemsValue[0]?.product_unit)
    );

  return (
    <MasterLayout>
      <TopProgressBar />
      {/* <HeaderTitle
        to="/app/products"
      /> */}
      {itemsValue.length >= 1 && (
        <ProductForm
          singleProduct={itemsValue}
          productUnit={getProductUnit}
          productGroups={base}
          id={id}
          openingStock={openingStock}
        title={getFormattedMessage("product.edit.title")}

        />
      )}
    </MasterLayout>
  );
};

const mapStateToProps = (state) => {
  const { products, base ,openingStock} = state;
  return { products, base,openingStock };
};

export default connect(mapStateToProps, {
  fetchProduct,
  fetchAllProductGroups,
  productUnitDropdown,
  fetchAllunits,
  fetchOpeningStock
})(EditProduct);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MasterLayout from '../../MasterLayout';
import TopProgressBar from '../../../shared/components/loaders/TopProgressBar';
import { getFormattedMessage, placeholderText } from '../../../shared/sharedMethod';
import TabTitle from '../../../shared/tab-title/TabTitle';
import { fetchClosingStockReport } from '../../../store/action/ClsoingStockReportAction';
import ClosingStockReport from './ClosingStockReport';
import LowStockReport from '../lowStockReport/LowStockReport';
import NilStockReport from '../nilStock/NilStockReport';
import StockMovementReport from '../stockMovement/StockMovementReport';
import StockRegisterReport from '../stockRegister.js/StockRegisterReport';
import { useNavigate } from 'react-router';
import StockRegisterItemFilter from '../stockRegister.js/StockRegisterItemFilter';
import Loader from '../../loader/Loader';

const PosClosingStockReport = ( props ) => {
    const { fetchClosingStockReport, posClsoingstock,closingStocks,allConfigData } = props;
    const [closingStockValue, setclosingStockValue ] = useState( { label: getFormattedMessage( "unit.filter.all.label" ), value: null } );
  
    const [ key, setKey ] = useState( 'sales' );    

    const array = posClsoingstock   
    const newFirstElement = { attributes: { name: getFormattedMessage( "report-all.warehouse.label" ) }, id: null }
    const newArray = [ newFirstElement ].concat( array )

    const [formcode, setFormCode] = useState("R02");
    const navigate = useNavigate();
    useEffect(() => {
        const storedFormData = localStorage.getItem("UserFormCode");

        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);

            if (parsedFormData.length > 0) {
                const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
                if (!formCodeItems.length > 0) {
                    navigate("/app/dashboard");
                }
            } else {
                navigate("/app/dashboard");
            }
        }
    }, []);

    useEffect(() => {
        fetchClosingStockReport() ;
    },[])

    //  const [itemShow, setItemShow] = useState(true);
    // const handleCloseCreate = () => setItemShow(!itemShow);

    return (
        

        <div className='gstreportDiv' style={{backgroundColor:"white",height:"100%"}}>
            <Loader/>
        <MasterLayout>
            <TopProgressBar />
            <TabTitle title={placeholderText( 'StockReports.title' )} />

            <h1 style={{textAlign:'center',color:'green'}}> Stock Report</h1>
                        
            <Tabs defaultActiveKey='sales' id='uncontrolled-tab-example' onSelect={( k ) => setKey( k )}
               className='p-3 mb-2 custom-bg-color text-white  mb-5 payrolltabs' >

                <Tab eventKey='sales'  title={getFormattedMessage('closingStock.title' )} 
                tabClassName=' me-15'>
                    
                    <div className='w-100 mx-auto border-radius-50'>
                        {key === 'sales' &&  <ClosingStockReport allConfigData={allConfigData} closingStockValue={closingStockValue  } />}
                    </div> 

                </Tab>

                <Tab eventKey='stockMovement' title={getFormattedMessage( 'stockMovement.title' )} 
                tabClassName=' me-15'
                >
                <div className='w-100 mx-auto'>
                    {key === 'stockMovement'&&<StockMovementReport/> }
                </div>
                </Tab>

                <Tab eventKey='stockRegister' title={getFormattedMessage( 'stockRegister.title' )} 
                tabClassName=' me-15'
                >
                <div className='w-100 mx-auto'>
                    {key === 'stockRegister'&&< StockRegisterReport /> }
                </div>
                </Tab>

                <Tab eventKey='lowStock' title={getFormattedMessage( 'lowStock.title' )} 
                tabClassName=' me-15'
                >
                <div className='w-100 mx-auto'>
                    {key === 'lowStock'&&<LowStockReport/> }
                </div>
                </Tab>

                <Tab eventKey='nilStock' title={getFormattedMessage( 'nilStock.title' )} 
                
                >
                <div className='w-100 mx-auto'>
                    {key === 'nilStock'&&<NilStockReport/> }
                </div>
                </Tab>
                

            </Tabs>
        </MasterLayout>
        </div>
        
    )
};

const mapStateToProps = ( state ) => {
    const { closingStocks,allConfigData, posClsoingstock} = state;
    return { closingStocks, allConfigData ,posClsoingstock}
};
export default connect( mapStateToProps, {fetchClosingStockReport } )( PosClosingStockReport );


import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { editUser, fetchUser } from '../../store/action/userAction';
import { useNavigate, useParams } from 'react-router-dom';
import MasterLayout from '../MasterLayout';
import HeaderTitle from '../header/HeaderTitle';
import { getFormattedMessage } from '../../shared/sharedMethod';
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import UsersForm from '../posUsers/UsersForm';
import { fetchSingleSalary } from '../../store/action/salaryAction';
import SalaryStructure from './SalaryStructure';
const ViewSalary = (props) => {

  const { fetchSingleSalary, singleSalary } = props;
  const { id } = useParams();

  const [formcode, setFormCode] = useState("HR02");
  const navigate = useNavigate();
  useEffect(() => {
    ;
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
        if (!formCodeItems.length > 0) {
          navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    }
  }, []);

  useEffect(() => {

    fetchSingleSalary(id);
  }, []);

  return (
    <MasterLayout>
      <TopProgressBar />
      {/* <HeaderTitle title={getFormattedMessage('user.edit.title')} to='/app/employees'/> */}
      {singleSalary?.attributes && <SalaryStructure singleSalary={singleSalary} id={id} />}
    </MasterLayout>
  );
}

const mapStateToProps = (state) => {
  const { singleSalary } = state;
  return { singleSalary }
};

export default connect(mapStateToProps, { fetchSingleSalary })(ViewSalary);

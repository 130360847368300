import React, { useEffect, useState } from 'react'
import Loader from '../loader/Loader';
import { getFormattedMessage } from '../../shared/sharedMethod';
import { Form, InputGroup, Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import ProductSearchbar from '../../frontend/components/product/ProductSearchbar';
import { FaCalendarAlt } from "react-icons/fa";
import { DatePicker } from '@mui/lab';
import ReactDataTable from '../../shared/table/ReactDataTable';
import { fetchAllProducts } from '../../store/action/productAction';
import { connect } from 'react-redux';

const CollectionForm = (props) => {
    const { fetchAllProducts } = props;
    const [date, setDate] = useState(null);

    useEffect(() => {
        fetchAllProducts();
    }, []);

    const columns = [
        {
            name: getFormattedMessage('Cust Code'),
            selector: row => row.invNo,
            sortField: 'invNo',
            sortable: true,
        },
        {
            name: getFormattedMessage('Customer Name'),
            selector: row => row.customerName,
            sortField: 'customerName',
            sortable: true,
        },
        {
            name: getFormattedMessage('No.of Bills'),
            selector: row => row.supplierMobile,
            sortField: 'supplierMobile',
            sortable: true,
        },
        {
            name: getFormattedMessage('Outstanding'),
            selector: row => row.city,
            sortField: 'city',
            sortable: true,
        },
        {
            name: getFormattedMessage('Received'),
            selector: row => row.paymentType,
            sortField: 'paymentType',
            sortable: true,
        },
        {
            name: getFormattedMessage('Pay Mode'),
            selector: row => row.billAmount,
            sortField: 'billAmount',
            sortable: true,
        },
        {
            name: getFormattedMessage('Collect By'),
            selector: row => row.billAmount,
            sortField: 'billAmount',
            sortable: true,
        },
        // {
        //     name: getFormattedMessage('react-data-table.action.column.label'),
        //     right: true,
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        //     cell: row => <ActionButton item={row}
        //         // isViewIcon={true}
        //         isEditMode={true}
        //         isPrint={true}
        //         goToEditProduct={goToEditProduct}
        //         onClickDeleteModel={onClickDeleteModel}
        //         onPrintModal={onPrintModal}
        //         isSales={true}
        //     //goToEditProduct={goToEditSuppliers} isEditMode={true}
        //     // onClickDeleteModel={onClickDeleteModel} 
        //     />
        // }
    ];

    return (
        <>
            <Loader />
            <div className="d-md-flex align-items-center justify-content-between mb-5">
                <h1 className="mb-0 create-title">{getFormattedMessage("Collection Entry")}</h1>
                <div className="text-end mt-4 mt-md-0">
                    <Button className="btn btn-outline-primary me-2 save-btn" style={{ width: '46%' }} >{getFormattedMessage("globally.save-btn")}</Button>
                    <button className="btn btn-outline-primary back-btn">
                        {getFormattedMessage("Back")}
                    </button>
                </div>
            </div>
            <div style={{ background: 'white', padding: '2%', borderRadius: '8px' }}>
            <div className="d-flex align-items-center gap-3" style={{ padding: "10px", justifyContent: "center" }}>
                {/* Entry No */}
                <div style={{ width: '6%' }}>
                    <Form.Group controlId="entryNo">
                        <Form.Label>Entry No</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter No"
                            style={{
                                border: "1px solid green",
                                borderRadius: "10px",
                                textAlign: "center",
                            }}
                        />
                    </Form.Group>
                </div>

                {/* Date */}
                <div style={{ width: '11%' }}>
                    <Form.Group controlId="datePicker">
                        <Form.Label>Date</Form.Label>
                        <InputGroup style={{ maxWidth: "150px" }}>
                            <input type='date' style={{
                                border: "1px solid green",
                                borderRadius: "10px",
                                textAlign: "center",
                                padding: '7%'
                            }}></input>
                        </InputGroup>
                    </Form.Group>
                </div>

                {/* Search Bar */}
                <div style={{ width: '60%' }}>
                    <Form.Group controlId="searchField">
                        <Form.Label>Search</Form.Label>
                        {/* <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Search Customer Name or Mobile No.."
                                style={{
                                    border: "1px solid #40C463",
                                    borderRadius: "10px 0 0 10px",
                                }}
                            />
                            <Button
                                style={{
                                    backgroundColor: "#40C463",
                                    border: "none",
                                    borderRadius: "0 10px 10px 0",
                                }}
                            >
                                <FaSearch style={{ color: "white" }} />
                            </Button>
                        </InputGroup> */}
                        <div className=" items-container collection-bar">
                            <ProductSearchbar
                                purchase={false}
                                mode={'sales'}
                                module={"collection"}
                                collection={true}
                            />
                        </div>
                    </Form.Group>
                </div>
            </div>
            <div>
                <ReactDataTable
                    columns={columns}
                    items={[]}
                    // totalRows={itemsValue?.length}
                    // isLoading={isLoading}
                    // isUnitFilter
                    subHeader={false}
                />
            </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { } = state;
    return { }
};

export default connect(mapStateToProps, { fetchAllProducts })(CollectionForm);

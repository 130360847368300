import { useSelector, useDispatch } from "react-redux";
import MasterLayout from "../MasterLayout";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import ReactDataTable from "../../shared/table/ReactDataTable";
import {
    addPriceList,
    fetchPriceHistory,
    fetchPriceList,
    fetchPriceListSpecific,
    updatePriceList,
} from "../../store/action/priceListAction";

import { addPriceLevelList, fetchPriceLevelHistory, fetchPriceLevelList, fetchPriceLevelListSpecific, updatePriceLevelList } from "../../store/action/priceLvlListAction";
import speechIcon from "../../assets/images/speech-icons.svg";

import {
    getFormattedMessage,
    placeholderText,
} from "../../shared/sharedMethod";
import ActionButton from "../../shared/action-buttons/ActionButton";
import { Button } from "react-bootstrap-v5";
import { useEffect, useRef, useState } from "react";
import DeletePriceList from "../priceList/DeletePriceList";
import CommonTable from "../../shared/table/CommonTable";
import PriceListHeaderComponents from "../../shared/action-buttons/PriceListHeaderComponents";
import AddPriceListConfirmationModal from "../../shared/action-buttons/AddPriceListConfimationModal";
import HeaderTitle from "../header/HeaderTitle";
import ActionButtonForNewItems from "../../shared/action-buttons/ActionButtonForNewItems";
import ValidationModal from "./ValidationModal";
import Loader from '../loader/Loader';
import { useNavigate } from "react-router";
import { useCallback } from "react";
import PriceLevelHistoryModal from "./priceLevelHistoryModal";
import DeletePriceLevelList from "./DeletePriceLevelList";
import { Link } from "react-router-dom";
import AddPriceLevel from "./AddPriceLevel";
import { fetchPriceLevel } from "../../store/action/priceLevelListAction";
import SweetAlert from "react-bootstrap-sweetalert";
import TabTitle from "../../shared/tab-title/TabTitle";
import { fetchItemPriceHistory } from "../../store/action/getItemPriceHistoryAction";

const PriceLevelList = () => {
    const { priceLvlListing, isLoading, priceLvlHistoryList, priceLevel } = useSelector(
        (state) => state
    );
    const priceLvlListingRef = useRef(priceLvlListing);
    const priceNewRef = useRef();
    const [priceListNew, setPriceListNew] = useState([]);
    const [filterPriceListNew, setFilterPriceListNew] = useState([]);
    const [selectedRowItem, setSelectedRowItem] = useState({});
    const [deleteModel, setDeleteModel] = useState(false);
    const [isDelete, setIsDelete] = useState(null);
    const [dataToSend, setDataToSend] = useState({
        itemId: 0,
        mrp: "",
        batchNo: "",
        salesPrice: "",
        remarks: "",
        updatedBy: 1,
        entryFrom: "",
    });
    const [show, setShow] = useState(false);
    const [columns, setColumns] = useState([]);
    const [priceHistoryModalShow, setPriceHistoryModalShow] = useState(false);
    const [addPriceListModalShow, setAddPriceListModalShow] = useState(false);
    const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);
    const [isExclusive, setIsExclusive] = useState(false);
    const [totalRecord, setTotalRecord] = useState();
    const [isEditIconClicked, setIsEditIconClicked] = useState(false);
    const [mrpValidation, setMrpValidation] = useState(false);
    const [salesPriceValidation, setSalesPriceValidation] = useState(false);
    const [pricelvlName, setPricelvlName] = useState();
    const [pricelvlName1, setPricelvlName1] = useState();
    const [salesPriceValidation1, setSalesPriceValidation1] = useState(false);
    const [costValidation, setCostValidation] = useState(false);
    const [costValidation1, setCostValidation1] = useState(false);
    const [formcode, setFormCode] = useState("M05");
    const [isEdit, setIsEdit] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [currentRow, setCurrentRow] = useState();
    const [priceHistoryrow, setPriceHistoryrow] = useState();
    const [prevRow, setPrevRow] = useState();
    const [flagKey, setFlagKey] = useState(0);
    const [pageCount, setPageCount] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const Dispatch = useDispatch();
    const navigate = useNavigate()
    const salesPriceInput = useRef(null);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [roleName, setRoleName] = useState();
    const [backAlert, setBackAlert] = useState(false);
    const [validFilter, setValidFilter] = useState([]);
    const [valid1, setValid1] = useState([]);

    useEffect(() => {

        const hash = location.hash; // This gives the part after the '#'

        // Assuming your URL looks like '#/app/price-list?id=1'
        // const params = new URLSearchParams(hash.split('?')[1]);

        // const idParam = params.get('id');
        // if (idParam) {
        //   Dispatch(fetchPriceListSpecific(true, idParam));
        // } else {
        // Dispatch(fetchPriceList(true));
        Dispatch(fetchPriceLevelList(true));
        Dispatch(fetchPriceLevel());
        // }
    }, []);

    useEffect(() => {
        let columns1 = [
            {
                name: "Product Name",
                width: "30%",
                headerStyle: { textAlign: 'center' },
                cell: (row) => (row.itemName),
            },
            {
                name: "MRP",
                cell: (row) => (
                    <input
                        className="form-control mrp_input"
                        type="number"
                        value={row?.mrp}
                        disabled={!row.isNewRow}
                        id={"mrp" + row?.key}
                        onKeyDown={(e) => { handleKeyDown(e, row); handleMrpKey(e, row) }}
                        onWheel={(e) => handleWheel(e)}
                        onChange={(e) => onChangeInput(e, row)}
                        name="mrp"
                        style={{ textAlign: "center" }}
                        autoComplete="off"
                    />
                ),
            },
            {
                name: "Cost",
                cell: (row) => (
                    <input
                        className="form-control"
                        type="number"
                        value={row?.purchaseCost}
                        disabled={true}
                        style={{ textAlign: "center" }}
                    />
                ),
            },
            {
                name: "Sales Price",
                cell: (row) => (
                    <input
                        className="form-control salesPrice_input"
                        type="text"
                        id={"salesPrice" + row?.key}
                        value={row?.salesPrice == '0.00' ? '' : row?.salesPrice}
                        ref={salesPriceInput}
                        disabled={
                            !(
                                selectedRowItem?.itemId === row?.itemId &&
                                selectedRowItem?.mrp === row?.mrp
                            ) && !row.isNewRow
                        }
                        onKeyDown={(e) => {
                            handleKeyDown(e, row);
                            handleKey2(e, row);
                            handleSalesPriceKey(e, row);
                        }}
                        // name={isExclusive ? "salesPrice" : "salesPriceInclusive"}
                        name="salesPrice"
                        onChange={(e) => onChangeInput(e, row)}
                        style={{ textAlign: "center" }}
                        autoComplete="off"
                    />
                ),
            },
            {
                name: "Unit",
                cell: (row) => row?.salesUnitName,
            },
            {
                name: getFormattedMessage("react-data-table.action.column.label"),
                right: true,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                width: "120px",
                cell: (row) =>
                    row?.isNewRow || row?.isRowEdit ? (
                        <ActionButtonForNewItems
                            // onSubmitEdit={(row) => EditSubmit(row)}
                            addNewPriceItem={(row) => row?.isRowEdit ? EditSubmit(row) : addNewPriceItem(row)}
                            removeAddedItem={(row) => row?.isRowEdit ? closeEdit(row) : removeAddedItem(row)}
                            idAdd={"salesPrice" + row?.key + "_add"}
                            idClose={"salesPrice" + row?.key + "_close"}
                            item={row}
                        />
                    ) : (
                        <ActionButton
                            item={row}
                            goToEditProduct={(row) => goToEditProduct(row)}
                            // isEditMode={
                            //   selectedRowItem?.itemId === row?.itemId &&
                            //     selectedRowItem?.mrp === row?.mrp &&
                            //     selectedRowItem?.batchNo === row?.batchNo
                            //     ? "save"
                            //     : row?.isNewRow
                            //       ? "add_new_row"
                            //       : "edit"
                            // }
                            onSubmitEdit={(row) => EditSubmit(row)}
                            onClickDeleteModel={onClickDeleteModel}
                            isPriceAdd={true}
                            editId={"salesPrice" + row?.key + "_edit"}
                            addNewRow={(row) => addNewRow(row)}
                            isViewPriceHistory={true}
                            isViewAddIcon={true}
                            addNewPriceItem={(row) => addNewPriceItem(row)}
                            onClickPriceHistory={() => onClickPriceHistory(row)}
                            addPriceListModalShowing={(row) => addPriceListModalShowing(row)}
                            removeAddedItem={(row) => removeAddedItem(row)}
                        />
                    ),
            },
        ];

        if (priceLevel?.length > 0) {
            const priceLevelColumns = priceLevel?.map((level, ind) => ({
                name: level.attributes.pricelevelname,
                cell: (row) => (
                    <input
                        className="form-control salesPrice_input"
                        type="number"
                        id={level.attributes.pricelevelname + row?.key}
                        // value={row[`priceLevel${level.pricelevelno}`]}
                        disabled={
                            !(
                                selectedRowItem?.itemId === row?.itemId &&
                                selectedRowItem?.mrp === row?.mrp
                            ) && !row.isNewRow
                        }
                        onWheel={(e) => handleWheel(e)}
                        onKeyDown={(e) => {
                            handleKeyDown(e, row);
                            handleKey2(e, row);
                            handlePriceLvlKey(e, row);
                        }}
                        value={row?.[level.attributes.pricelevelname] == '0.00' ? '' : row?.[level.attributes.pricelevelname]}
                        onChange={(e) => onChangeInput(e, row)}
                        name={level.attributes.pricelevelname}
                        data-ind={ind}
                        style={{ textAlign: "center" }}
                        autoComplete="off"
                    />
                ),
            }));

            // Insert the PriceLevel columns before the Unit column
            const unitIndex = columns1.findIndex((col) => col.name === "Unit");
            const updatedColumns = [
                ...columns1.slice(0, unitIndex),
                ...priceLevelColumns,
                ...columns1.slice(unitIndex),
            ];
            setColumns(updatedColumns);
        } else {
            setColumns(columns1);
        }
    }, [priceLevel, selectedRowItem]);

    useEffect(() => {
        if (priceLvlListing?.length > 0) {
            let data = [...priceLvlListing];
            priceLvlListingRef.current = data;
            data = data?.map((item) => {
                return {
                    ...item,
                    attributes: {
                        ...item.attributes,
                        salesPrice: Number(item?.attributes?.salesPrice * (1 + (item?.attributes?.tax / 100))).toFixed(2).toString(),
                        purchaseCost: Number(item?.attributes?.purchaseCost * (1 + (item?.attributes?.tax / 100))).toFixed(2).toString(),
                        PriceLevelLists: item?.attributes?.PriceLevelLists?.map((list) => {
                            return {
                                ...list,
                                salesRate: Number(list?.salesRate * (1 + (item?.attributes?.tax / 100))).toFixed(2).toString(),
                            }
                        })
                    }
                }
            });

            setPriceListNew(data);
            priceNewRef.current = data;
            setFilterPriceListNew(data);
            setTotalRecord(data.length);
        }
    }, [priceLvlListing]);

    useEffect(() => {
        const storedFormData = localStorage.getItem("UserFormCode");
        const userRole = localStorage.getItem("loginUserArray")
        const role = JSON.parse(userRole);
        const roleName = role?.role;
        setRoleName(roleName);
        if (storedFormData) {
            const parsedFormData = JSON.parse(storedFormData);

            if (parsedFormData.length > 0) {
                const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
                if (!formCodeItems.length > 0) {
                    navigate("/app/dashboard");
                }
            } else {
                navigate("/app/dashboard");
            }
        }
    }, []);


    const goToEditProduct = (item) => {
        debugger
        let data = [...priceLvlListingRef.current];
        if (priceLvlListingRef.current?.length > 0) {
            data = data?.map((item) => {
                return {
                    ...item,
                    attributes: {
                        ...item.attributes,
                        salesPrice: Number(item?.attributes?.salesPrice * (1 + (item?.attributes?.tax / 100))).toFixed(2).toString(),
                        purchaseCost: Number(item?.attributes?.purchaseCost * (1 + (item?.attributes?.tax / 100))).toFixed(2).toString(),
                        PriceLevelLists: item?.attributes?.PriceLevelLists?.map((list) => {
                            return {
                                ...list,
                                salesRate: Number(list?.salesRate * (1 + (item?.attributes?.tax / 100))).toFixed(2).toString(),
                            }
                        })
                    }
                }
            });
        }
        if (data?.length > 0) {
            const updatedNewList = [...data];
            setIsAdd(false);
            setSelectedRowItem({});
            if (prevRow?.itemId) {
                //   prevRow.attributes.isRowEdit = false;
                // updatedNewList[index] = row;
                const index = updatedNewList?.findIndex((row) => row?.itemId === prevRow?.itemId && Number(row?.attributes?.mrp).toFixed(2) == Number(prevRow.mrp).toFixed(2) && row?.attributes?.batchNo == prevRow?.batchNo);
                const row = updatedNewList?.find((row) => row?.itemId === prevRow?.itemId && Number(row?.attributes?.mrp).toFixed(2) == Number(prevRow.mrp).toFixed(2) && row?.attributes?.batchNo == prevRow?.batchNo);
                row.attributes.isRowEdit = false;
                updatedNewList[index] = row;
                setPriceListNew(updatedNewList);
                priceNewRef.current = updatedNewList;
            }
            const index = updatedNewList?.findIndex((row) => row?.itemId === item?.itemId && Number(row?.attributes?.mrp).toFixed(2) == Number(item.mrp).toFixed(2) && row?.attributes?.batchNo == item?.batchNo);
            const row = updatedNewList?.find((row) => row?.itemId === item?.itemId && Number(row?.attributes?.mrp).toFixed(2) == Number(item.mrp).toFixed(2) && row?.attributes?.batchNo == item?.batchNo);
            if (row) {
                row.attributes.isRowEdit = true;
            }
            // console.log("edited", row);
            updatedNewList[index] = row;
            setPriceListNew(updatedNewList);
            priceNewRef.current = updatedNewList;
            setSelectedRowItem(item);
            salesPriceInput?.current?.focus();
            salesPriceInput?.current?.addEventListener('keydown', handleKeyDown);
            setIsEditIconClicked(true);
            setIsEdit(true);
            setDataToSend({ ...item });
            setFlagKey(item?.key);
            setPrevRow(item);
            setTimeout(() => {
                document.getElementById(`salesPrice${item?.key}`)?.focus();
            }, 500);
        }
    };

    const validationCheck = (dataToSend) => {

        const { itemId, mrp, batchNo, salesPrice, remarks, updatedBy, purchaseCost } = dataToSend;
        // console.log('price syed', priceListNew);
        let mrpValidationValues;
        /**duplicate MRP Calculation */

        // const filterByItemId = priceListNew.filter((each) => each?.itemId == itemId && each?.attributes?.isNewRow !== true);
        // console.log('MRP CAL ::: filterByItemId', filterByItemId);
        // for (let eachitems of filterByItemId) {
        //   console.log('MRP CAL ::: eachitems', eachitems);
        //   setMrpValidation(Number(eachitems?.attributes?.mrp)?.toFixed(2) == Number(mrp)?.toFixed(2));
        //   mrpValidationValues = Number(eachitems?.attributes?.mrp)?.toFixed(2) == Number(mrp)?.toFixed(2);
        //   console.log('MRP CAL ::: checkMrp inside mrp', mrp);
        //   console.log('MRP CAL ::: checkMrp inside 0', Number(mrp)?.toFixed(2));
        //   console.log('MRP CAL ::: checkMrp inside 1', Number(eachitems?.attributes?.mrp)?.toFixed(2));
        //   console.log('MRP CAL ::: checkMrp inside 2', Number(mrp)?.toFixed(2) == Number(eachitems?.attributes?.mrp)?.toFixed(2));
        //   console.log('MRP CAL ::: checkMrp inside 3', mrpValidation);
        //   if (mrpValidationValues) break;
        // }
        debugger
        mrpValidationValues = priceListNew.filter(item => item.itemId == itemId).filter(data => data.attributes.mrp == mrp && !data?.attributes?.isRowEdit && !data?.attributes?.isNewRow)?.length > 0 ? true : false;
        setMrpValidation(mrpValidationValues);
        /** SalesPriceValidation*/
        let valid = [];
        setSalesPriceValidation((Number(mrp) < Number(salesPrice)) && Number(mrp) > 0);
        const filteredPriceLevel = priceLevel.filter(level => {
            return Object.keys(dataToSend).includes(level.attributes.pricelevelname);
        });
        filteredPriceLevel?.forEach(level => {
            if ((Number(mrp) < Number(dataToSend[level?.attributes?.pricelevelname])) && (Number(mrp) > 0 && Number(dataToSend[level?.attributes?.pricelevelname]) > 0)) {
                valid.push(false);
                setPricelvlName(level?.attributes?.pricelevelname);
            } else {
                valid.push(true);
            }
        });
        const salesPriceValidationValues = (Number(mrp) < Number(salesPrice)) && Number(mrp) > 0;
        const salesPriceValidationValues1 = valid.includes(false) ? true : false;
        setSalesPriceValidation1(salesPriceValidationValues1);

        let valid1 = [];
        filteredPriceLevel?.forEach(level => {
            if ((Number(dataToSend[level?.attributes?.pricelevelname]) < Number(purchaseCost)) && Number(dataToSend[level?.attributes?.pricelevelname]) > 0) {
                valid1.push(false);
                setPricelvlName1(level?.attributes?.pricelevelname);
            } else {
                valid1.push(true);
            }
        });
        setValid1(valid1);
        /**Cost Validation */
        setCostValidation((Number(salesPrice) < Number(purchaseCost)) && Number(salesPrice) > 0);
        const costValidationValues = (Number(salesPrice) < Number(purchaseCost)) && Number(salesPrice) > 0;
        const costValidationValues1 = valid1.includes(false) ? true : false;
        setCostValidation1(costValidationValues1);
        setValidFilter([...valid1, ...valid, salesPriceValidationValues, costValidationValues]);
        setIsEdit(true);
        return (!mrpValidationValues && !salesPriceValidationValues && !costValidationValues && !salesPriceValidationValues1 && !costValidationValues1);
    }

    const handleKeyDown = (e) => {
        const regex = /[[\]\/';:"\\_=+@#$%^&*()!~`{}|,-]/;
        // if (['e', 'E', '+', '-'].includes(e.key)) {
        if (regex.test(e.key) || e.key == 'ArrowDown' || e.key == 'ArrowUp') {
            e.preventDefault();
        }
    };

    useEffect(() => {
        let data = localStorage.getItem("loginUserArray");
        setCurrentUserId(JSON.parse(data)['id']);
    }, []);

    const handleSalesPriceKey = (e, item) => {
        e.stopPropagation();
        if (e.key == 'ArrowRight' || e.key == 'ArrowDown') {
            priceLevel?.map((level, ind) => {
                if (ind == 0) {
                    document.getElementById(level?.attributes?.pricelevelname + item?.key)?.focus();
                    document.getElementById(level?.attributes?.pricelevelname + item?.key)?.select();
                }
            })
        }
    }

    const handlePriceLvlKey = (e, item) => {
        e.stopPropagation();
        if (e.key == 'ArrowRight' || e.key == 'ArrowDown') {
            priceLevel?.map((level, ind) => {
                if ((parseInt(event.target.getAttribute("data-ind")) + 1) == ind) {
                    document.getElementById(level?.attributes?.pricelevelname + item?.key)?.focus();
                    document.getElementById(level?.attributes?.pricelevelname + item?.key)?.select();
                }
            })
        }
        if (e.key == 'ArrowLeft' || e.key == 'ArrowUp') {
            priceLevel?.map((level, ind) => {
                if ((parseInt(event.target.getAttribute("data-ind")) - 1) == ind) {
                    document.getElementById(level?.attributes?.pricelevelname + item?.key)?.focus();
                    document.getElementById(level?.attributes?.pricelevelname + item?.key)?.select();
                } else if ((parseInt(event.target.getAttribute("data-ind")) - 1) == -1) {
                    document.getElementById("salesPrice" + item?.key)?.focus();
                    document.getElementById("salesPrice" + item?.key)?.select();
                }
            })
        }
    };

    const handleKey2 = (e, item) => {
        debugger
        e.stopPropagation();
        if (e.key == 'Enter') {
            if (isEdit) {
                // let res = EditSubmit();
                // if (res) {
                document.getElementById(`salesPrice${flagKey}_add`)?.click();
                let flag = flagKey + 1;
                setTimeout(() => {
                    document.getElementById(`salesPrice${flag}_edit`)?.focus();
                    // setTimeout(() => {
                    //   document.getElementById(`salesPrice${flag}`)?.focus();
                    //   document.getElementById(`salesPrice${flag}`)?.select();
                    // }, 500)
                }, 100);
                if (itemsValue.length > flagKey) {
                    setFlagKey(flagKey + 1);
                } else {
                    setFlagKey(0);
                }
                // }
            } else {
                // document.getElementById(`salesPrice${flagKey}_add`)?.click();
                addNewPriceItem(item);
            }
        } else if (e.key == 'Escape') {
            closeEdit(item);
            setIsEdit(false);
        }
    };

    const handleMrpKey = (e, item) => {
        e.stopPropagation();
        if (e.key == 'Enter') {
            document.getElementById(`salesPrice${item?.key}`).focus();
            document.getElementById(`salesPrice${item?.key}`).select();
        }
    }

    const handleWheel = (e) => {
        e.target.blur();
    };

    const closeEdit = (item) => {
        const updatedNewList = [...priceListNew];
        const index = updatedNewList?.findIndex((row) => row?.itemId === item?.itemId && Number(row?.attributes?.mrp).toFixed(2) == Number(item.mrp).toFixed(2) && row?.attributes?.batchNo == item?.batchNo);
        const row = updatedNewList?.find((row) => row?.itemId === item?.itemId && Number(row?.attributes?.mrp).toFixed(2) == Number(item.mrp).toFixed(2) && row?.attributes?.batchNo == item?.batchNo);
        row.attributes.isRowEdit = false;
        // console.log("edited", row);
        updatedNewList[index] = row;

        setPriceListNew(updatedNewList);
        priceNewRef.current = updatedNewList;
        setSelectedRowItem({});
        setFlagKey(0);
    }

    const onClickDeleteModel = (isDelete = null) => {
        setDeleteModel(!deleteModel);
        setIsDelete(isDelete);
    };
    const onChangeInput = (e, row) => {
        e.preventDefault();
        const { value, name } = e.target;

        // Validate decimal input
        if (value.includes('.')) {
            const decimalLength = value.split('.')[1]?.length || 0;
            if (decimalLength > 2) {
                // Prevent update if more than 2 decimal places
                e.preventDefault();
                return;
            }
        }

        // Update the priceListNew state with the modified row
        const updatedPriceList = priceNewRef.current.map((item, index) =>
            item.itemId === row.itemId && row.key === index
                ? {
                    ...item,
                    attributes: {
                        ...item.attributes,
                        [name]: value, // Only update the modified field
                    },
                }
                : item
        );

        // Log the updated priceList
        // Update the state with the new priceList
        setPriceListNew(updatedPriceList);

        priceNewRef.current = updatedPriceList;
        // Find the updated row from the updated state
        const updatedRow = updatedPriceList.find(
            (item, index) => item.itemId === row.itemId && row.key === index
        );

        // Update the dataToSend with the modified row (this is the only row that should be changed)
        setDataToSend((prevState) => ({
            ...prevState,
            ...updatedRow.attributes, // Only update the attributes for the changed row
            [name]: value, // Set the modified field value
            itemId: row.itemId,
        }));
        let data = { ...dataToSend };
        data[name] = value;
        data["tax"] = row?.tax;
        // setDataToSend(data);
    };

    const EditSubmit = (row) => {
        // let data = {
        //     ...dataToSend,
        // }
        let data = {
            ...row,
        }
        debugger
        let valid = validationCheck(data);
        data["salesPrice"] = Number((Number(data?.salesPrice) / (1 + (data?.tax / 100))));
        data["purchaseCost"] = Number(data?.purchaseCost);
        // if (mrpValidation == false && salesPriceValidation == false && costValidation == false && valid) {
        if (valid) {
            let priceList = {
                "itemId": data?.itemId,
                "mrp": data?.mrp,
                "batchNo": data?.batchNo,
                "salesPrice": data?.salesPrice,
                "remarks": "",
                "updatedBy": currentUserId,
                "entryFrom": "Price List"
            }
            debugger
            Dispatch(updatePriceList(priceList, "priceLevel"));
            const filteredPriceLevel = priceLevel.filter(level => {
                return Object.keys(dataToSend).includes(level.attributes.pricelevelname);
            });
            filteredPriceLevel?.forEach(level => {
                let postdata = {
                    "pricelevelno": level?.pricelevelno,
                    "itemId": data?.itemId,
                    "mrp": data?.mrp,
                    "batchNo": data?.batchNo,
                    "salesRate": Number((Number(data[level?.attributes?.pricelevelname]) / (1 + (dataToSend?.tax / 100)))),
                    "remarks": "",
                    "updatedBy": currentUserId,
                    "entryFrom": "Price List"
                }
                // console.log("postdata", postdata);
                if (postdata?.salesRate !== null && postdata?.salesRate !== undefined && postdata?.salesRate !== "" && postdata?.salesRate !== "NaN" && !isNaN(postdata?.salesRate)) {
                    Dispatch(addPriceLevelList(postdata));
                }
            });
            Dispatch(fetchPriceLevelList());
            setSelectedRowItem({});
            return true;
        }
    };

    /** */
    // const onClickValidationModel=()=>{
    //   setMrpValidation(false)
    // }
    const addNewPriceItemV2 = (dataToSend, mrpValidationValues, salesPriceValidationValues, costValidationValues, salesPriceValidationValues1, costValidationValues1) => {
        const { itemId, mrp, batchNo, salesPrice, remarks, updatedBy } = dataToSend;
        if (!mrpValidationValues && !salesPriceValidationValues && !costValidationValues && !salesPriceValidationValues1 && !costValidationValues1) {
            const data_send_for_add = {
                itemId: itemId,
                mrp: mrp,
                batchNo: "",
                salesPrice: Number((Number(salesPrice) / (1 + (dataToSend?.tax / 100)))),
                remarks: "",
                updatedBy: updatedBy,
                entryFrom: "Price List",
            };
            Dispatch(addPriceList(data_send_for_add));
            const filteredPriceLevel = priceLevel.filter(level => {
                return Object.keys(dataToSend).includes(level.attributes.pricelevelname);
            });
            setTimeout(() => {
                filteredPriceLevel?.forEach(level => {
                    let postdata = {
                        "pricelevelno": level?.pricelevelno,
                        "itemId": dataToSend?.itemId,
                        "mrp": dataToSend?.mrp,
                        "batchNo": dataToSend?.batchNo ? dataToSend?.batchNo : "",
                        "salesRate": Number((Number(dataToSend[level?.attributes?.pricelevelname]) / (1 + (dataToSend?.tax / 100)))),
                        "remarks": "",
                        "updatedBy": currentUserId,
                        "entryFrom": "Price List"
                    }
                    // console.log("postdata", postdata);
                    if (postdata?.salesRate !== null && postdata?.salesRate !== undefined && postdata?.salesRate !== "" && postdata?.salesRate !== "NaN" && !isNaN(postdata?.salesRate)) {
                        Dispatch(addPriceLevelList(postdata));
                    }
                });
            }, 1000)
            setIsAdd(false);
            Dispatch(fetchPriceLevelList());
        }
    }
    const addNewPriceItem = (dataToSend) => {
        debugger
        const { itemId, mrp, batchNo, salesPrice, remarks, updatedBy, purchaseCost } = dataToSend;
        let mrpValidationValues;
        /**duplicate MRP Calculation */
        setSelectedRowItem({});

        // const filterByItemId = priceListNew.filter((each) => each?.itemId == itemId && each?.attributes?.isNewRow !== true);
        // console.log('MRP CAL ::: filterByItemId', filterByItemId);
        // for (let eachitems of filterByItemId) {
        //   console.log('MRP CAL ::: eachitems', eachitems);
        //   setMrpValidation(Number(eachitems?.attributes?.mrp)?.toFixed(2) == Number(mrp)?.toFixed(2));
        //   mrpValidationValues = Number(eachitems?.attributes?.mrp)?.toFixed(2) == Number(mrp)?.toFixed(2);
        //   console.log('MRP CAL ::: checkMrp inside mrp', mrp);
        //   console.log('MRP CAL ::: checkMrp inside 0', Number(mrp)?.toFixed(2));
        //   console.log('MRP CAL ::: checkMrp inside 1', Number(eachitems?.attributes?.mrp)?.toFixed(2));
        //   console.log('MRP CAL ::: checkMrp inside 2', Number(mrp)?.toFixed(2) == Number(eachitems?.attributes?.mrp)?.toFixed(2));
        //   console.log('MRP CAL ::: checkMrp inside 3', mrpValidation);
        //   if (mrpValidationValues) break;
        // }
        debugger
        mrpValidationValues = priceListNew.filter(item => item.itemId == itemId).filter(data => data.attributes.mrp == mrp && !data?.attributes?.isRowEdit && !data?.attributes?.isNewRow)?.length > 0 ? true : false;
        setMrpValidation(mrpValidationValues);
        if (mrpValidationValues) {
            return
        } else {
            /** SalesPriceValidation*/
            let valid = [];
            setSalesPriceValidation((Number(mrp) < Number(salesPrice)) && Number(mrp) > 0);
            const filteredPriceLevel = priceLevel.filter(level => {
                return Object.keys(dataToSend).includes(level.attributes.pricelevelname);
            });
            filteredPriceLevel?.forEach(level => {
                if ((Number(mrp) < Number(dataToSend[level?.attributes?.pricelevelname])) && (Number(mrp) > 0 && Number(dataToSend[level?.attributes?.pricelevelname]) > 0)) {
                    valid.push(false);
                    setPricelvlName(level?.attributes?.pricelevelname);
                } else {
                    valid.push(true);
                }
            });
            const salesPriceValidationValues = ((Number(mrp) < Number(salesPrice)) && Number(mrp) > 0);
            const salesPriceValidationValues1 = valid.includes(false) ? true : false;
            /**Cost Validation */
            setSalesPriceValidation1(salesPriceValidationValues1);
            let valid1 = [];
            filteredPriceLevel?.forEach(level => {
                if (Number(dataToSend[level?.attributes?.pricelevelname]) < Number(purchaseCost) && Number(dataToSend[level?.attributes?.pricelevelname]) > 0) {
                    valid1.push(false);
                    setPricelvlName1(level?.attributes?.pricelevelname);
                } else {
                    valid1.push(true);
                }
            });
            /**Cost Validation */
            setValid1(valid1);
            setCostValidation((Number(salesPrice) < Number(purchaseCost)) && Number(salesPrice) > 0);
            const costValidationValues = (Number(salesPrice) < Number(purchaseCost)) && Number(salesPrice) > 0;
            const costValidationValues1 = valid1.includes(false) ? true : false;
            setValidFilter([...valid1, ...valid, salesPriceValidationValues, costValidationValues]);
            setCostValidation1(costValidationValues1);
            addNewPriceItemV2(dataToSend, mrpValidationValues, salesPriceValidationValues, costValidationValues, salesPriceValidationValues1, costValidationValues1);
        }
    };

    const onConfirm = () => {
        if (isEdit) {
            let data = {
                ...dataToSend,
            }
            // let valid = validationCheck(data);
            // console.log("EditSubmit", data);
            data["salesPrice"] = Number((Number(dataToSend?.salesPrice) / (1 + (dataToSend?.tax / 100))));
            data["purchaseCost"] = Number(dataToSend?.purchaseCost);
            // if (validFilter?.filter(item => item == false).length <= 1) {
            let priceList = {
                "itemId": data?.itemId,
                "mrp": data?.mrp,
                "batchNo": data?.batchNo,
                "salesPrice": data?.salesPrice,
                "remarks": "",
                "updatedBy": currentUserId,
                "entryFrom": "Price List"
            }
            debugger
            Dispatch(updatePriceList(priceList, "priceLevel"));
            const filteredPriceLevel = priceLevel.filter(level => {
                return Object.keys(data).includes(level.attributes.pricelevelname);
            });
            filteredPriceLevel?.forEach(level => {
                let postdata = {
                    "pricelevelno": level?.pricelevelno,
                    "itemId": data?.itemId,
                    "mrp": data?.mrp,
                    "batchNo": data?.batchNo,
                    "salesRate": Number((Number(dataToSend[level?.attributes?.pricelevelname]) / (1 + (dataToSend?.tax / 100)))),
                    "remarks": "",
                    "updatedBy": currentUserId,
                    "entryFrom": "Price List"
                }
                // console.log("postdata", postdata);
                if (postdata?.salesRate !== null && postdata?.salesRate !== undefined && postdata?.salesRate !== "" && postdata?.salesRate !== "NaN" && !isNaN(postdata?.salesRate)) {
                    Dispatch(addPriceLevelList(postdata));
                }
            });
            // console.log('filteredPriceLevel', filteredPriceLevel);
            setSelectedRowItem({});
            setIsEdit(false);
            // }
        } else {
            addNewPriceItemV2(dataToSend, false, false, false, false, false);
        }
        setMrpValidation(false);
        setSalesPriceValidation(false);
        setSalesPriceValidation1(false);
        setCostValidation1(false);
        setCostValidation(false);
    }

    const onClickPriceHistory = (items) => {
        
        setPriceHistoryModalShow(!priceHistoryModalShow);
        setPriceHistoryrow(items);
        !priceHistoryModalShow
            ? Dispatch(fetchItemPriceHistory(true, items?.itemId,items?.mrp,items?.batchNo))
            : "";
    };
    const addPriceListModalShowing = (selected_row) => {
        debugger
        setIsAddButtonClicked(true);
        if (selected_row) {
            selected_row.isNewRow = true;
        }
        setAddPriceListModalShow(!addPriceListModalShow);
        setSelectedRowItem(selected_row);
        if (prevRow) {
            let data = [...priceListNew];
            const index = data?.findIndex((row) => row?.itemId === prevRow?.itemId && Number(row?.attributes?.mrp).toFixed(2) == Number(prevRow.mrp).toFixed(2) && row?.attributes?.batchNo == prevRow?.batchNo);
            const row = data?.find((row) => row?.itemId === prevRow?.itemId && Number(row?.attributes?.mrp).toFixed(2) == Number(prevRow.mrp).toFixed(2) && row?.attributes?.batchNo == prevRow?.batchNo);
            if (row) {
                row.isRowEdit = false;
            }
            if (index !== -1) {
                if (data[index].attributes.isRowEdit) {
                    data[index].attributes.isRowEdit = false;
                    setPriceListNew(data);
                }
                // setPrevRow(data[index]);
            }
        }
    };
    const handleIncExc = () => {
        setIsExclusive(!isExclusive);
    };

    const closeModal = () => {
        setMrpValidation(false);
        setSalesPriceValidation(false);
        setSalesPriceValidation1(false);
        setCostValidation1(false);
        setCostValidation(false);
    };

    const pageChange = (page) => {
        setCurrentPage(page);
    }

    const rowsperPageChange = (count) => {
        setPageCount(count);
    }

    const addNewRow = (item) => {
        setAddPriceListModalShow(false);
        const {
            itemName,
            mrp,
            purchaseCost,
            salesPrice,
            salesUnitName,
            itemId,
            type,
            key,
            batchNo,
            tax
        } = item;
        const addNewItem = {
            itemId: itemId,
            type: type,
            attributes: {
                itemName: itemName,
                mrp: "0.00",
                purchaseCost: "0.00",
                salesPrice: "0.00",
                salesUnitName: salesUnitName,
                isNewRow: true,
                tax: tax
            },
        };
        setCurrentRow(key);
        const updatedNewList = [...priceListNew];
        updatedNewList.splice(key + 1, 0, addNewItem);
        if (key >= ((pageCount * currentPage) - 1)) {
            let count = [10, 15, 25, 50, 100];
            let index = count.indexOf(pageCount);
            if (index != -1 && index < count.length - 1) {
                let i = 0;
                // while (i < count.length && count[i] <= (pageCount * currentPage)) {
                //   i++;
                // }
                // setPageCount(count[i]);
                // setPageCount(count[index + 1]);
                // setPageCount((pageCount * currentPage) + 1);
                setCurrentPage(currentPage + 1);
            } else {
                setPageCount((pageCount * currentPage) + 1);
            }
            // setPageCount(pageCount);
        }
        setTimeout(() => {
            document.getElementById("mrp" + (key + 1))?.focus();
            document.getElementById("mrp" + (key + 1))?.select();
        }, 250);

        setPriceListNew(updatedNewList);
        priceNewRef.current = updatedNewList;
        setFlagKey(key + 1);
        setSelectedRowItem({});
        setIsAdd(true);
    };

    const focusMrp = () => {
        document.getElementById("mrp" + (currentRow + 1))?.focus();
        document.getElementById("mrp" + (currentRow + 1))?.select();
        setMrpValidation(false);
    }

    const focusSalesPrice = () => {
        if (isAdd) {
            document.getElementById("salesPrice" + (currentRow + 1))?.focus();
            document.getElementById("salesPrice" + (currentRow + 1))?.select();
            setFlagKey(currentRow + 1);
        } else {
            document.getElementById("salesPrice" + (flagKey - 1))?.focus();
            document.getElementById("salesPrice" + (flagKey - 1))?.select();
            setFlagKey(flagKey - 1);
        }
        setSalesPriceValidation(false);
        setSalesPriceValidation1(false);
        setCostValidation(false);
        setCostValidation1(false);
    }

    const priceLevelFocus = () => {
        if (isAdd) {
            document.getElementById(pricelvlName + (currentRow + 1))?.focus();
            document.getElementById(pricelvlName + (currentRow + 1))?.select();
        } else {
            document.getElementById(pricelvlName + (flagKey - 1))?.focus();
            document.getElementById(pricelvlName + (flagKey - 1))?.select();
        }
        setSalesPriceValidation(false);
        setSalesPriceValidation1(false);
        setCostValidation(false);
        setCostValidation1(false);
    }

    const priceLevelFocus1 = () => {
        if (isAdd) {
            document.getElementById(pricelvlName1 + (currentRow + 1))?.focus();
            document.getElementById(pricelvlName1 + (currentRow + 1))?.select();
        } else {
            document.getElementById(pricelvlName1 + (flagKey - 1))?.focus();
            document.getElementById(pricelvlName1 + (flagKey - 1))?.select();
        }
        setSalesPriceValidation(false);
        setSalesPriceValidation1(false);
        setCostValidation(false);
        setCostValidation1(false);
    }

    const removeAddedItem = (item) => {
        const {
            itemName,
            mrp,
            purchaseCost,
            salesPrice,
            salesUnitName,
            itemId,
            type,
            key,
            batchNo,
        } = item;
        const updatedNewList = [...priceListNew];
        updatedNewList.splice(key, 1);

        setPriceListNew(updatedNewList);
        priceNewRef.current = updatedNewList;
        setFlagKey(0);
    };
    // const itemsValue =
    //     priceListNew?.length >= 0 &&
    //     priceListNew?.map((item, index) => ({
    //         key: index,
    //         itemId: item?.itemId,
    //         itemName: item?.attributes?.itemName,
    //         // mrp: Number(item?.attributes?.mrp).toFixed(2),
    //         mrp: item?.attributes?.isNewRow ? item?.attributes?.mrp : Number(item?.attributes?.mrp).toFixed(2),
    //         /**TODO Tax*/
    //         // purchaseCost: isExclusive ? Number(item?.attributes?.purchaseCost).toFixed(2).toString(): Number(item?.attributes?.purchaseCost).toFixed(2).toString(),
    //         // salesPrice: isExclusive ? Number(item?.attributes?.salesPrice).toFixed(2).toString() : Number(item?.attributes?.salesPrice).toFixed(2).toString(),
    //         purchaseCost: isExclusive ? item?.attributes?.purchaseCost : item?.attributes?.purchaseCost,
    //         salesPrice: isExclusive ? item?.attributes?.salesPrice : item?.attributes?.salesPrice,
    //         salesUnitName: item?.attributes?.salesUnitName,
    //         batchNo: item?.attributes?.batchNo,
    //         remarks: item?.attributes?.remarks,
    //         updatedBy: currentUserId,
    //         entryFrom: "Price List",
    //         isNewRow: item?.attributes?.isNewRow ?? false,
    //         isRowEdit: item?.attributes?.isRowEdit ?? false,
    //         tax: item?.attributes?.tax
    //     }));

    const itemsValue =
        priceListNew?.length >= 0 && priceListNew?.map((item, index) => {
            // Initialize the base object
            const resultItem = {
                key: index,
                itemId: item?.itemId,
                itemName: item?.attributes?.itemName,
                mrp: item?.attributes?.isNewRow
                    ? item?.attributes?.mrp
                    : Number(item?.attributes?.mrp).toFixed(2),
                purchaseCost: isExclusive
                    ? item?.attributes?.purchaseCost
                    : item?.attributes?.purchaseCost,
                salesPrice: isExclusive
                    ? item?.attributes?.salesPrice
                    : item?.attributes?.salesPrice,
                salesUnitName: item?.attributes?.salesUnitName,
                batchNo: item?.attributes?.batchNo,
                remarks: item?.attributes?.remarks,
                updatedBy: currentUserId,
                entryFrom: "Price List",
                isNewRow: item?.attributes?.isNewRow ?? false,
                isRowEdit: item?.attributes?.isRowEdit ?? false,
                tax: item?.attributes?.tax,
            };

            // Retain already existing price level values
            if (priceLevel?.length > 0) {

                priceLevel?.forEach((pl) => {
                    const priceLevelName = pl?.attributes?.pricelevelname;

                    // Use existing value if available, else default to 0.00
                    resultItem[priceLevelName] =
                        item?.attributes?.[priceLevelName] ??
                        parseFloat(item?.attributes?.PriceLevelLists?.find(
                            (plItem) => plItem?.priceLevelName === priceLevelName
                        )?.salesRate).toFixed(2) ??
                        parseFloat(0).toFixed(2);
                });
            }
            return resultItem;
        });




    const handleSearchBar = (event) => {
        const { name, value } = event.target;
        const filtered_prices =
            value.length > 0
                ? filterPriceListNew.filter((item) =>
                    item?.attributes?.itemName
                        ?.toLowerCase()
                        ?.includes(value?.toLowerCase())
                )
                : filterPriceListNew;

        setPriceListNew(filtered_prices);
        priceNewRef.current = filtered_prices;

    };

    const paymentKeyPress = useCallback((e) => {
        // Prevent default only if the key pressed is F6 or F10
        if (e.key === "Enter" && !costValidation && !salesPriceValidation && !mrpValidation && (addPriceListModalShow == false) && (deleteModel == false)) {
            e.preventDefault();
            let flag = flagKey;
            // document.getElementById(`salesPrice${flagKey}_edit`)?.click();
            // document.getElementById(`salesPrice${flagKey}`)?.focus();
            // document.getElementById(`salesPrice0_edit`)?.click();
            // setTimeout(() => {
            //   document.getElementById(`salesPrice0`)?.focus();
            //   document.getElementById(`salesPrice0`)?.select();
            // }, 500);

            document.getElementById(`salesPrice${flag}_edit`)?.click();
            setTimeout(() => {
                document.getElementById(`salesPrice${flag}`)?.focus();
                document.getElementById(`salesPrice${flag}`)?.select();
            }, 500)

            // setFlagKey(flagKey + 1);
        } else if (e.key === 'Esc') {
            e.preventDefault();
        } else if (e.key == 'Enter' && addPriceListModalShow) {
            e.preventDefault();
            setAddPriceListModalShow(true);
        } else if (e.key == 'Enter' && deleteModel) {
            e.preventDefault();
            setDeleteModel(true);
        }
    }, [itemsValue]);

    useEffect(() => {
        window.addEventListener("keydown", paymentKeyPress);

        return () => {
            window.removeEventListener("keydown", paymentKeyPress);
        };
    }, [paymentKeyPress]);

    const handleClose = () => {
        setShow(false);
    };

    const onCancel = () => {
        setBackAlert(false);
        document.getElementById("priceListSearchBar")?.focus();
    }

    return (
        <>
            <Loader />
            <MasterLayout>
                <TabTitle title={placeholderText('Price List')} />
                <TopProgressBar />
                {/* <HeaderTitle
                    title={getFormattedMessage(
                        isExclusive ? "priceList.title.exc" : "priceList.title.inc"
                    )}
                    to="/app/products"
                /> */}
                <div className="d-md-flex align-items-center justify-content-between mb-5">
                    <h1 className="mb-0 create-title">{getFormattedMessage(
                        isExclusive ? "priceList.title.exc" : "priceList.title.inc"
                    )}</h1>
                    <div className="text-end mt-4 mt-md-0">
                        <button className="btn btn-outline-primary back-btn me-2" onClick={() => {
                            setBackAlert(true);
                        }}>
                            {getFormattedMessage("globally.back-btn")}
                        </button>
                        <button className="btn btn-outline-primary crt_product" onClick={() => setShow(true)}>Price Level</button>
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "0.625rem",
                        padding: "20px",
                    }}
                >
                    <PriceListHeaderComponents
                        handleIncExc={handleIncExc}
                        isExclusive={isExclusive}
                        handleSearchBar={handleSearchBar}
                        module={"priceLvl"}
                    />

                    <CommonTable
                        columns={roleName?.toUpperCase() === "USER" ? columns?.filter((col) => col?.name !== "Cost") : columns}
                        // items={itemsValue?.sort((a, b) => a.itemName.localeCompare(b.itemName))}
                        items={priceLvlListing?.length > 0 ? itemsValue : []}
                        totalRows={totalRecord}
                        isLoading={isLoading}
                        subHeader={true}
                        rowsPerPage={pageCount}
                        pagechange={pageChange}
                        rowsChange={rowsperPageChange}
                        currentPage={currentPage}
                    />

                    {addPriceListModalShow && (
                        <AddPriceListConfirmationModal
                            addPriceListModalShow={addPriceListModalShow}
                            addPriceListModalShowing={addPriceListModalShowing}
                            addNewRow={addNewRow}
                            selectedRowItem={selectedRowItem}
                        />
                    )}
                    <DeletePriceLevelList
                        onClickDeleteModel={onClickDeleteModel}
                        deleteModel={deleteModel}
                        onDelete={isDelete}
                        name={getFormattedMessage("priceList.PriceListOfThisItem")}
                    />
                    <PriceLevelHistoryModal
                        priceHistoryShow={priceHistoryModalShow}
                        title="Price History"
                        onClickPriceHistory={onClickPriceHistory}
                        priceHistoryList={priceLvlHistoryList}
                        pricehistoryrow={priceHistoryrow}
                    />
                    {
                        mrpValidation && <ValidationModal onClose={closeModal} onConfirm={focusMrp} name={getFormattedMessage("priceList.DublicteMRP")} mrp={true} />
                    }
                    {
                        salesPriceValidation && <ValidationModal onClose={focusSalesPrice} onConfirm={() => { onConfirm() }} name={getFormattedMessage("Unusual price compared to MRP/cost. Do you want to proceed.?")} />
                    }
                    {
                        salesPriceValidation1 && <ValidationModal onClose={priceLevelFocus} onConfirm={() => { onConfirm() }} name={getFormattedMessage(`Unusual price compared to MRP/cost. Do you want to proceed.?`)} />
                    }
                    {
                        costValidation && <ValidationModal onClose={focusSalesPrice} onConfirm={() => { onConfirm() }} name={getFormattedMessage("Unusual price compared to MRP/cost. Do you want to proceed.?")} />
                    }
                    {
                        costValidation1 && <ValidationModal onClose={priceLevelFocus1} onConfirm={() => { onConfirm() }} name={getFormattedMessage(`Unusual price compared to MRP/cost. Do you want to proceed.?`)} />
                    }
                    {backAlert && (
                        <SweetAlert
                            confirmBtnBsStyle='success mb-3 fs-5 rounded'
                            cancelBtnBsStyle='secondary mb-3 fs-5 rounded text-white'
                            confirmBtnCssClass='alert_yes'
                            confirmBtnText={"Yes"}
                            cancelBtnText={"No"}
                            title={"Back to Product Listing Page.?"}
                            showCancel
                            focusCancelBtn
                            customButtons={
                                <>
                                    <button id="cancel-button" onClick={() => onCancel()} className="btn btn-secondary" onKeyDown={(e) => cancelKey(e)}>
                                        No
                                    </button>

                                    <Link to={"/app/products"} style={{ marginRight: "5%" }}
                                        onKeyDown={(e) => paymentKeyPress(e)}>
                                        <button id="confirm-button" className="btn btn-success" style={{ marginRight: "5%" }} autoFocus={true} >
                                            Yes
                                        </button>
                                    </Link>
                                </>
                            }
                        />
                    )}
                </div>
                <AddPriceLevel show={show} handleClose={handleClose} />
            </MasterLayout>
        </>
    );
};

export default PriceLevelList;

import { validate } from "email-validator";
import apiConfig from "../../config/apiConfig";
import { apiBaseURL, NilStockActionType, gstrReportActionType, toastType } from "../../constants";
import { setLoading } from "./loadingAction";
import { addToast } from "./toastAction";
import { setLoader } from "./loaderAction";

export const fetchGstrReport =
  (values,filter = {}, isLoading = true) =>
    async (dispatch) => {
      if (isLoading) {
        dispatch(setLoading(true));
        dispatch(setLoader(true));
      }

      let url = apiBaseURL.GSTR_REPORT +values;

      apiConfig
        .get(url)
        .then((response) => {
          dispatch({
            type: gstrReportActionType.FETCH_GSTR_REPORT,
            payload: response?.data?.data,
          });
          if(response?.data?.success){
            // dispatch(addToast({ text: response?.data?.message, type: toastType.SUCCESS }));
          }else{
            // dispatch(addToast({ text: response?.data?.message, type: toastType.ERROR }));   
          }
          if (isLoading) {
            dispatch(setLoader(false));
            dispatch(setLoading(false));
          }
        })
        .catch(({ response }) => {
          dispatch(
            addToast({
              text: response?.data?.message,
              type: toastType.ERROR,
            })
          );
          dispatch(setLoader(false));
        });
    };
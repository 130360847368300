import React, { useState, createRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Form, Modal } from "react-bootstrap-v5";
import { addZoneData } from "../../store/action/PosCustomerAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { placeholderText } from "../../shared/sharedMethod";
import ModelFooter from "../../shared/components/modelFooter";
import { toastType } from '../../constants';
import { addToast } from "../../store/action/toastAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ZoneForm = (props) => {
  const {
    title,
    show,
    handleClose,
    editDetails,
    addZoneData,
    data,
    singleZone,
  } = props;

  // console.log("Single  Zone : ", singleZone);

  const innerRef = createRef();
  const disabled = false;
  const [formValue, setFormValue] = useState({
    zoneid: editDetails ? editDetails.zoneid : 0,
    zonename: editDetails ? editDetails.zonename : "",
    isActive: singleZone ? (singleZone?.isActive == "Yes" ? true : false) : true
  });

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({ zonename: "", isActive: false });

  useEffect(() => { }, []);

  useEffect(() => {
    setFormValue({
      zoneid:
        editDetails || singleZone
          ? editDetails
            ? editDetails.zoneid
            : singleZone?.zoneId
          : 0,
      zonename:
        editDetails || singleZone
          ? editDetails
            ? editDetails.zonename
            : singleZone?.zoneName
          : "",
      isActive:
        editDetails?.isActive === "Yes" || singleZone?.isActive === "Yes"
          ? true
          : editDetails?.isActive === "No" || singleZone?.isActive === "No"
            ? false
            : true, // Default is true
    });
  }, [editDetails]);

  // useEffect(() => {
  //   setFormValue
  //   ({
  //     isActive:
  //     singleZone?.isActive === "Yes"
  //       ? true
  //       : false
  //   })
  // },[singleZone])

  const handleValidation = () => {
    let errors = {};
    let isValid = false;
    if (!formValue["zonename"]?.trim()) {
      errors["zonename"] = "Zone is required";
    } else if (formValue["zonename"] && formValue["zonename"].length > 50) {
      errors["zonename"] = "Zone is must not exceed 50 characters";
    } else if (
      data &&
      data.length > 0 &&
      data.some(
        (x) =>
          x.attributes?.name?.toLowerCase() ===
          formValue["zonename"].toLowerCase()
      )
    ) {
      errors["zonename"] = "Zone name already exists.!";
    } else {
      isValid = true;
    }
    setErrors(errors);
    return isValid;
  };

  const onChangeInput = (e) => {
    e.preventDefault();
    setFormValue((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
    setErrors("");
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormValue((prev) => ({
      ...prev,

      [name]: type === 'checkbox' ? checked : value
    }
    ));
  };


  const prepareFormData = (data) => {
    let formData = {
      zoneid: data.zoneid,
      zonename: data.zonename,
      isActive: data?.isActive
    };
    // console.log("Data :", data)
    return formData;
  };

  const closeButtonClick = () => {
    clearField();
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    const valid = handleValidation();
    if (valid) {
      let response = await addZoneData(prepareFormData(formValue));
      if (response?.data?.success === true) {
        handleClose(formValue);
      }
      // clearField();
    }
  };

  const clearField = () => {
    // setFormValue({
    //   zoneid: 0,
    //   zonename: "",
    // });
    setErrors("");
    handleClose(formValue);
  };

  return (
    <Modal
      show={show}
      onHide={clearField}
      keyboard={true}
      onShow={() =>
        setTimeout(() => {
          document.getElementById("name")?.focus();
        }, 1)
      }
    >
      <Form
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onSubmit(e);
          }
        }}
      >
        <Modal.Header className="modal_header_sub">
          <Modal.Title>{title}</Modal.Title>
          <button style={{ backgroundColor: "white", display: "flex", gap: "50px", border: "none" }}
            onClick={closeButtonClick} >
            <FontAwesomeIcon
              icon={faXmark}
              className="fa-2x search-icon"
              style={{
                height: "20px", width: "27px", marginTop: "2px", color: "gray"

              }}

            ></FontAwesomeIcon>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-5">
              <label className="form-label">
                {getFormattedMessage("zone.create.textbox.name")}:{" "}
              </label>
              <span className="required" />
              <input
                type="text"
                name="zonename"
                id="name"
                autoComplete="off"
                placeholder={placeholderText(
                  "globally.input.Zonename.placeholder.label"
                )}
                className="form-control"
                ref={innerRef}
                value={formValue?.zonename}
                onChange={(e) => onChangeInput(e)}
                autoFocus
              />
              <span className="text-danger d-block fw-400 fs-small mt-2">
                {errors["zonename"] ? errors["zonename"] : null}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <input
                type="checkbox"
                name="isActive"
                className="me-3 form-check-input cursor-pointer mt-1 "
                style={{ marginLeft: "10px" }}
                checked={formValue?.isActive}
                placeholder={placeholderText(
                  "globally.input.remarks.placeholder.label"
                )}
                onChange={(e) => handleInputChange(e)}
              />
              <label className="form-label mt-1">
                {getFormattedMessage("globally.input.isActive.?.label")}
              </label>

              <span className="text-danger d-block fw-400 fs-small mt-2"></span>
            </div>
          </div>
        </Modal.Body>
      </Form>
      <ModelFooter
        onEditRecord={singleZone}
        onSubmit={onSubmit}
        editDisabled={disabled}
        clearField={clearField}
        addDisabled={!formValue?.zonename?.trim()}
      />
    </Modal>
  );
};

export default connect(null, { addZoneData })(ZoneForm);

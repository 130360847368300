import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import MasterLayout from '../MasterLayout';
import HeaderTitle from '../header/HeaderTitle';
import { useNavigate } from 'react-router-dom';
import { fetchAllWarehouses } from '../../store/action/warehouseAction';
import { fetchAllSuppliers } from '../../store/action/supplierAction';
import { fetchLedgers, fetchLedger } from '../../store/action/ledgerAction';
import { addPurchase } from '../../store/action/purchaseAction';
import { getFormattedMessage } from "../../shared/sharedMethod";
import SalesItemForm from './SalesItemForm';
import { saleStatusOptions, toastType, cartItem, singleSalesOrderActionType } from '../../constants';

const CreateSalesItem = (props) => {
  const { addPurchase, warehouses, fetchAllWarehouses, fetchAllSuppliers, fetchLedgers, suppliers, fetchLedger, ledger } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllWarehouses();
    fetchAllSuppliers();
    fetchLedger();
    dispatch({
      type: singleSalesOrderActionType.FETCH_SINGLE_SALES_ORDER,
      payload: []
    });
  }, []);

  const [formcode, setFormCode] = useState("T04");

  useEffect(() => {
    ;
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permissionAdd);
        if (!formCodeItems.length > 0) {
          navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    }
  }, []);

  const addPurchaseData = (formValue) => {
    // addPurchase(formValue, navigate);
  };

  return (
    <MasterLayout>
      {/* <HeaderTitle title={getFormattedMessage("purchase.create.title")} to='/app/purchases'/> */}
      <SalesItemForm addPurchaseData={addPurchaseData} warehouses={warehouses} ledger={ledger}
        suppliers={suppliers} singleSalesOrder={{}} />

    </MasterLayout>
  );
};

const mapStateToProps = (state) => {
  const { warehouses, suppliers, totalRecord, ledger } = state;
  return { warehouses, suppliers, totalRecord, ledger }
};

export default connect(mapStateToProps, { addPurchase, fetchAllWarehouses, fetchAllSuppliers, fetchLedgers, fetchLedger })(CreateSalesItem);
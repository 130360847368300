import apiConfig from "../../config/apiConfig";
import { apiBaseURL, toastType, companyConfigActionType } from "../../constants";
import { addToast } from "./toastAction";
import { setLoading } from "./loadingAction";
import { setSavingButton } from "./saveButtonAction";

export const fetchCompanyConfig =
    (filter = {}, isLoading = true) =>
        async (dispatch) => {
            if (isLoading) {
                dispatch(setLoading(true));
            }
            let url = apiBaseURL.COMPANY_CONFIG;
            await apiConfig
                .get(url)
                .then((response) => {
                    dispatch({
                        type: companyConfigActionType.FETCH_COMPANY_CONFIG,
                        payload: response?.data?.data,

                    });
                    if (isLoading) {
                        dispatch(setLoading(false));
                    }
                })

                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };

export const editCompanyConfig =
    (data, imgFiles, signature, navigate, delImageIds) => async (dispatch) => {
                dispatch(setSavingButton(true));
        await apiConfig
            .post(apiBaseURL.COMPANY_CONFIG, data)
            .then((response) => {
                if (response?.data?.success == true) {
                    let result = response.data.data.attributes
                    let companyid = result.companyId ?? 1;
                    if (imgFiles) {
                        imgFiles.append("logoId", companyid);
                        if (delImageIds.companyLogo) {
                            dispatch(deleteLogoImage(companyid, imgFiles));
                        } else {
                            if (imgFiles.get("image") !== 'undefined') {
                                dispatch(addLogoImage(imgFiles));
                            }
                        }
                    }
                    if (signature) {
                        signature.append("logoId", companyid);
                        if (delImageIds.digitalSignature) {
                            dispatch(deleteSignatureImage(companyid, signature));
                        } else {
                            if (signature.get("image") !== 'undefined') {
                                dispatch(addSignatureImage(signature));
                            }
                        }
                    }
                    navigate(`/app/companies`);
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType?.success
                        })
                    )
                } else {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                }

                dispatch(setSavingButton(false));
            })
            .catch((response) => {
                dispatch(setSavingButton(false));
                dispatch(
                    addToast({
                        text: response.data.message,
                        type: toastType.ERROR,
                    })
                );
            });
    };

export const addLogoImage =
    (empImg, navigate) => async (dispatch) => {

        await apiConfig
            .post(apiBaseURL.COMPANY_LOG, empImg)
            .then((response) => {
            })
            .catch(({ response }) => {
                dispatch(setSavingButton(false));
                dispatch(
                    addToast({ text: response?.data?.message, type: toastType.ERROR })
                );
            });
    };

export const deleteLogoImage =
    (id, imgFiles) => async (dispatch) => {
        
        await apiConfig
            .delete(`${apiBaseURL.COMPANY_LOGO_DELETE}?logoId=${id}`)
            .then((response) => {
                if (imgFiles.get("image") !== 'undefined') {
                    dispatch(addLogoImage(imgFiles));
                }
            })
            .catch(({ response }) => {
                dispatch(setSavingButton(false));
                dispatch(
                    addToast({ text: response?.data?.message, type: toastType.ERROR })
                );
            });
    };


export const addSignatureImage =
    (sigImg, navigate) => async (dispatch) => {

        await apiConfig
            .post(apiBaseURL.DIGITAL_SIGNATURE, sigImg)
            .then((response) => {
            })

            .catch(({ response }) => {
                dispatch(setSavingButton(false));
                dispatch(
                    addToast({ text: response?.data?.message, type: toastType.ERROR })
                );
            });
    };

export const deleteSignatureImage =
    (id, signature) => async (dispatch) => {
        
        await apiConfig
            .delete(`${apiBaseURL.DIGITAL_SIGNATURE_DELETE}?logoId=${id}`)
            .then((response) => {
                if (signature.get("image") !== 'undefined') {
                    dispatch(addSignatureImage(signature));
                }
            })

            .catch(({ response }) => {
                dispatch(setSavingButton(false));
                dispatch(
                    addToast({ text: response?.data?.message, type: toastType.ERROR })
                );
            });
    };

export const companyList =
    () => async (dispatch) => {
        await apiConfig
            .get(apiBaseURL.COMPANY_CONFIG_LIST)
            .then((response) => {
                dispatch(setLoading(false));
                dispatch({
                    type: companyConfigActionType.FETCH_COMPANY_CONFIG_LIST,
                    payload: response.data.data,
                });
            })
            .catch(({ response }) => {
                response &&
                    dispatch(
                        addToast({
                            text: response.data.message,
                            type: toastType.ERROR,
                        })
                    );
            });
    };

export const companyDetailById =
    (id) => async (dispatch) => {
        await apiConfig
            .get(`${apiBaseURL.COMPANY_CONFIG}/${id}`)
            .then((response) => {
                dispatch(setLoading(false));
                dispatch({
                    type: companyConfigActionType.FETCH_COMPANY_CONFIG_SPECIFIC,
                    payload: response.data.data,
                });
            })
            .catch(({ response }) => {
                response &&
                    dispatch(
                        addToast({
                            text: response.data.message,
                            type: toastType.ERROR,
                        })
                    );
            });
    };

export const updateCompanyActiveStatus =
    (data) => async (dispatch) => {
        await apiConfig
            .post(apiBaseURL.COMPANY_CONFIG_STATUS, data)
            .then((response) => {
                if (response?.data?.success == true) {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType?.success
                        })
                    )
                }
                dispatch(companyList());
                dispatch(setLoading(false));
            })
            .catch(({ response }) => {
                response &&
                    dispatch(
                        addToast({
                            text: response.data.message,
                            type: toastType.ERROR,
                        })
                    );
            });
    };
import apiConfig from "../../config/apiConfig";
import { apiBaseURL, orderConfigActionType, orderSingleActionType, toastType } from "../../constants";
import { addToast } from "./toastAction";
import requestParam from "../../shared/requestParam";
import { setLoading } from "./loadingAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { setSavingButton } from "./saveButtonAction";
import { callImportProductApi } from "./importProductApiAction";
import { setLoader } from "./loaderAction";


export const fetchOrderConfig =
    (filter = {}, isLoading = true) =>
        async (dispatch) => {
            
            dispatch(setLoader(true));
            if (isLoading) {
                dispatch(setLoading(true));
            }
            let url = apiBaseURL.ORDER_CONFIG;
            apiConfig
                .get(url)
                .then((response) => {
                    dispatch({
                        type: orderConfigActionType.FETCH_ORDER_CONFIG,
                        payload: response?.data?.data,
                    });
                    if (response?.data?.success) {
                        dispatch(setLoader(false));
                    } else {
                        dispatch(setLoader(false));
                    }
                    if (isLoading) {
                        dispatch(setLoading(false));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };

export const fetchSingleOrder =
    (Id, companyId, channelId, isLoading = true) =>
        async (dispatch) => {
            dispatch(setLoader(true));
            if (isLoading) {
                // dispatch(setLoading(true));
            }
            await apiConfig
                .get(apiBaseURL.ORDER_CONFIG + "?orderConfigId=" + Id +"&companyId= " + companyId + "&channelId=" + channelId)
                .then((response) => {
                    dispatch({
                        type: orderSingleActionType.FETCH_SINGLE_ORDER,
                        payload: response?.data?.data,
                    });
                    if (response?.data?.success) {
                        if (isLoading) {
                            // dispatch(setLoading(false));
                        }
                        // dispatch(fetchPosSalesListing());
                        dispatch(setLoader(false));
                    }
                    else {
                        dispatch(setLoader(false));
                        // dispatch(fetchPosSalesListing());
                        dispatch(addToast({ text: response?.data?.message, type: toastType.ERROR }));
                    }
                })
                .catch(({ response }) => {
                    dispatch(
                        addToast({
                            text: response?.data?.message,
                            type: toastType.ERROR,
                        })
                    );
                });
        };


export const addOrderConfig = (status) => async (dispatch) => {
    // dispatch(setSavingButton(true));
    try {
        const response = await apiConfig.post(apiBaseURL.ORDER_CONFIG, status);
        
        dispatch({
            type: orderConfigActionType.ADD_ORDER_CONFIG,
            payload: response?.data?.data,
        });

        if (response?.data?.success === true) {
            dispatch(
                addToast({
                    text: getFormattedMessage("status.success.create.message"),
                })
            );
            // dispatch(fetchOrderStatus());
            window.location.href = "#/app/salesOrder";
        } else {
            dispatch(
                addToast({ text: response?.data?.message, type: toastType.ERROR })
            );
            // dispatch(fetchOrderStatus());
        }
        return response;  // Return the response
    } catch (error) {
        // dispatch(setSavingButton(false));
        dispatch(
            addToast({ text: error.response?.data?.message, type: toastType.ERROR })
        );
        // dispatch(fetchOrderStatus());
        throw error;  // Re-throw the error so it can be caught in the calling function
    }
}

export const updateOrderConfig = (status) => async (dispatch) => {
    // dispatch(setSavingButton(true));
    try {
        const response = await apiConfig.post(apiBaseURL.ORDER_CONFIG, status);
        
        dispatch({
            type: orderConfigActionType.EDIT_ORDER_CONFIG,
            payload: response?.data?.data,
        });

        if (response?.data?.success === true) {
            dispatch(
                addToast({
                    text: getFormattedMessage(response?.data?.message),
                })
            );
            // dispatch(fetchOrderStatus());
            window.location.href = "#/app/salesOrder";
        } else {
            dispatch(
                addToast({ text: response?.data?.message, type: toastType.ERROR })
            );
            // dispatch(fetchOrderStatus());
        }
        return response;  // Return the response
    } catch (error) {
        // dispatch(setSavingButton(false));
        dispatch(
            addToast({ text: error.response?.data?.message, type: toastType.ERROR })
        );
        // dispatch(fetchOrderStatus());
        throw error;  // Re-throw the error so it can be caught in the calling function
    }
}

export const deleteOrder = (Id) => async (dispatch) => {
    
    apiConfig
        .delete(apiBaseURL.ORDER_CONFIG + "?orderConfigId=" + Id)
        .then((response) => {
            dispatch({ type: orderConfigActionType.DELETE_ORDER_CONFIG, payload: Id });
            
            if (response?.data?.success) {
                dispatch(
                    addToast({
                        text: getFormattedMessage(response?.data?.message),
                    })
                );
                // dispatch(fetchEmployees());
            } else {
                dispatch(
                    addToast({
                        type: toastType.ERROR,
                        text: getFormattedMessage(response?.data?.message),
                    })
                );
            }
        })
        .catch(({ response }) => {
            dispatch(
                addToast({ text: response?.data?.message, type: toastType.ERROR })
            );
        });
};
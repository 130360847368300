import React, { useState, useRef } from 'react';
import { Modal, Button, Form, Row, Col, Tab, Nav } from 'react-bootstrap';
import Select from 'react-select';
import { ReactTabulator } from 'react-tabulator';
import 'react-tabulator/lib/styles.css';
import 'react-tabulator/css/bootstrap/tabulator_bootstrap4.min.css';
import Cash_coins from '../../assets/images/Cash_coins.png';
import creditCard from '../../assets/images/creditCard.png';
import qrCode from '../../assets/images/qrCode.png';

let pay;
const customInputEditorWithClassPay = (cell, onRendered, success, cancel) => {
    // Create and style the input element
    const input = document.createElement("input");
    input.type = "text";
    input.id = "variablePay-inp";
    input.value = cell.getValue() || "";

    // Define the allowed characters
    const allowedCharacters = /^[0-9\b.]+$/; // Allows only numbers and backspace

    // Event listener to handle input restriction
    input.addEventListener("input", function (e) {
        const value = e.target.value;
        if (parseInt(value) > pay) {
            e.preventDefault();
            e.target.value = pay;
        }

        const parts = e.target.value.split(".")[1];
        if (parts?.length > 2) {
            e.target.value = value?.split(".")[0] + "." + e.target.value?.split(".")[1]?.slice(0, 2);
        }
        // Test if the current value matches the allowed characters pattern
        if (!allowedCharacters.test(value)) {
            e.target.value = value.replace(/[^0-9.]/g, ""); // Remove disallowed characters
        }

    });

    // Callback for when the editor is created
    onRendered(() => {
        input.focus();
        input.style.width = "100%";
        input.style.height = "100%";
    });

    // Commit the changes when the user hits enter or the input loses focus
    input.addEventListener("blur", () => success(input.value));
    input.addEventListener("keydown", function (e) {
        if (e.key === "Enter") {
            e.preventDefault(); // Prevent the default action of the Enter key

            // Find the current table instance
            const table = cell.getTable();

            // Find the next row
            // const nextRow = table.getRow(cell.getRow().getIndex() + 1);
            const nextRow = table.getRow(cell.getRow().getNextRow());
            if (nextRow) {
                // Find the next row's editable 'Leave Days' cell
                const leaveDaysCell = nextRow.getCell('received');
                if (leaveDaysCell) {
                    // Focus the 'Leave Days' cell
                    leaveDaysCell.getElement().focus();
                    const value = leaveDaysCell.getValue();
                    if (value) {
                        leaveDaysCell?.getElement()?.getElementsByTagName('input')[0]?.select();
                    }
                }
            }
            success(input.value);
        } else if (e.key === "Escape") {
            cancel();
        }
    });

    return input;
};

const CollectionModal = (props) => {
    const { show, onHide } = props;
    const [cash, setCash] = useState(false);
    const [cashPay, setCashPay] = useState(false);
    const [cardPay, setCardPay] = useState(false);
    const [qrPay, setQrPay] = useState(false);

    const tableData = [
        { date: '10-09-2024', invoiceNo: 'AI234', outstanding: 1100.0, odDays: 10, received: '' },
        { date: '09-09-2024', invoiceNo: 'AI225', outstanding: 650.0, odDays: 7, received: '' },
    ];

    const tableRef = useRef(null);
    const refNoRef = useRef(null);
    const refDateRef = useRef(null);
    const receivedAmountRef = useRef(null);
    const collectedByRef = useRef(null);

    const tableColumns = [
        { title: 'Date', field: 'date', },
        {
            title: 'Inv.No', field: 'invoiceNo', bottomCalcFormatter: (cell) => {
                return '<strong style="padding-left: 10px;">Total</strong>'; // Display 'Total' in bold
            }
        },
        {
            title: 'Outstanding', field: 'outstanding', bottomCalc: (values, data) => {
                // Custom calculation logic
                let total = 0;
                values.forEach(value => {
                    total += parseInt(value) || 0; // Ensure values are parsed as numbers
                });
                return total; // Return total with two decimal places
            }
        },
        { title: 'OD Days', field: 'odDays', },
        {
            title: 'Received',
            field: 'received',

            editor: customInputEditorWithClassPay,
            formatter: (cell) => {
                const value = cell.getValue();
                const color = "blue";
                const border = '1px solid darkgrey';
                const borderradius = '5px';
                const padding = '0px 22px'
                return `<span style="color: ${color}; ">${value != undefined ? value : ""}</span>`;
            }, bottomCalc: (values, data) => {
                // Custom calculation logic
                let total = 0;
                values.forEach(value => {
                    if (value) {
                        parseFloat(total);
                        total += parseFloat(value).toFixed(2) || 0;
                    } // Ensure values are parsed as numbers
                });
                return total; // Return total with two decimal places
            }
        }
    ];

    const customStyles = {
        option: (provided) => ({
            ...provided,
            color: '#000',
        }),
        control: (provided) => ({
            ...provided,
            backgroundColor: '#f8f9fa',
            border: '1px solid #ced4da',
        }),
    };

    const handlePayment = (method) => {
        if (method === 'cash') {
            setCash(true);
        }
        else {
            setCash(false);
        }

        if (method === 'cash') {
            setCashPay(true);
            setCardPay(false);
            setQrPay(false);
        }
        else if (method === 'card') {
            setCardPay(true);
            setCashPay(false);
            setQrPay(false);
        }
        else if (method === 'qr') {
            setQrPay(true);
            setCardPay(false);
            setCashPay(false);
        }
    }

    const handleKeyDown = (e, currentField) => {
        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            switch (currentField) {
                case 'collectedBy':
                    setCash(true);
                    document.getElementById('cash-payment').focus();
                    break;
                case 'payMode':
                    if (cashPay) {
                        refNoRef.current.focus();
                    }
                    break;
                case 'refNo':
                    refDateRef.current.focus();
                    break;
                case 'refDate':
                    receivedAmountRef.current.focus();
                    break;
                case 'receivedAmount':
                    if (tableRef.current) {
                        const firstRow = tableRef.current.table.getRows()[0];
                        const receivedCell = firstRow?.getCell('received');
                        if (receivedCell) {
                            receivedCell.getElement().click();
                        }
                    }
                    break;
                default:
                    break;
            }
        } else if (e.key === 'ArrowUp') {
            switch (currentField) {
                case 'payMode':
                    collectedByRef.current.focus();
                    break;
                case 'refNo':
                    document.getElementById('cash-payment').focus();
                    break;
                case 'refDate':
                    refNoRef.current.focus();
                    break;
                case 'receivedAmount':
                    refDateRef.current.focus();
                    break;
                default:
                    break;
            }
        }
    };

    const handleClose = () => {
        onHide();
        setCash(false);
        setCashPay(false);
        setCardPay(false);
        setQrPay(false);
    };

    return (
        <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="w-100 text-center">
                    <h1>ABC SUPER MARKET</h1>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="collectedBy">
                        <Form.Label column sm={3}>
                            Collected By :
                        </Form.Label>
                        <Col sm={9}>
                            <Select
                                ref={collectedByRef}
                                onKeyDown={(e) => handleKeyDown(e, 'collectedBy')}
                                options={[
                                    { value: 'collector1', label: 'Collector 1' },
                                    { value: 'collector2', label: 'Collector 2' }
                                ]}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="payMode">
                        <Form.Label column sm={3}>
                            Pay Mode :
                        </Form.Label>
                        <Col sm={9}>
                            <div className='row payment_mode'>
                                <button
                                    type='button'
                                    id="cash-payment"
                                    className={cashPay ? 'col-3 pay_icon_div active' : 'col-3 pay_icon_div'}
                                    style={{ border: "none", width: '25%' }}
                                    onKeyDown={(e) => handleKeyDown(e, 'payMode')}
                                    onClick={() => handlePayment('cash')}
                                >
                                    <img id="cash-img" src={Cash_coins} style={{ height: '45px', padding: '0' }} className='cashImg' alt="Payment Mode Icon" />
                                </button>
                                <button
                                    type='button'
                                    id="card-payment"
                                    className={cardPay ? 'col-3 pay_icon_div active' : 'col-3 pay_icon_div'}
                                    style={{ border: "none", width: '25%' }}
                                    onClick={() => handlePayment('card')}
                                >
                                    <img src={creditCard} id="card-img" style={{ height: '45px', padding: '0' }} className='creditCardImg' alt="Payment Mode Icon" />
                                </button>
                                <button
                                    type='button'
                                    id="qr-payment"
                                    className={qrPay ? 'col-3 pay_icon_div active' : 'col-3 pay_icon_div'}
                                    style={{ border: "none", width: '25%' }}
                                    onClick={() => handlePayment('qr')}
                                >
                                    <img src={qrCode} id='qr-img' style={{ height: '45px', padding: '0' }} className='qrImg' alt="Payment Mode Icon" />
                                </button>
                            </div>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="refNoDate">
                        <Form.Label column sm={3}>
                            Ref.No & Date :
                        </Form.Label>
                        <Col sm={5}>
                            <Form.Control
                                ref={refNoRef}
                                type="text"
                                placeholder="Ref. No"
                                onKeyDown={(e) => handleKeyDown(e, 'refNo')}
                            />
                        </Col>
                        <Col sm={4}>
                            <Form.Control
                                ref={refDateRef}
                                type="date"
                                onKeyDown={(e) => handleKeyDown(e, 'refDate')}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="receivedAmount">
                        <Form.Label column sm={3}>
                            Received Amount :
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                ref={receivedAmountRef}
                                type="number"
                                step="0.01"
                                onKeyDown={(e) => handleKeyDown(e, 'receivedAmount')}
                            />
                        </Col>
                    </Form.Group>

                    <ReactTabulator
                        columns={tableColumns}
                        data={tableData}
                        options={{ layout: 'fitColumns' }}
                        className="mb-3 collection"
                    />
                </Form>
            </Modal.Body>
            <Modal.Footer className='product-modal-footer'>
                {/* {updateData == null ? */}
                {/* <Button className='product-modal-submit-btn' id='submit' variant="success" >Submit</Button> : */}
                <Button className='collection-modal-submit-btn' id='collection-submit' variant="success" >Submit</Button>
                {/* } */}
                <Button className='collection-modal-cancel-btn' id='Cancel' variant="danger" onClick={handleClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CollectionModal;

import { useNavigate } from "react-router"
import TopProgressBar from "../../shared/components/loaders/TopProgressBar"
import { getFormattedMessage } from "../../shared/sharedMethod"
import HeaderTitle from "../header/HeaderTitle"
import MasterLayout from "../MasterLayout"
import LedgerForm from "./LedgerForm"
import { connect } from "react-redux"
import { addLedger } from "../../store/action/ledgerAction"
import { Filters } from "../../constants"
import { useEffect, useState } from "react"

const CreateLedger =(props) => {
    const {addLedger} =props;
    const navigate =useNavigate();

    const [formcode, setFormCode] = useState("M04");
  useEffect(() => {
    ;
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permissionAdd);
        if(!formCodeItems.length > 0){
            navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    } 
  }, []);

    const addLedgerData = (formValue) => {
        addLedger(formValue, navigate, Filters.OBJ);
    };

    return(
       <div>
        <MasterLayout>
            <TopProgressBar/>
            {/* <HeaderTitle title={getFormattedMessage('ledger.create.title')} to='/app/ledger'/> */}
            <LedgerForm addLedgerData={addLedgerData} title={getFormattedMessage( "ledger.create.title")} to='/app/posCustomer'/>
        </MasterLayout>
       </div>
    )
}

export default connect(null,{addLedger}) (CreateLedger)
import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { addToast } from '../../store/action/toastAction';
import { connect, useDispatch } from 'react-redux';
import { addOrderStatus } from '../../store/action/orderStatusAction';

const AddOrderForm = (props) => {
    const { show, handleClose, orderData, addOrderStatus, channelId } = props;
    const [primary, setPrimary] = useState(false);
    const [status, setStatus] = useState('');
    const [seqNo, setSeqNo] = useState(0);
    const dispatch = useDispatch();
    const [primaryValue, setPrimaryValue] = useState();
    const options = [
        { value: '1', label: 'Yes' },
        { value: '0', label: 'No' }
    ];

    useEffect(() => {
       
        setPrimary(orderData?.filter((item) => (item.attributes.primaryId == 1 || item.attributes.primaryId == 2)).map((item) => {
            return { value: item.statusId, label: item.attributes.statusName }
        }))
        // setPrimary(false);
    }, [orderData]);

    const validateForm = () => {
        if (status == "") {
            dispatch(addToast({ text: "Status Name is Required", type: "error" }));
            return false;
        } else if (primaryValue == null) {
            dispatch(addToast({ text: "Primary Value is Required", type: "error" }));
            return false;
        }
        return true;
    }

    const handleSumbit = async () => {
        if (validateForm()) {

            let metaData = {
                "statusId": 0,
                "companyId": 1,
                "channelId": channelId ? channelId : 1,
                "sequenceNo": parseFloat(primaryValue + (seqNo > 10 ? seqNo : '.0' + seqNo)),
                "statusName": status,
                "isPrimary": false,
                "primaryId": primaryValue,
                "remarks": ""
            }
            const response = await addOrderStatus(metaData);
            if (response?.data?.success === true) {
                handleClose();
            }
        }
    };

    const close = () => {
        setPrimaryValue(null);
        setStatus('');
        setSeqNo("");
        handleClose();
    }

    const handleKeyDown = (e) => {
        const restrictedKeys = [",", ".", "e", "E", "+", "-"];
        if (restrictedKeys.includes(e.key)) {
            e.preventDefault();
        }
        if(e.key === "ArrowDown") {
            e.preventDefault();
        }else if(e.key === "ArrowUp"){
            e.preventDefault();
        }
    };

    const handleWheel = (e) => {
        e.target.blur();
    };

    return (
        <>
            <Modal show={show} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title style={{ width: '100%', textAlign: 'center' }}>Create New Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>New Status</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Status Name"
                                autoFocus
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                autoComplete='off'
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Primary</Form.Label>
                            <Select options={primary} onChange={(e) => setPrimaryValue(e?.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Sequence No.</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Sequence No"
                                value={seqNo == 0 ? '' : seqNo}
                                onChange={(e) => setSeqNo(e.target.value)}
                                onKeyDown={(e) => handleKeyDown(e)}
                                onWheel={(e) => handleWheel(e)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleSumbit} style={{ width:"30%", fontWeight:'700', borderRadius: '8px'}}>
                        Save
                    </Button>
                    <Button variant="danger" onClick={close} style={{ width:"30%", fontWeight:'700', borderRadius: '8px'}}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    const { } = state;
    return {}
};

export default connect(mapStateToProps, { addOrderStatus })(AddOrderForm);
import { useEffect } from "react";
import { connect } from "react-redux";
import { editEmpDepartment, fetchSpecificEmpDepartments } from "../../store/action/empDepartmentAction";
import { useParams } from "react-router";
import { getFormattedMessage } from "../../shared/sharedMethod";
import ZoneForm from "./ZoneForm";

const EditZone =(props) => {
    const {zone,fetchSpecificEmpDepartments,show,handleClose,zones,editEmpDepartment} =props;

    // const {id} =useParams() ;

    // useEffect(() => {
    //     fetchSpecificEmpDepartments(id) ;
    //     editEmpDepartment();
    // },[])

    // console.log("Id",id)

    // const itemsValue = empdepartment && empDepartment.length ===1 && empDepartment.map(empDepartments => ({
    //     departmentName:empDepartments?.attributes?.departmentName,
    //     isActive:empDepartments?.attributes?.isActive,
    //     departmentId: empDepartments?.departmentId 
    // }));
  
    // console.log("Item Value =>",itemsValue)

    return(
        <div>
            {zone && <ZoneForm show={show} 
                        handleClose={handleClose} singleZone={zones} 
                        title={getFormattedMessage('zone.edit.title')} /> }
       </div>
    )
}

const mapStateToProps = (state) => {
    const {zone} = state;
    return { zone }
}

export default connect(mapStateToProps,{fetchSpecificEmpDepartments,editEmpDepartment}) (EditZone)
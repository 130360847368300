import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { addUser } from '../../store/action/userAction';
import {getFormattedMessage} from '../../shared/sharedMethod';
import UsersForm from './UsersForm';
import {  useNavigate, useParams } from 'react-router';
import MasterLayout from '../MasterLayout';
import HeaderTitle from '../header/HeaderTitle';
import { Filters } from '../../constants';
import { fetchPermissions } from '../../store/action/permissionAction';


const CreateUsers= (props) => {
    const {addUser,fetchPermissions,permissions,userPermission} = props;
    const navigate=useNavigate();

    // const [formcode, setFormCode] = useState("U01");
    // // const navigate = useNavigate
    // useEffect(() => {
    //     const storedFormData = localStorage.getItem("UserFormCode");

    //     if (storedFormData) {
    //         const parsedFormData = JSON.parse(storedFormData);

    //         if (parsedFormData.length > 0) {
    //             const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
    //             if (!formCodeItems.length > 0) {
    //                 navigate("/app/dashboard");
    //             }
    //         } else {
    //             navigate("/app/dashboard");
    //         }
    //     }
    // }, []);
    // const {id} = useParams();


    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);

    useEffect(() => {
        const storedFormData = localStorage.getItem("loginUserArray");
        if(JSON.parse(storedFormData).role?.toUpperCase() != "ADMINISTRATOR") {
          navigate("/app/dashboard");
        }
      }, []);

    const addUsersData = (formValue,formData) => {
        addUser(formValue, navigate, Filters.OBJ,formData);
    };

    const prepareFormOption = {
        permissionsArray: permissions
    };

    // const [resetForm, setResetForm] = useState(false);

    // const onClick = () => {
    //   setResetForm((prev) => !prev); 
    // };

   const resetForm=true
  
    return (
        <MasterLayout>
            {/* <HeaderTitle title={getFormattedMessage( "user.create.account.title")} /> */}
            <UsersForm addUsersData={addUsersData} title={getFormattedMessage("user.create.account.title")}
                        {...prepareFormOption}  to='/app/users' resetForm={resetForm}  />
        </MasterLayout>

    )
};

const preparePermissions = permissions => {
    let permissionArray = [];
    permissions?.forEach(permission => {
        permissionArray?.push({id: permission?.id, name: permission?.attributes?.menu,
            code: permission?.attributes?.menuCode
            // Visibility :permission?.attributes?.visibility
        })
    });
    return permissionArray;
};


const mapStateToProps = (state) => {
    const {permissions,userPermission} = state ;
    return {permissions: preparePermissions(permissions),userPermission}
}

export default connect(mapStateToProps, {addUser,fetchPermissions})(CreateUsers);

import apiConfig from "../../config/apiConfig";
import {
    apiBaseURL,
    dailySalesActionType,
    toastType,
    Filters,
    dateFormat,
    invoiceSummaryActionType,
    
    
   
} from "../../constants";
import requestParam from "../../shared/requestParam";
import { addToast } from "./toastAction";
import {
    setTotalRecord,
    addInToTotalRecord,
    removeFromTotalRecord,
} from "./totalRecordAction";
import { setLoading } from "./loadingAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { date } from "faker/lib/locales/az";
import { DATETIME_MS } from "ag-charts-community/dist/esm/es6/module-support";
import { month } from "faker/lib/locales/az/date";
import FilterComponent from "../../shared/components/FilterComponent";
import { setLoader } from "./loaderAction";

export const fetchInvoiceSummary =
    (values,filter = {}, isLoading = true) =>
    async (dispatch) => {
        if (isLoading) {
            dispatch(setLoading(true));
            dispatch(setLoader(true));
        }

    let url=apiBaseURL.DAILY_SALES+values;
        apiConfig
            .get(url)
            .then((response) => {
                dispatch({
                    type: invoiceSummaryActionType.FETCH_INVOICE_SUMMARY,
                    payload: response?.data?.data,
                });
                if (isLoading) {
                    dispatch(setLoading(false));
                    dispatch(setLoader(false));
                }
            })
            .catch(({ response }) => {
                dispatch(
                    addToast({
                        text: response.data.message,
                        type: toastType.ERROR,
                    })
                );
                dispatch(setLoading(false));
                dispatch(setLoader(false));
            });
    };

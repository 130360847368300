import DataTable from "react-data-table-component";
import EmptyComponent from "../../components/empty-component/EmptyComponent";
import { getFormattedMessage } from "../sharedMethod";
import { useEffect, useState } from "react";

const CommonTable = (props) => {
  const {
    items,
    columns,
    isLoading,
    paginationRowsPerPageOptions = [10, 15, 25, 50, 100],
    totalRows,
    rowsPerPage,
    priceHistoryList,
    pagechange,
    rowsChange,
    currentPage,
    getItemPriceHistory,
  } = props;

  const [rows, setRows] = useState(rowsPerPage);

  debugger;
  const paginationComponentOptions = {
    rowsPerPageText: getItemPriceHistory
      ? ""
      : getFormattedMessage("react-data-table.records-per-page.label"),
  };

  // Update the rows when rowsPerPage changes
  useEffect(() => {
    setRows(rowsPerPage);
  }, [rowsPerPage]);

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    pagechange(page);
  };

  const handleRows = (rows) => {
    rowsChange(rows);
  };

  return (
    <>
      <DataTable
        key={rows}
        columns={columns}
        data={items}
        noDataComponent={<EmptyComponent isLoading={isLoading} />}
        pagination={getItemPriceHistory ? false : true}
        paginationRowsPerPageOptions={
          getItemPriceHistory ? undefined : paginationRowsPerPageOptions
        }
        paginationPerPage={getItemPriceHistory ? undefined : rows}
        paginationDefaultPage={getItemPriceHistory ? undefined : currentPage}
        paginationComponentOptions={
          getItemPriceHistory ? undefined : paginationComponentOptions
        }
        paginationTotalRows={getItemPriceHistory ? undefined : totalRows}
        defaultSortFieldId={5}
        defaultSortAsc={priceHistoryList ? false : true}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRows}
      />
    </>
  );
};

export default CommonTable;

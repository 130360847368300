import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import MasterLayout from '../MasterLayout';
import HeaderTitle from '../header/HeaderTitle';
import { useNavigate } from 'react-router-dom';
import { fetchAllWarehouses } from '../../store/action/warehouseAction';
import { fetchAllSuppliers } from '../../store/action/supplierAction';
import { fetchLedgers, fetchLedger } from '../../store/action/ledgerAction';
// import WholeSaleForm from './WholeSaleForm';
import { addPurchase } from '../../store/action/purchaseAction';
import { getFormattedMessage } from "../../shared/sharedMethod";
import { cartItem } from '../../constants';
import CollectionForm from './CollectionForm';

const CreateWholeSales = (props) => {
  const { addPurchase, warehouses, fetchAllWarehouses, fetchAllSuppliers, fetchLedgers, suppliers, fetchLedger, ledger } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllWarehouses();
    fetchAllSuppliers();

    // fetchLedgers();
    fetchLedger();
    dispatch({ type: cartItem.CART_ITEMS, payload: [] });
    // console.log(data);
  }, []);

  const [formcode, setFormCode] = useState("T03");
  //   const navigate =useNavigate();
  useEffect(() => {
    ;
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      // console.log("Parsed Form Data:", parsedFormData);
      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permissionAdd);
        // console.log("Form Code Items:", formCodeItems);
        if (!formCodeItems.length > 0) {
          navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    }
  }, []);

  const addPurchaseData = (formValue) => {
    // addPurchase(formValue, navigate);
  };

  return (
    <MasterLayout>
      {/* <WholeSaleForm addPurchaseData={addPurchaseData} warehouses={warehouses} ledger={ledger}
        suppliers={suppliers} mode={"create"} /> */}
        <CollectionForm/>

    </MasterLayout>
  );
};

const mapStateToProps = (state) => {
  const { warehouses, suppliers, totalRecord, ledger } = state;
  return { warehouses, suppliers, totalRecord, ledger }
};

export default connect(mapStateToProps, { addPurchase, fetchAllWarehouses, fetchAllSuppliers, fetchLedgers, fetchLedger })(CreateWholeSales);
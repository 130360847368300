import React from "react";
import {
    Modal,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Paper,
    IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";

const BillHistory = (props) => {
    const { open, handleClose } = props;
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%", // Larger width for popup
        bgcolor: "#fff7f7", // Matching the light red background color in the image
        boxShadow: 24,
        p: 4,
        borderRadius: "8px",
        border: "2px solid #90182A", // Border color matching the theme
    };

    const tableHeaderStyle = {
        backgroundColor: "#90182A", // Header background color
        color: "#ffffff", // Header text color
    };

    const data = [
        { date: "01-10-24", payMode: "Credit", transaction: "Sales", amount: 1000, createdBy: "X" },
        { date: "02-10-24", payMode: "Cash", transaction: "Receipt", amount: 500, createdBy: "Y" },
    ];

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="bill-history-modal" aria-describedby="bill-history-details">
            <Box sx={style}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2, position: "relative" }}>
                    <Typography variant="h6" align="center" gutterBottom sx={{ color: "#90182A", fontWeight: "bold" }}>
                        Bill History
                    </Typography>
                    <Box sx={{ position: "absolute", right: 0, display: "flex", gap: 1 }}>
                        <IconButton sx={{ color: "#90182A" }}>
                            <ShareIcon />
                        </IconButton>
                        <IconButton onClick={handleClose} sx={{ color: "#90182A" }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, color: "#90182A" }}>
                    <Typography variant="body2">Customer:</Typography>
                    <Typography variant="body2">Invoice No:</Typography>
                    <Typography variant="body2">Invoice Date:</Typography>
                    <Typography variant="body2">Pay Mode:</Typography>
                </Box>

                <TableContainer component={Paper} sx={{ width: "100%" }}> {/* Ensuring the table fills the popup width */}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={tableHeaderStyle}>Date</TableCell>
                                <TableCell sx={tableHeaderStyle}>Pay Mode</TableCell>
                                <TableCell sx={tableHeaderStyle}>Transaction</TableCell>
                                <TableCell sx={tableHeaderStyle}>Amount</TableCell>
                                <TableCell sx={tableHeaderStyle}>Created By</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.date}</TableCell>
                                    <TableCell>{row.payMode}</TableCell>
                                    <TableCell>{row.transaction}</TableCell>
                                    <TableCell>{row.amount.toFixed(2)}</TableCell>
                                    <TableCell>{row.createdBy}</TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={2}></TableCell>
                                <TableCell align="left">
                                    <Typography fontWeight="bold" sx={{ color: "#90182A" }}>Balance:</Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography fontWeight="bold" sx={{ color: "#90182A" }}>500.00</Typography>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box sx={{ mt: 2, textAlign: "right" }}>
                    <Typography fontWeight="bold" variant="body1" sx={{ color: "#90182A" }}>
                        Closing Balance: 500.00
                    </Typography>
                </Box>
            </Box>
        </Modal>
    );
};

export default BillHistory;

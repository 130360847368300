import apiConfig from "../../config/apiConfig";
import { apiBaseURL, NilStockActionType, toastType } from "../../constants";
import { setLoader } from "./loaderAction";
import { setLoading } from "./loadingAction";
import { addToast } from "./toastAction";

export const fetchAllNilStocks =
  (filter = {}, isLoading = true) =>
    async (dispatch) => {
      if (isLoading) {
        dispatch(setLoading(true));
        dispatch(setLoader(true));
      }

      let url = apiBaseURL.NIL_STOCK ;

      apiConfig
        .get(url)
        .then((response) => {

          dispatch({
            type: NilStockActionType.FETCH_NIL_STOCK,
            payload: response?.data?.data,
          });


          if (isLoading) {
            dispatch(setLoading(false));
            dispatch(setLoader(false));
          }

        })
        .catch(({ response }) => {
          dispatch(
            addToast({
              text: response?.data?.message,
              type: toastType.ERROR,
            })
          );
        });
    };



export const fetchNilStockReportFilter =
    (values,filter = {}, isLoading = true) =>
    async (dispatch) => {
        if (isLoading) {
            dispatch(setLoading(true));
        }
        let url = apiBaseURL.CLOSINGSTOCKREPORT+values;
       
        apiConfig
            .get(url)
            .then((response) => {
                dispatch({  
                    type: NilStockActionType.FETCH_NIL_STOCK_FILTER,
                    payload: response?.data?.data,
                });
                
                if (isLoading) {
                    dispatch(setLoading(false));
                }
            })
            .catch(({ response }) => {
                dispatch(
                    addToast({
                        text: response?.data?.message,
                        type: toastType.ERROR,
                    })
                );
            });
    };

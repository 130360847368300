import {
  faEye,
  faFileExcel,
  faFilePdf,
  faFilter,
  faPrint,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { ReactTabulator } from "react-tabulator";
import { fetchAllStockMovement } from "../../../store/action/stockMovementReportAction";
import { connect } from "react-redux";
import ClosingStockReportForm from "../closingStockReport/ClosingStockReportForm";
import ClosingStockField from "../closingStockReport/ClosingStockField";
import { fetchCompanyConfig } from "../../../store/action/companyConfigAction";
import {
  generateClosingStockReportExcel,
  generatePDF,
  printPDF,
} from "../pdfUtil";
import Loader from "../../loader/Loader";
import FilterModal from "../../priceList/FilterModal";

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const StockMovementReport = (props) => {
  const {
    fetchAllStockMovement,
    stockMovement,
    fetchCompanyConfig,
    companyConfig,
  } = props;

  const today = new Date();
  const formattedDate = formatDate(today);
  const numOfDaysAdded = 0;
  const date = today.setDate(today.getDate() + numOfDaysAdded);
  const defaultValue = new Date(date).toISOString().split("T")[0]; // YYYY-MM-dd

  const formatDecimal = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();

    return value.toFixed(2);
  };

  const [filterStockMovement, setFilterStockMovement] = useState([]);
  const [fieldShow, setFieldShow] = useState(false);
  const handleFieldClose = () => setFieldShow(!fieldShow);
  const [showField, setShowField] = useState();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(!show);

  const [label, setLabel] = useState(false);
  const [fetchRangeValue, setFetchRangeValue] = useState({
    category1Name: "",
    category2Name: "",
    category3Name: "",
    unitName: "",
  });

  const [range, setRange] = useState({
    category1Name: "",
    category2Name: "",
    category3Name: "",
    unitName: "",
  });

  useEffect(() => {

    setFilterStockMovement(stockMovement);
  }, [stockMovement]);

  // useEffect(() => {
  //   fetchAllStockMovement() ;
  // },[])

  const paySlipRef = useRef(null);
  const TableRef = useRef(null);

  useEffect(() => {
    fetchCompanyConfig();
  }, [fetchCompanyConfig]);


  const [showFilterModals, setShowFilterModals] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    brand: "ALL",
    category: "ALL",
    group: "ALL",
  });
  const [filterTextShow, setFilterTextShow] = useState(false);
  const showFilterModal = () => {
    setShowFilterModals(!showFilterModals);
  };
  const selectedFilterSetting = (
    filtered_brand,
    filtered_group,
    filtered_category
  ) => {
    setSelectedFilter({
      brand: filtered_brand,
      category: filtered_category,
      group: filtered_group,
    });

    setFilterTextShow(
      filtered_brand || filtered_group || filtered_category ? true : false
    );
  };

  const companyDetails = {
    companyName: companyConfig?.companyName || "Company Name",
    address: `${companyConfig?.attributes?.address1 || ""} , ${companyConfig?.attributes?.address2 || ""
      }`,
    phoneNumber: companyConfig?.attributes?.phoneNo || "Phone Number",
  };

  const handleExportToPDF = () => {
    generatePDF(
      paySlipRef,
      companyDetails,
      "Portrait",
      "Stock Movement Report",
      selectedFilter,
      itemsValue,
      columns
    )
      .then((pdf) => {
        pdf.save("Stock Movement Report.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  const handlePrintPDF = () => {
    printPDF(
      paySlipRef,
      companyDetails,
      "Portrait",
      "Stock Movement Report",
      selectedFilter,
      itemsValue,
      columns
    );
  };

  const reportDetails = {
    title: "Stock Movement Report",
    dateRange: formattedDate,
  };

  const handleExportExcel = () => {
    generateClosingStockReportExcel(companyDetails, reportDetails, itemsValue);
  };

  const columns = [
    {
      title: "Code", field: "code", headerSort: false, width: "10%", bottomCalc: () => 'Total', // Custom text for the bottom of this column
      bottomCalcFormatter: (cell) => {
        return '<strong>Total</strong>'
      }
    },
    {
      title: "Product Name",
      field: "productName",
      headerSort: false,
      width: "20%",
    },
    {
      title: "MRP",
      field: "mrp",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: "8%",
      formatter: formatDecimal
    },
    // { title: "Openings", field: "openings", headerSort: false, hozAlign: "right", headerHozAlign: "right", formatter: formatDecimal, width: "8%" },
    // { title: "Unit Name", field: "unitName", headerSort: false, hozAlign: "right", headerHozAlign: "right", width: "10%" },
    {
      title: "Opening",
      field: "openings",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      // field: "stocks",
      // width: "25%",
      height: "10px"
      , bottomCalc: (values, data) => {
        // Custom calculation logic
        let total = 0;
        values.forEach(value => {
          total += parseFloat(value) || 0; // Ensure values are parsed as numbers
        });
        return total.toFixed(2); // Return total with two decimal places
      },
      formatter: function (cell) {
        // Access the row data
        const rowData = cell.getRow().getData();
        const openings = rowData.openings;
        const unitName = rowData.unitName;

        return `<span style="border-right: 2px solid #dddddd;
    padding-right: 3%;
    display: inline-flex;
    height: 43px;
    margin-top: -8px;
    /* padding: 0% 0%; */
    width: 50%;
    justify-content: center;
    align-items: center;">${openings}</span><span style="display: inline-flex;width: 50%; justify-content: center;
    align-items: center;">${unitName}</span>`;
      },
    },
    // { title: "Inward", field: "inWard", headerSort: false, hozAlign: "right", headerHozAlign: "right", formatter: formatDecimal, width: "8%" },
    // { title: "Unit Name", field: "unitName", headerSort: false, hozAlign: "right", headerHozAlign: "right", width: "10%" },
    ...(showField?.inWard || showField == undefined ? [{
      title: "Inward",
      field: "inWard",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      // field: "stocks",
      // width: "25%",
      height: "10px"
      , bottomCalc: (values, data) => {
        // Custom calculation logic
        let total = 0;
        values.forEach(value => {
          total += parseFloat(value) || 0; // Ensure values are parsed as numbers
        });
        return total.toFixed(2); // Return total with two decimal places
      },
      // formatter: formatDecimal,
      formatter: function (cell) {
        // Access the row data
        const rowData = cell.getRow().getData();
        const inWard = rowData.inWard;
        const unitName = rowData.unitName;

        return `<span style="border-right: 2px solid #dddddd;
    padding-right: 3%;
    display: inline-flex;
    height: 43px;
    margin-top: -8px;
    /* padding: 0% 0%; */
    width: 50%;
    justify-content: center;
    align-items: center;">${inWard}</span><span style="display: inline-flex;width: 50%; justify-content: center;
    align-items: center;">${unitName}</span>`;
      }

    }] : []),
    // { title: "Outward", field: "outWard", headerSort: false, hozAlign: "right", headerHozAlign: "right", formatter: formatDecimal, width: "8%" },
    // { title: "Unit Name", field: "unitName", headerSort: false, hozAlign: "right", headerHozAlign: "right", width: "10%" },
    ...(showField?.outWard || showField == undefined ? [{
      title: "Outward",
      field: "outWard",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      // field: "stocks",
      // width: "25%",
      height: "10px"
      , bottomCalc: (values, data) => {
        // Custom calculation logic
        let total = 0;
        values.forEach(value => {
          total += parseFloat(value) || 0; // Ensure values are parsed as numbers
        });
        return total.toFixed(2); // Return total with two decimal places
      },
      formatter: function (cell) {
        // Access the row data
        const rowData = cell.getRow().getData();
        const outWard = rowData.outWard;
        const unitName = rowData.unitName;

        return `<span style="border-right: 2px solid #dddddd;
    padding-right: 3%;
    display: inline-flex;
    height: 43px;
    margin-top: -8px;
    /* padding: 0% 0%; */
    width: 50%;
    justify-content: center;
    align-items: center;">${outWard}</span><span style="display: inline-flex;width: 50%; justify-content: center;
    align-items: center;">${unitName}</span>`;
      }

    }] : []),
    ...(showField?.closing || showField == undefined ? [{
      title: "Closings",
      field: "closing",
      headerSort: false,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: formatDecimal,
      width: "12%"
      , bottomCalc: (values, data) => {
        // Custom calculation logic
        let total = 0;
        values.forEach(value => {
          total += parseFloat(value) || 0; // Ensure values are parsed as numbers
        });
        return total.toFixed(2); // Return total with two decimal places
      }
    }] : []),
    // {
    //   title: "Closing",
    //   headerSort: false,
    //   hozAlign: "center",
    //   headerHozAlign: "center",
    //   // field: "stocks",
    //   // width: "25%",
    //   height: "10px",
    //   formatter: function (cell) {
    //     // Access the row data
    //     const rowData = cell.getRow().getData();
    //     const closings = rowData.closing;
    //     const unitName = rowData.unitName;

    //     return `<span style="border-right: 2px solid #dddddd;
    // padding-right: 3%;
    // display: inline-flex;
    // height: 43px;
    // margin-top: -8px;
    // /* padding: 0% 0%; */
    // width: 50%;
    // justify-content: center;
    // align-items: center;">${closings}</span><span style="display: inline-flex;width: 50%; justify-content: center;
    // align-items: center;">${unitName}</span>`;
    //   },
    // },
  ];

  //   const [typingTimeout, setTypingTimeout] = useState(null);
  const [inputValue, setInputValue] = useState(0);

  const fromDate = useRef();
  const toDate = useRef();

  const loadValues = (filter) => {
    let values =
      "?fromDate=" +
      fromDate?.current?.value +
      "&toDate=" +
      toDate?.current?.value;

    fetchAllStockMovement(values, filter, true);
  };

  const onChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // if (typingTimeout) {
    //   clearTimeout(typingTimeout,3000);
    // }

    // const timeOutId = setTimeout(() => {
    //  loadValues();
    // }, 3000);

    // setTypingTimeout(timeOutId);
  };

  const handleSearchData = (e) => {
    const { name, value } = e.target;
    const filtered_Stock_movement =
      value.length > 0
        ? stockMovement?.filter((item) => {
          const itemCode = item?.attributes?.itemCode?.toLowerCase() || "";
          const itemName =
            item?.attributes?.itemName?.toString().toLowerCase() || "";

          return (
            itemCode.includes(value.toLowerCase()) ||
            itemName.includes(value.toLowerCase())
          );
        })
        : stockMovement;

    setFilterStockMovement(filtered_Stock_movement);
  };

  const itemsValue =
    filterStockMovement?.length > 0
      ? (selectedFilter.brand !== "ALL" || selectedFilter?.category !== "ALL" || selectedFilter?.group !== "ALL"
        ? filterStockMovement.filter(item => {
          let shouldInclude = true;

          // Check brand filter
          if (selectedFilter?.brand !== "ALL" && selectedFilter?.brand !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category1Name === selectedFilter.brand;
          }

          // Check category filter
          if (selectedFilter?.category !== "ALL" && selectedFilter?.category !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category2Name === selectedFilter.category;
          }

          // Check group filter
          if (selectedFilter?.group !== "ALL" && selectedFilter?.group !== undefined) {
            shouldInclude = shouldInclude && item?.attributes?.category3Name === selectedFilter.group;
          }

          return shouldInclude;
        })
        : filterStockMovement
      ).map(StockMovement => {
        return {
          code: StockMovement?.attributes?.itemCode,
          productName: StockMovement?.attributes?.itemName,
          mrp: StockMovement?.attributes?.mrp,
          openings: parseFloat(StockMovement?.attributes?.openings).toFixed(2),
          inWard: StockMovement?.attributes?.inWard,
          outWard: StockMovement?.attributes?.outWard,
          unitName: StockMovement?.attributes?.unitName,
          closing: StockMovement?.attributes?.closing,
          unitName: StockMovement?.attributes?.unitName,
        };
      })
      : [];

  const totalSalesValue = (data) => {
    return new Intl.NumberFormat("en-IN", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(data.reduce((acc, curr) => acc + parseFloat(curr.closing), 0));
  };
  const totalOpeningValue = (data) => {
    return new Intl.NumberFormat("en-IN", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(data.reduce((acc, curr) => acc + parseFloat(curr.openings), 0));
  };
  const totalInWardValue = (data) => {
    return new Intl.NumberFormat("en-IN", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(data.reduce((acc, curr) => acc + parseFloat(curr.inWard), 0));
  };
  const totalOutWardValue = (data) => {
    return new Intl.NumberFormat("en-IN", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(data.reduce((acc, curr) => acc + parseFloat(curr.outWard), 0));
  };
  const handleClick = (e) => {
    setFieldShow(true);
  };

  const onClick = () => {
    setLabel(true);
    setShow(true);
    setFetchRangeValue(range);
  };

  return (
    <>
      <Loader />
      <div style={{ backgroundColor: "white", height: "100%" }}>
        {fieldShow && (
          <ClosingStockField
            setShowField={setShowField}
            fieldShow={fieldShow}
            handleFieldClose={handleFieldClose}
            Title={"Show field"}
            // stockMovement = {stockMovement}
            stockMovement={columns}
          />
        )}

        <div className="row">
          <div className="col-md-5">
            <h2 style={{ color: "green" }}>
              Stock Movement Report
            </h2>
          </div>
          <div className="col-md-5"></div>
          <div className="col-md-2 ">
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                borderRadius: "10px",
                width: "220px",
                height: "60px",
                gap: "13px",
                background: "white",
                marginTop: "-20px",
                fontSize: "11px",
                marginLeft: "-20px",
              }}
              ref={paySlipRef}
            >
              <FontAwesomeIcon
                icon={faPrint}
                className="fa-2x search-icon"
                style={{ color: "black", paddingTop: "8px" }}
                onClick={handlePrintPDF}
              ></FontAwesomeIcon>

              <FontAwesomeIcon
                icon={faFileExcel}
                className="fa-2x search-icon "
                style={{
                  color: "green",
                  paddingLeft: "10px",
                  paddingTop: "7px",
                }}
                onClick={handleExportExcel}
              ></FontAwesomeIcon>

              <FontAwesomeIcon
                icon={faFilePdf}
                className="fa-2x search-icon"
                style={{ color: "red", paddingLeft: "10px", paddingTop: "7px" }}
                onClick={handleExportToPDF}
              ></FontAwesomeIcon>
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 searchBox">
            <div className="position-relative d-flex width-420" style={{ height: "45px" }}>

              <input
                className="form-control ps-8"
                type="search"
                name="searchData"
                id="search"
                placeholder="Search Product Name (or) Product Code"
                aria-label="Search"
                onChange={(e) => handleSearchData(e)}
              />
              <span className="position-absolute d-flex align-items-center top-0 bottom-0 left-0 text-gray-600 ms-3">
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div>
          </div>

          {/* <div className="col-md-1"></div> */}

          <div className="col-md-2" style={{ marginTop: "-35px" }}>
            <h4 className="mt-2">From Date</h4>

            <input
              id1="dateInput"
              type="date"
              className=" form-control rounded text-align-center ml-2 align-item-center mr-15 mb-5"
              ref={fromDate}
              defaultValue={defaultValue}
              onChange={onChange}
            ></input>
          </div>

          {/* <div className="col-md-1"></div> */}

          <div className="col-md-2" style={{ marginTop: "-26px" }}>
            <h4>To Date</h4>

            <input
              id2="dateRequired2"
              type="date"
              className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
              ref={toDate}
              defaultValue={defaultValue}
              onChange={onChange}
            ></input>
          </div>

          <div
            className="col-md-2 gstrGenerateBtn ml-3"
            style={{ marginTop: "-35px" }}
          >
            <button
              type="button"
              className="btn btn-success "
              onClick={() => loadValues()}
            >
              Generate
            </button>
          </div>
        </div>

        <br />

        <div className="row" >
          <div className=" col-md-8" style={{ marginTop: "-20px" }}>
            <span
              className="m-0 fw-bold"
              style={{ fontWeight: 500, fontSize: "15px" }}
            >
              <span style={{ fontWeight: "normal" }}>Applied Filters: </span>
              {selectedFilter.brand &&
                `Brand : ${selectedFilter.brand ?? "ALL"},`}
              {selectedFilter.category &&
                `Category : ${selectedFilter.category ?? "ALL"},`}
              {selectedFilter.group &&
                `Group : ${selectedFilter?.group ?? "ALL"}`}
            </span>
          </div>
          <div className="col-md-1"></div>
          <div
            className="col-md-3"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-50px",
            }}
          >
            <button
              style={{
                marginTop: "20px",
                border: "none",
                borderRadius: "10px 0 0 10px",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                color: "blue",
                marginLeft: "20px",
              }}
              onClick={showFilterModal}
            >
              <FontAwesomeIcon
                icon={faFilter}
                className="fa-2x search-icon"
                style={{ fontSize: "15px" }}
              />
            </button>
            <button
              style={{
                marginTop: "21px",
                border: " none",
                borderRadius: "0 10px 10px 0",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                marginLeft: "-13px",
                marginRight: "5px",
                color: "blue",
              }}
              onClick={showFilterModal}
            >
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Filter
              </span>
            </button>

            <button
              style={{
                marginTop: "20px",
                border: "none",
                borderRadius: "10px 0 0 10px",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                color: "blue",
              }}
              onClick={handleClick}
            >
              <FontAwesomeIcon
                icon={faEye}
                className="fa-2x search-icon"
                style={{ fontSize: "15px" }}
              />
            </button>
            <button
              style={{
                marginTop: "21px",
                border: "none",
                borderRadius: "0 10px 10px 0",
                backgroundColor: "white",
                height: "40px",
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                marginLeft: "-13px",
                marginRight: "35px",
                color: "blue",
              }}
              onClick={handleClick}
            >
              <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                Show Fields
              </span>
            </button>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 w-100 tabulator-container nilStock gstrTable">
            <ReactTabulator
              columns={columns}
              data={itemsValue}
              // ref={pdfRef}
              options={{
                columnHeaderVertAlign: "bottom",
                layout: "fitColumns",
                responsiveLayout: "collapse",
                placeholder: "No records found",
                height: "420px",
                //     footerElement: `<div style='width:100%;text-align: left; padding: 10px; border: 1px solid rgb(99, 166, 77); border-radius: 5px; display: flex;justify-content:space-between;  align-items: center;'>
                //         <div style='padding-left: 10px'>Total</div>

                //         <div style='margin-right: 10px;' >
                //           ${totalSalesValue(itemsValue)}
                //         </div>
                // </div>`,
              }}
            />
          </div>
        </div>

        <FilterModal
          showFilterModals={showFilterModals}
          showFilterModal={showFilterModal}
          selectedFilterSetting={selectedFilterSetting}
          selectedFilter={selectedFilter}
          title="Apply Filter"
        />

        {/* {show ? (
          <ClosingStockReportForm
            stockMovement={stockMovement}
            show={show}
            handleClose={handleClose}
            // setItemValues={setItemValues}
            singleStock={itemsValue}
            setRange={setRange}
            Title={"Apply Filter"}
            // search={search}
          />
        ) : null} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { stockMovement, companyConfig } = state;
  return { stockMovement, companyConfig };
};

export default connect(mapStateToProps, {
  fetchAllStockMovement,
  fetchCompanyConfig,
})(StockMovementReport);

import { Form, Modal } from "react-bootstrap-v5";
import ModelFooter from "../../shared/components/modelFooter";
import { useEffect, useState } from "react";
import { getFormattedMessage } from "../../shared/sharedMethod";
import ReactDataTable from "../../shared/table/ReactDataTable";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../shared/table/CommonTable";
import goBackIcon from "../../assets/images/go-back.svg";
import { number } from "prop-types";
const PriceLevelHistoryModal = (props) => {
  const {
    priceHistoryShow,
    title,
    onClickPriceHistory,
    priceHistoryList,
    pricehistoryrow,
  } = props;

  const { priceLvlListing, priceLevel, getItemPriceHistory } = useSelector(
    (state) => state
  );
  console.log("PriceLevelHistoryModal ::: priceHistoryList", priceHistoryList);
  console.log("PricehistoryRow", pricehistoryrow);
  // console.log("getItemPriceHistory : " ,getItemPriceHistory)

  const [columns, setColumns] = useState([]);
  // const itemValue = priceHistoryList?.sort((a, b) => {
  //   // Convert 'updatedOn' strings to Date objects
  //   const dateA = new Date(a.attributes.updatedOn);
  //   const dateB = new Date(b.attributes.updatedOn);

  //   // Sort in descending order
  //   return dateB - dateA;
  // }).map((items) => {
  //   return {
  //     mrp: items?.attributes?.mrp.toFixed(2),
  //     prev_price: items?.attributes?.salesPrice.toFixed(2),
  //     new_price: items?.attributes?.salesPrice.toFixed(2),
  //     changed_by: items?.attributes?.updatedBy,
  //     entry_from: items?.attributes?.entryFrom,
  //     changed_on: items?.attributes?.updatedOn ?? 0,
  //   };
  // });

  const sortedData = getItemPriceHistory?.attributes
    ?.filter((item) =>
      priceLevel?.some(
        (level) => item?.PriceLevelName === level?.attributes?.pricelevelname
      )
    )
    .sort((a, b) => new Date(b?.updatedOn) - new Date(a?.updatedOn));

  console.log("Sorted Data:", sortedData);

  // // Find price change history
  // const priceChangeHistory = [];
  // for (let i = 0; i < sortedData.length - 1; i++) {
  //   const current = sortedData[i];
  //   const previous = sortedData[i + 1];

  //   if (current.attributes.salesPrice !== previous.attributes.salesPrice) {
  //     priceChangeHistory.push({
  //       mrp: current.attributes.mrp.toFixed(2),
  //       prev_price: Number(previous.attributes.salesPrice * (1 + (previous.attributes.tax / 100))).toFixed(2).toString(),
  //       // prev_price: previous.attributes.salesPrice.toFixed(2) ,
  //       // new_price: current.attributes.salesPrice.toFixed(2),
  //       new_price: Number(current.attributes.salesPrice * (1 + (current.attributes.tax / 100))).toFixed(2).toString(),
  //       changed_by: current.attributes.updatedBy,
  //       entry_from: current.attributes.entryFrom,
  //       changed_on: current.attributes.updatedOn
  //     });
  //   }
  // }

  // const sortedData = priceListHistory.sort((a, b) => new Date(b.attributes.updatedOn) - new Date(a.attributes.updatedOn));

  const priceChangeHistory = [];

  if (sortedData?.length === 1) {
    priceChangeHistory?.push({
      // mrp: sortedData[0].attributes.mrp.toFixed(2),
      // prev_price: "-", // No previous price available
      new_price: Number(sortedData[0]?.salesPrice).toFixed(2).toString(),
      changed_by: sortedData[0]?.userName,
      entry_from: sortedData[0]?.entryFrom,
      changed_on: sortedData[0]?.changedOn,
    });
  } else {
    for (let i = 0; i < sortedData?.length; i++) {
      const current = sortedData[i];
      const previous = sortedData[i + 1];

      if (!previous) {
        priceChangeHistory.push({
          // mrp: current?.attributes?.mrp.toFixed(2),
          // prev_price: "-", // No previous price available
          new_price: Number(current?.salesPrice).toFixed(2).toString(),
          changed_by: current?.userName,
          entry_from: current?.entryFrom,
          changed_on: current?.changedOn,
        });
      } else if (current?.salesPrice !== previous?.salesPrice) {
        priceChangeHistory?.push({
          // mrp: current.attributes.mrp.toFixed(2),
          // prev_price: Number(
          //   previous?.attributes?.salesPrice * (1 + previous?.attributes?.tax / 100)
          // )
          //   .toFixed(2)
          //   .toString(),
          new_price: Number(current?.salesPrice).toFixed(2).toString(),
          changed_by: current?.userName,
          entry_from: current?.entryFrom,
          changed_on: current?.changedOn,
        });
      }
    }
  }

  const itemValue = getItemPriceHistory?.attributes?.map((itemMrp) => {
    // const date = new Date(itemMrp?.changedOn); // Convert string to Date object
    // const formattedDate = `${date?.getFullYear()}-${(date?.getMonth() + 1).toString().padStart(2, '0')}-${date?.getDate().toString().padStart(2, '0')} ${date?.getHours().toString().padStart(2, '0')}:${date?.getMinutes().toString().padStart(2, '0')}:${date?.getSeconds().toString().padStart(2, '0')}`;

    const rawDate = itemMrp?.changedOn; 

    const [datePart, timePart] = rawDate.split(' '); 
    const [hours, minutes] = timePart.split(':'); 
    const ampm = rawDate.includes('PM') ? 'PM' : 'AM'; 
  
    const formattedDate = `${datePart} ${hours}:${minutes} ${ampm}`;  

    return {
      //  newPrice: itemMrp?.salesPrice,
      //   salesPrice:itemMrp?.salesPrice,
       newPrice:Number(itemMrp?.salesPrice * (1 + (itemMrp?.tax / 100))).toFixed(2).toString(),
       salesPrice:Number(itemMrp?.salesPrice * (1 + (itemMrp?.tax / 100))).toFixed(2).toString(),
      changedOn: itemMrp?.changedOn,
      changedAt: itemMrp?.userName,
      entryFrom: itemMrp?.entryFrom,
      priceLevelName: itemMrp?.PriceLevelName,
      priceLevelNo : itemMrp?.priceLevelNo
    };
  });

  const sortedItemValue = itemValue?.sort((a, b) => {
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    return new Date(`${month}-${day}-${year}`);
  };

  const dateA = parseDate(a?.changedOn).getTime();
  const dateB = parseDate(b?.changedOn).getTime();

  return dateB - dateA;
});

  console.log("itemsvalue : ", itemValue);

  useEffect(() => {
    console.log("getItemPriceHistory", getItemPriceHistory);
  }, [getItemPriceHistory]);


  const [itemValues,setItemValue] = useState([])
 
 
  useEffect(() => {
    const getPreviousSalesPrice = (changedOn, priceLevelName) => {
      debugger
      const previousRow = itemValue?.find(
        (plItem) =>
          plItem?.changedOn < changedOn &&
          plItem?.priceLevelName === priceLevelName 
      );
      return previousRow?.salesPrice;
    };

    const uniqueData = itemValue?.filter((value, index, self) => {
      return (
        index ===
        self.findIndex(
          (t) =>
            t.changedBy === value.changedBy &&
            t.changedOn === value.changedOn &&
            t.entryFrom === value.entryFrom
        )
      );
    });
  
    let columns1 = [
      {
        name: <div className="header-left">Sales Price</div>,
        cell: (row) => {
          const matchedItem = itemValue?.find(
            (item) =>
              item?.changedOn === row?.changedOn &&
              item?.priceLevelName === "STANDARD" 
          );
  
          // Determine sales price with fallback to the previous row
          const salesPrice =
            matchedItem?.salesPrice !== undefined
              ? matchedItem?.salesPrice
              : getPreviousSalesPrice(row?.changedOn,"STANDARD");
  
          // Validate and format sales price
          const validSalesPrice =
            salesPrice === undefined || salesPrice === null || isNaN(salesPrice)
              ? "-"
              : parseFloat(salesPrice).toFixed(2);
  
          return validSalesPrice;
        },
        style: {
          fontSize: "12px",
          fontWeight: "300",
        },
      },
      {
        name: "Changed By",
        cell: (row) => row?.changedAt,
        style: {
          fontSize: "12px",
          fontWeight: "300",
        },
      },
      {
        name: "Changed On",
        cell: (row) => {
          const rawDate = row?.changedOn;
      
          if (!rawDate) {
            return "-";
          }
      
          const [datePart, timePart] = rawDate.split(' ');
          const [hours, minutes] = timePart.split(':');
          const ampm = rawDate.includes('PM') ? 'PM' : 'AM';
      
          const formattedDate = `${datePart} ${hours}:${minutes} ${ampm}`;
          
          return formattedDate;
        },
        style: {
          fontSize: "12px",
          fontWeight: "300",
        },
      },
      
      {
        name: "Entry From",
        cell: (row) => row?.entryFrom,
        style: {
          fontSize: "12px",
          fontWeight: "300",
        },
      },
    ];
  
    if (priceLevel?.length > 0) {
      const priceLevelListColumn = priceLevel?.map((level) => ({
        name: level?.attributes?.pricelevelname,
        cell: (row) => {
          const matchedItem = itemValue?.find(
            (plItem) =>
              plItem?.changedOn === row?.changedOn &&
              plItem?.priceLevelName === level?.attributes?.pricelevelname
          );
  
          const salesPrice =
            matchedItem?.newPrice !== undefined
              ? matchedItem?.newPrice
              : getPreviousRowPrice(
                  row?.changedOn,
                  level?.attributes?.pricelevelname
                );
  
          const validSalesPrice =
            salesPrice === undefined || salesPrice === null || isNaN(salesPrice)
              ? "-"
              : parseFloat(salesPrice).toFixed(2);
  
          return validSalesPrice;
        },
        style: {
          fontSize: "12px",
          fontWeight: "300",
        },
      }));
  
      const getPreviousRowPrice = (changedOn, priceLevelName) => {
        const previousRow = itemValue?.find(
          (plItem) =>
            plItem?.changedOn < changedOn &&
            plItem?.priceLevelName === priceLevelName
        );
        return previousRow?.newPrice;
      };
  
      const unitIndex = columns1?.findIndex(
        (col) => col.name?.props?.children === "Sales Price"
      );
  
      const updatedColumns = [
        ...columns1.slice(0, unitIndex + 1),
        ...priceLevelListColumn,
        ...columns1.slice(unitIndex + 1),
      ];
  
      setColumns(updatedColumns);
    } else {
      setColumns(columns1);
    }
    setItemValue(uniqueData)
    
  }, [getItemPriceHistory, priceLevel]);

 

  const [currentPage, setCurrentPage] = useState(1);
  const pageChange = (page) => {
    console.log("Current page:", page);
    setCurrentPage(page);
  };

  return (
    <Modal
      dialogClassName="modal-lg  modal-price-history"
      show={priceHistoryShow}
      onHide={() => onClickPriceHistory(false)}
    >
      <Modal.Header className="modal-header-price-history">
        <Modal.Title
          className="custom-modal-header"
          style={{ marginLeft: "42%" }}
        >
          {title}
        </Modal.Title>
        <button
          onClick={() => onClickPriceHistory(false)}
          className="btn back-btn-custom"
          style={{ cursor: "pointer" }}
        >
          <img className="goBackIcon" src={goBackIcon} />
          {getFormattedMessage("globally.back-btn")}
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="row align-items-center">
          <div className="col-md-8">
            <p className="custom-modal-header mb-0">
              {getItemPriceHistory ? getItemPriceHistory?.itemName : ""}
            </p>
          </div>
          <div className="col-md-4 ">
            <p className="custom-modal-header mb-0">
              {"MRP :"}
              {getItemPriceHistory ? getItemPriceHistory?.mrp?.toFixed(2) : ""}
            </p>
          </div>
        </div>
         {/* <div
          style={{
            maxHeight: "400px",
            overflowX: "hidden",
          }}
        >
          <CommonTable
            subHeader={false}
            items={sortedItemValue}
            columns={columns}
            getItemPriceHistory={true}
          />
        </div>  */}


 <div
  style={{
    maxHeight: "400px", // Set max height
    overflowY: "auto",  // Enable vertical scrolling
    overflowX: "hidden", // Hide horizontal scrolling
    border: "1px solid #ddd", // Optional: Add border for better UI
    marginTop:"5px" 
  }}
>
  <table
    style={{
      width: "100%",    // Ensure table occupies full width
      borderCollapse: "collapse",
    // Remove spacing between cells
    }}
  >
    <thead>
      <tr>
        {columns.map((column, index) => (
          <th
            key={index}
            style={{
              position: "sticky", // Freeze the header
              top: 0,             // Stick the header at the top
              backgroundColor: "#f1f1f1", // Add background to the header
              zIndex: 2,          // Ensure header is above other rows
              borderBottom: "1px solid #ddd",
              padding: "8px",     // Add padding
              textAlign: "left",  // Align text
            }}
          >
            {column.headerName || column.name} 
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {itemValues?.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {columns.map((column, colIndex) => (
            <td key={colIndex} style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
              {column?.cell ? column?.cell(row) : "-"}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
</div> 




      </Modal.Body>
    </Modal>
  );
};
export default PriceLevelHistoryModal;






// <div className="table-wrapper">
//   <table>
//     <thead>
//       <tr>
//         {columns.map((column, index) => (
//           <th key={index}>{column.headerName}</th>
//         ))}
//       </tr>
//     </thead>
//   </table>
//   <div className="table-body">
//     <table>
//       <tbody>
//         {itemValue.map((item, index) => (
//           <tr key={index}>
//             {columns.map((column, colIndex) => (
//               <td key={colIndex}>{item[column.field]}</td>
//             ))}
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   </div>
// </div>

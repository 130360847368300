import React, { useEffect, useState } from 'react'
import ReactSelect from '../../../shared/select/reactSelect';
import { counterList } from '../../../constants';
import { counterAction } from '../../../store/action/counterAction';
import { connect, useSelector } from 'react-redux';
import Select from "react-select";


const CounterField = (props) => {
    const { counterAction, counterData, setCounter, counterId } = props;
    const cou = useSelector((state) => state.counterData);
    const [counters, setCounters] = useState([]);
    const [selectedCounter, setSelectedCounter] = useState();


    useEffect(() => {
        // console.log("Counter", cou);
        let response = cou;
        let array = [];
        response?.map((da) => {
            // console.log(da);
            array.push({ label: da.attributes.counterName, value: da.counterId });
        });
        setCounters(array);
        if (counterId) {
            setSelectedCounter(array.find((da) => da.value === counterId));
        }else{
            setSelectedCounter(array[0]);
        }
    }, [cou]);

    useEffect(() => {
        // console.log("CounterData", counterData);
    }, [counterData]);

    useEffect(() => {
        counterAction();
    }, []);

    // useEffect(() => {
    //     
    //     console.log("counterId", counterId);
    //     setSelectedCounter(counters.find((da) => da.value === counterId));
    // }, [counterId]);

    // useEffect(() => {
    //     if (selectedCounter) {
    //         setCounter(selectedCounter[0]?.value);
    //     }
    // }, [selectedCounter]);

    const customStyles = {
        control: (base) => ({
            ...base,
            backgroundColor: '#ffffff',
            borderColor: '#ffffff',
            boxShadow: 'none',
            borderRadius: '5px',
            padding: '5px',
            minHeight: '30px',
            fontSize: '14px',
            ':hover': {
                borderColor: '#ddd',
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: '#333',
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: '#555',
            ':hover': {
                color: '#333',
            },
        }),
        indicatorSeparator: () => ({
            display: 'none',
        }),
        menu: (base) => ({
            ...base,
            borderRadius: '5px',
            marginTop: '2px',
            zIndex: 5,
        }),
        menuList: (base) => ({
            ...base,
            padding: '5px',
        }),
        option: (base, { isSelected }) => ({
            ...base,
            backgroundColor: isSelected ? '#17a2b8' : '#ffffff',
            color: isSelected ? '#fff' : '#333',
            ':hover': {
                backgroundColor: '#e8f5fe',
                color: '#17a2b8',
            },
        }),
    };

    return (
        <>
            <div className='col-4 mt-2 mb-2 center counter'>
                {/* <ReactSelect data={counters} name='counter'
                // isRequired
                defaultValue={{label: 'COUNTER-1', value: 1}}
            // placeholder={getFormattedMessage('select.payment-type.label')}
            /> */}
                <Select
                    name="counter"
                    options={counters}
                    isDisabled={counterId ? true : false}
                    // defaultValue={counters.find((da) => da.value === counterId)}
                    // value={counterId ? counters.find((da) => da.value === counterId) : counters[0]}
                    value={selectedCounter}
                    // defaultValue={counters[0]}
                    onChange={(e) => { setSelectedCounter(e); setCounter(e.value) }}
                    // styles={customStyles}
                />
                {/* <div className='col-4'>hello</div> */}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    const { counterData } = state;
    return {
        counterData
    }
}

export default connect(mapStateToProps, {
    counterAction
    // fetchHoldLists,
})(CounterField);

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import MasterLayout from '../MasterLayout';
import {addProduct} from '../../store/action/productAction';
import ProductForm from './ProductForm';
import HeaderTitle from '../header/HeaderTitle';
import {getFormattedMessage} from '../../shared/sharedMethod';
import {fetchAllProductGroups} from "../../store/action/productGroupsAction";

const CreateProduct = (props) => {
    const {addProduct,fetchAllProductGroups,base} = props;
    const navigate = useNavigate();

    const [formcode, setFormCode] = useState("M01");
   
    useEffect(() => {
      ;
      const storedFormData = localStorage.getItem("UserFormCode");
  
      if (storedFormData) {
        const parsedFormData = JSON.parse(storedFormData);
          if (parsedFormData.length > 0) {
          const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permissionAdd);
          if(!formCodeItems.length > 0){
              navigate("/app/dashboard");
          }
        } else {
          navigate("/app/dashboard");
        }
      } 
    }, []);

    useEffect(() => {
        fetchAllProductGroups();
    }, []);

    const addProductData = (formValue) => {
        addProduct(formValue, navigate);
    };

    return (
        <MasterLayout>
            {/* <HeaderTitle title={getFormattedMessage('product.create.title')} to='/app/products'/> */}
            <ProductForm addProductData={addProductData} 
            title={getFormattedMessage( "product.create.title")}productGroups={base} to='/app/products'/>
        </MasterLayout>
    )
};

const mapStateToProps = (state) => {
    const {base} = state;
    return {base}
};

export default connect(mapStateToProps, {addProduct,fetchAllProductGroups})(CreateProduct);

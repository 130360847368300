import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import MasterLayout from "../MasterLayout";
import ReactDataTable from "../../shared/table/ReactDataTable";
import { fetchCustomers } from "../../store/action/PosCustomerAction";
import TabTitle from "../../shared/tab-title/TabTitle";
import HeaderTitle from "../header/HeaderTitle";
import {
  getFormattedMessage,
  placeholderText,
} from "../../shared/sharedMethod";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import SearchComponent from "../../shared/components/SearchComponent";
import ActionButton from "../../shared/action-buttons/ActionButton";
import { useNavigate } from "react-router";
import DeletePosCustomer from "./DeletePosCustomer";

const PosCustomer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [deleteModel, setDeleteModel] = useState(false);
  const [isDelete, setIsDelete] = useState(null);
  const [editModel, setEditModel] = useState(false);
  const [formcode, setFormCode] = useState("M02");
  const { posCustomer, isLoading } = props;
  const [importCustomer, setimportCustomer] = useState(false);
  const [filterPosCustomer, setFilterPosCustomer] = useState([]);

  useEffect(() => {
    dispatch(fetchCustomers());
  }, []);

  const handleClose = () => {
    setEditModel(!editModel);
    setimportCustomer(!importCustomer);
  };

  const goToEditCustomer = (item) => {
    const id = item.id;
    navigate(`/app/posCustomer/edit/${id}`);
  };

  const onClickDeleteModel = (isDelete = null) => {
    setDeleteModel(!deleteModel);
    setIsDelete(isDelete);
  };

  useEffect(() => {
    if (posCustomer && posCustomer.customerList && posCustomer.customerList.length > 0) {
      setFilterPosCustomer(posCustomer.customerList);
    }
  }, [posCustomer]);

  useEffect(() => {

    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility);
        if (!formCodeItems.length > 0) {
          navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    }
  }, []);

  const columns = [
    {
      name: getFormattedMessage("customerCode.title"),
      selector: (row) => row.customerCode,
      sortField: "customerCode",
      sortable: true,
    },
    {
      name: getFormattedMessage("globally.input.customerName.label"),
      selector: (row) => row.customerName,
      sortField: "customerName",
      sortable: true,
    },
    {
      name: getFormattedMessage("globally.input.MobileNo.label"),
      selector: (row) => row.mobileNo,
      sortField: "mobileNo",
      sortable: true,
    },

    {
      name: getFormattedMessage("globally.input.city.label"),
      selector: (row) => row.city,
      sortField: "city",
      sortable: true,
    },
    {
      name: getFormattedMessage("globally.input.isactive.label"),
      selector: (row) => row.isActive,
      sortField: "isActive",
      sortable: true,
    },
    {
      name: getFormattedMessage("react-data-table.action.column.label"),
      right: true,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <ActionButton
          item={row}
          goToEditProduct={goToEditCustomer}
          isEditMode={true}
          // isViewIcon={true}
          onClickDeleteModel={onClickDeleteModel}
          isViewIcon={true}
          goToDetailScreen={goToDetailPage}
        />
      ),
    },
  ];

  const handleSearchData = (e) => {
    const { name, value } = e.target;
    const filtered_posCustomers =
      value.length > 0
        ? posCustomer.filter((item) =>
          item?.attributes?.ledgerName
            ?.toLowerCase()
            ?.includes(value?.toLowerCase())
        )
        : posCustomer;
    setFilterPosCustomer(filtered_posCustomers);
  };

  const goToDetailPage = (item) => {
    debugger
    const id = item
    window.location.href = "#/app/posCustomer/Detail/" + id;
  };

  const itemsValue =
    filterPosCustomer && filterPosCustomer.length > 0 ?
      filterPosCustomer
        .map((posCustomer) => {
          if (posCustomer?.attributes?.underGroup === "CUSTOMERS") {
            return {
              customerCode: posCustomer?.attributes?.ledgerCode,
              customerName: posCustomer?.attributes?.salutation ? posCustomer?.attributes?.salutation + ". " + posCustomer?.attributes?.ledgerName : posCustomer?.attributes?.ledgerName,
              mobileNo: posCustomer?.attributes?.mobileNo1,
              city: posCustomer?.attributes?.city,
              isActive: posCustomer?.attributes?.isActive == true ? "Yes" : "No",
              id: posCustomer?.id,
            };
          }
          return null;
        })
        .filter((item) => item !== null) : [];

  return (
    <MasterLayout>
      <TopProgressBar />
      <TabTitle title={placeholderText("customer.create.title")} />
      <HeaderTitle title={getFormattedMessage("customerList.create.title")} />
      <SearchComponent
        handleSearchData={handleSearchData}
        autoComplete="off"
        ButtonValue={getFormattedMessage("customer.create.title")}
        to="#/app/posCustomer/create"
        totalRows={itemsValue?.length}
        goToImport={handleClose}
        placeholder={"Search Customers"}
      />

      <ReactDataTable
        columns={columns}
        items={itemsValue}
        isLoading={isLoading}
        subHeader={false}
      />
      <DeletePosCustomer
        onClickDeleteModel={onClickDeleteModel}
        deleteModel={deleteModel}
        onDelete={isDelete}
      />
    </MasterLayout>
  );
};
const mapStateToProps = (state) => {
  const { posCustomer, isLoading } = state;
  return { posCustomer, isLoading };
};
export default connect(mapStateToProps, null)(PosCustomer);

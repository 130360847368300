import apiConfig from "../../config/apiConfig";
import { apiBaseURL, toastType, zoneActionType } from "../../constants";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { setLoading } from "./loadingAction";
import { setSavingButton } from "./saveButtonAction";
import { addToast } from "./toastAction";
import { addInToTotalRecord, removeFromTotalRecord } from "./totalRecordAction";
import { setLoader } from "./loaderAction";

export const fetchZones =
  (filter = {}, isLoading = true) =>
    async (dispatch) => {
      if (isLoading) {
        dispatch(setLoading(true));
        dispatch(setLoader(true));
      }

      let url = apiBaseURL.ZONE;
      // if (
      //     !_.isEmpty(filter) &&
      //     (filter.page ||
      //         filter.pageSize ||
      //         filter.search ||
      //         filter.order_By ||
      //         filter.created_at)
      // ) {
      //     url += requestParam(filter, null, null, null, url);
      // }
      apiConfig
        .get(url)
        .then((response) => {
          dispatch({
            type: zoneActionType.FETCH_ZONE,
            payload: response?.data?.data,
          });


          if (isLoading) {
            dispatch(setLoading(false));
            dispatch(setLoader(false));
          }

        })
        .catch(({ response }) => {
          dispatch(
            addToast({
              text: response?.data?.message,
              type: toastType.ERROR,
            })
          );
          dispatch(setLoading(false));
          dispatch(setLoader(false));
        });
    };

export const addZone = (zone, handleClose) => async (dispatch) => {
  dispatch(setSavingButton(true));
  await apiConfig
    .post(apiBaseURL.ZONE, zone)
    .then((response) => {
      if (response?.data?.success === true) {
        dispatch(
          addToast({
            text: getFormattedMessage
              (
                "Data.success.create.message"
              ),
          })
        );
        handleClose(false)
        dispatch(fetchZones());
        // navigate("/app/emp");
      }
      else {
        dispatch(
          addToast({
            text: response?.data?.message,
            type: toastType?.ERROR,
          })
        );


      }

      dispatch({
        type: zoneActionType.ADD_ZONE,
        payload: response?.data?.data,
      });


      dispatch(fetchZones());
      dispatch(addInToTotalRecord(1));
      dispatch(setSavingButton(false));
    })
    .catch(({ response }) => {
      dispatch(setSavingButton(false));
      response &&
        dispatch(
          addToast({
            text: response?.data?.message,
            type: toastType.ERROR,
          })
        );
    });
};

export const deleteZone = (zoneId) => async (dispatch) => {
  apiConfig
    .delete(apiBaseURL.ZONE + "?zoneId=" + zoneId)
    .then((response) => {
      if (response?.data?.success === true) {
        dispatch(
          addToast({
            text: response?.data?.message, type: toastType?.success
          })
        );
      } else {
        dispatch(
          addToast({ text: response?.data?.message, type: toastType?.ERROR })
        );
      }
      dispatch(removeFromTotalRecord(1));
      dispatch(fetchZones());
      dispatch({
        type: zoneActionType.DELETE_ZONE,
        payload: zoneId
      });
    })
    .catch(({ response }) => {
      dispatch(
        addToast({ text: response?.data?.message, type: toastType?.ERROR })
      );
    });
};

import { useNavigate, useParams } from "react-router"
import TopProgressBar from "../../shared/components/loaders/TopProgressBar"
import { getFormattedMessage } from "../../shared/sharedMethod"
import HeaderTitle from "../header/HeaderTitle"
import MasterLayout from "../MasterLayout"
import PosSupplierForm from "./PosSupplierForm"
import { useEffect, useState } from "react"
import { editSuppliers, fetchSuppliers } from "../../store/action/PosSupplierAction"
import { connect } from "react-redux"

const PosEditSupplier = (props) => {

    const {posSupplier,fetchSuppliers,editSuppliers} = props;


    const {id} =useParams(); 

    const [formcode, setFormCode] = useState("M03");
    const navigate = useNavigate() ;
  useEffect(() => {
    ;
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permisssionUpdate);
        if(!formCodeItems.length > 0){
            navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    } 
  }, []);

    useEffect(() => {
        fetchSuppliers(id);
         // editSuppliers();
    },[])

    const itemsValue = posSupplier && posSupplier.length ===1 && posSupplier.map(posSuppliers => ({
        supplierName:posSuppliers?.attributes?.ledgerName,
        salutation:posSuppliers?.attributes?.salutation,
        supplierCode:posSuppliers?.attributes?.ledgerCode,
        address:posSuppliers?.attributes?.address,
        area:posSuppliers?.attributes?.area,
        city:posSuppliers?.attributes?.city,
        state:posSuppliers?.attributes?.state,
        location:posSuppliers?.attributes?.location,
        gstNo:posSuppliers?.attributes?.regNo,
        isActive:posSuppliers?.attributes?.isActive,
        email:posSuppliers?.attributes?.email,
        mobileNo:posSuppliers?.attributes?.mobileNo1,
        id: posSuppliers?.id 
    }));


    return(
        <MasterLayout>
            <TopProgressBar/>
            {/* <HeaderTitle title={getFormattedMessage('supplier.Edit.title')} to='/app/supplier' /> */}
            {posSupplier.length === 1 && <PosSupplierForm singleSupplier={itemsValue} id={id} 
            title={getFormattedMessage('supplier.Edit.title')} to='/app/supplier'
            />}
        </MasterLayout>
    )
}

const mapStateToProps =( state ) => {
    const {posSupplier} =state;
    return {posSupplier}
}

export default connect(mapStateToProps,{fetchSuppliers,editSuppliers}) (PosEditSupplier)
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Modal } from 'react-bootstrap-v5';
import { editBrand, fetchBrand, fetchBrands } from '../store/action/brandsAction';
import { getFormattedMessage } from './sharedMethod';
import { GoogleMap, LoadScript, Marker, Autocomplete, DistanceMatrixService } from '@react-google-maps/api';
import { environment } from '../config/environment';

const mapContainerStyle = {
    width: '100%',
    height: '400px',
};

const LocationPopup = (props) => {
    const { handleClose, show, title, fetchFormData, mapData } = props;

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        keywords: '',
        latitude: mapData.latitude,
        longitude: mapData.longitude,
        geoUrl: '',
        radius: 0,
        markerPosition: mapData.markerPosition,
        address: '',
        street: '',
        area: '',
        city: '',
        state: '',
        companycord: { lat: '', lng: '' },
        distance: '',
        originname: '',
        destinationname: ''
    });

    useEffect(() => {
        
        setFormData({
            keywords: '',
            latitude: mapData.latitude,
            longitude: mapData.longitude,
            geoUrl: mapData.geoUrl,
            radius: mapData.radius,
            markerPosition: mapData.markerPosition,
            address: mapData.address,
            street: mapData.street ?? '',
            area: mapData.area ?? '',
            city: mapData.city ?? '',
            state: mapData.state ?? '',
            companycord: mapData.companycord ?? '',
            distance: mapData.distance ?? '',
            originname: mapData.originname ?? '',
            destinationname: mapData.destinationname ?? ''
        })

    }, [mapData]);

    const autocompleteRef = useRef(null);

    const inputRefs = {
        keywords: useRef(null),
        latitude: useRef(null),
        longitude: useRef(null),
        geoUrl: useRef(null),
        radius: useRef(null),
    }

    const submitRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        let result = value;
        if (name === 'latitude' || name === 'longitude')
            result = parseFloat(value);

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    const handleValidation = () => {
        const { latitude, longitude, geoUrl } = formData;
        let isValid = true;
        let result = '';
        let errors = {};
        if (latitude) {
            result = parseFloat(latitude);
            isValid = !isNaN(result) && result >= -90 && result <= 90;
            errors.latitude = isValid ? '' : 'Please enter a valid latitude between -90 and 90.';
        }
        if (longitude) {
            result = parseFloat(longitude);
            isValid = !isNaN(result) && result >= -180 && result <= 180;
            errors.longitude = isValid ? '' : 'Please enter a valid longitude between -180 and 180.';
        }
        if (geoUrl) {
            const regex = /^geo:-?\d+\.\d+,-?\d+\.\d+(\?([qz]=[^\s&]+))?$/;
            isValid = geoUrl && regex.test(geoUrl);
            errors.geoUrl = isValid ? '' : 'Please enter a valid Geo URL in the format';
        }
        setErrors(errors);
        return isValid;
    }

    const handlePlaceSelected = () => {
        const autocomplete = autocompleteRef.current;
        const place = autocomplete.getPlace();
        if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            setFormData((prevData) => ({
                ...prevData,
                address: place.formatted_address,
                keywords: place.formatted_address,
                latitude: lat,
                longitude: lng,
                markerPosition: { lat, lng }
            }));
        }
    };

    const fetchAddress = async () => {
        try {
            if ((hasCoordinatesChanged || !formData.address)) {
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?latlng=${formData.latitude},${formData.longitude}&key=${environment.GoogleAPIKey}`
                );

                const data = await response.json();
                if (data.status === 'OK' && data.results.length > 0) {
                    let filtered = data.results.filter(x => x.geometry.location.lat == formData.latitude && x.geometry.location.lng == formData.longitude);
                    if (filtered.length > 0) {
                        var exactLocation = filtered[filtered.length - 1];

                        var components = exactLocation["address_components"];

                        var street = getComponent(components, "route") ? getComponent(components, "route") : getComponent(components, "plus_code");
                        var area = getComponent(components, "locality");
                        var city = getComponent(components, "administrative_area_level_2") ? getComponent(components, "administrative_area_level_2") : getComponent(components, "administrative_area_level_3");
                        var state = getComponent(components, "administrative_area_level_1");
                        
                        setFormData((prevData) => ({
                            ...prevData,
                            address: exactLocation["formatted_address"],
                            street: street,
                            area: area,
                            city: city,
                            state: state
                        }));
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching the address:', error);
            setAddress('Error fetching the address');
        }
    };

    const getComponent = (components, type) => {
        for (const component of components) {
            if (component.types && component.types.includes(type)) {
                return component.long_name || null;
            }
        }
        return null;
    }

    useEffect(() => {
        if (show) {
            if (formData.latitude && formData.longitude) {
                fetchAddress();
                // console.log('Form data updated:', formData);
            }
        }
    }, [show, formData.latitude, formData.longitude]);

    useEffect(() => {
        if (show) {
            if (formData.geoUrl) {
                parseGeoUrl();
                // console.log('Form data updated:', formData);
            }
        }
    }, [show, formData.geoUrl]);

    const prevDestination = useRef({ lat: formData.latitude, lng: formData.longitude });

    const handleResponse = (response) => {
        if (response?.rows[0]?.elements[0]?.status === 'OK') {
            const calculatedDistance = response.rows[0].elements[0].distance.text;

            setFormData((prevData) => ({
                ...prevData,
                distance: calculatedDistance
            }));
        } else {
            console.error('Error fetching distance:', response);
        }
    };

    const hasCoordinatesChanged = prevDestination.current.lat !== formData.latitude || prevDestination.current.lng !== formData.longitude;

    if (hasCoordinatesChanged) {
        prevDestination.current = { lat: formData.latitude, lng: formData.longitude };
    }

    const hasCompanyCord = formData?.companycord?.lat && formData?.companycord?.lng;

    const parseGeoUrl = async () => {
        try {
            const match = formData.geoUrl.match(/geo:([-+]?[0-9]*\.?[0-9]+),([-+]?[0-9]*\.?[0-9]+)(?:\?q=(.*))?/);
            if (match) {
                const lat = parseFloat(match[1]);
                const lng = parseFloat(match[2]);
                setFormData((prevData) => ({
                    ...prevData,
                    latitude: lat,
                    longitude: lng,
                    markerPosition: { lat, lng }
                }));
            } else {
                alert('Invalid Geo URL');
            }
        } catch (error) {
            console.error('Error parsing Geo URL:', error);
        }

        // try {
        //     // Request to resolve the shortened URL
        //     const response = await fetch(`https://unshorten.me/json/${encodeURIComponent(formData.geoUrl)}`);

        //     // Expanded URL should be in response.url
        //     const data = await response.json();
        //     const expandedUrl = data.resolved_url;

        //     // Extract the latitude and longitude from the expanded URL
        //     const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
        //     const match = expandedUrl.match(regex);

        //     if (match) {
        //         const lat = parseFloat(match[1]);
        //         const lng = parseFloat(match[2]);
        //         setFormData((prevData) => ({
        //             ...prevData,
        //             latitude: lat,
        //             longitude: lng,
        //             markerPosition: { lat, lng }
        //         }));
        //     } else {
        //         console.log("Coordinates not found in the URL");
        //     }
        // } catch (error) {
        //     console.error("Error resolving URL:", error);
        // }
    };

    const onSubmit = (event) => {
        const valid = handleValidation();
        if (valid) {
            fetchFormData(formData);
            clearField();
        }
    };

    const clearField = (event) => {
        setFormData((prevData) => ({
            ...prevData,
            keywords: ''
        }));
        setErrors({});
        handleClose(false);
    };

    const handleKeyDown = (e) => {
        const { key, target } = e;
        const { name } = target;
        if (name == "done" && key === 'Enter') {
            e.preventDefault();
        }
        if (key === 'Enter' || key === 'ArrowDown' || key === 'Tab' || key === 'ArrowRight') {
            if (name == "keywords") {
                inputRefs.latitude.focus();
            } else if (name == "latitude") {
                inputRefs.longitude.focus();
            } else if (name == "longitude") {
                inputRefs.geoUrl.focus();
            } else if (name == "geoUrl") {
                inputRefs.radius.focus();
            } else if (name == "radius") {
                submitRef.current?.click();
            }
        } else if (key === 'ArrowUp' || key === 'ArrowLeft') {
            if (name == "latitude") {
                inputRefs.keywords.focus();
            } else if (name == "longitude") {
                inputRefs.latitude.focus();
            } else if (name == "geoUrl") {
                inputRefs.longitude.focus();
            } else if (name == "radius") {
                inputRefs.geoUrl.focus();
            }
        }
    };

    return (
        <Modal show={show}
            // onHide={clearField}
            keyboard={true}
            size={"lg"}
        >
            <Form>
                <Modal.Header>
                    <Modal.Title className="model-header-title-700">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <LoadScript googleMapsApiKey={environment.GoogleAPIKey} libraries={['places']}>
                            <div className="col-md-12 mb-5">
                                <Form.Group controlId='keywords'>
                                    <Form.Label>Keywords</Form.Label>
                                    <Autocomplete
                                        onPlaceChanged={handlePlaceSelected}
                                        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                                        style={{
                                            width: '400px',
                                            height: '40px',
                                            paddingLeft: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <Form.Control type={"text"} value={formData.keywords} name="keywords" onChange={handleChange}
                                            onKeyDown={(e) => handleKeyDown(e)}
                                            ref={(el) => (inputRefs.keywords = el)}
                                        />
                                    </Autocomplete>
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-5">
                                <Form.Group controlId='latitude'>
                                    <Form.Label>Latitude</Form.Label>
                                    <Form.Control type={"number"} name="latitude" value={formData.latitude}
                                        onChange={handleChange} isInvalid={errors.latitude}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        ref={(el) => (inputRefs.latitude = el)}
                                    />
                                    {errors.latitude && <Form.Text style={{ color: 'red', marginTop: '5px' }}>{errors.latitude}</Form.Text>}
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-5">
                                <Form.Group controlId='longitude'>
                                    <Form.Label>Longitude</Form.Label>
                                    <Form.Control type={"number"} name="longitude" value={formData.longitude}
                                        onChange={handleChange} isInvalid={errors.longitude}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        ref={(el) => (inputRefs.longitude = el)}
                                    />
                                    {errors.longitude && <Form.Text style={{ color: 'red', marginTop: '5px' }}>{errors.longitude}</Form.Text>}
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-5">
                                <Form.Group controlId='geoUrl'>
                                    <Form.Label>Geo URL</Form.Label>
                                    <Form.Control type={"text"} name="geoUrl" value={formData.geoUrl}
                                        onChange={handleChange} isInvalid={errors.geoUrl}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        ref={(el) => (inputRefs.geoUrl = el)}
                                    />
                                    {errors.geoUrl && <Form.Text style={{ color: 'red', marginTop: '5px' }}>{errors.geoUrl}</Form.Text>}
                                </Form.Group>
                            </div>
                            <div className="col-md-6 mb-5">
                                <Form.Group controlId='radius'>
                                    <Form.Label>Radius (Mtrs.)</Form.Label>
                                    <Form.Control type={"number"} name="radius" value={formData.radius}
                                        onChange={handleChange}
                                        onKeyDown={(e) => handleKeyDown(e)}
                                        ref={(el) => (inputRefs.radius = el)}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-md-12 text-end mb-5">
                                <button type="button" onClick={onSubmit} name="done"
                                    onKeyDown={(e) => handleKeyDown(e)}
                                    ref={submitRef}
                                    className="btn btn-success me-6">
                                    {getFormattedMessage("globally.done-btn")}
                                </button>
                                <button type="button" onClick={clearField}
                                    className="btn btn-danger">
                                    {getFormattedMessage("globally.close-btn")}
                                </button>
                            </div>
                            <div className="col-md-12 mb-5">
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    center={formData.markerPosition}
                                    zoom={12}
                                    options={{
                                        disableDefaultUI: true,
                                        draggable: false,
                                        clickableIcons: false,
                                    }}
                                >
                                    {(!formData.distance || hasCoordinatesChanged) && (hasCompanyCord) && (
                                        <DistanceMatrixService
                                            options={{
                                                origins: [formData.companycord],
                                                destinations: [{ lat: formData.latitude, lng: formData.longitude }],
                                                travelMode: 'DRIVING' // Modes: DRIVING, WALKING, BICYCLING, TRANSIT
                                            }}
                                            callback={handleResponse}
                                        />
                                    )}

                                    {formData.latitude && formData.longitude && (
                                        <Marker position={formData.markerPosition} />
                                    )}
                                </GoogleMap>

                            </div>
                        </LoadScript>
                    </div>
                </Modal.Body>
            </Form>
        </Modal>
    )
};

export default connect(null, { fetchBrand, editBrand, fetchBrands })(LocationPopup);

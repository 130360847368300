import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MasterLayout from '../MasterLayout';
import ReactDataTable from '../../shared/table/ReactDataTable';
import TabTitle from '../../shared/tab-title/TabTitle';
import { getFormattedMessage, placeholderText } from '../../shared/sharedMethod';
import ActionButton from '../../shared/action-buttons/ActionButton';
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import HeaderTitle from '../header/HeaderTitle';
import SearchComponent from '../../shared/components/SearchComponent';
import { companyList, updateCompanyActiveStatus } from '../../store/action/companyConfigAction';
import DeactivateModel from '../../shared/action-buttons/DeactivateModel';
import ActiveModel from '../../shared/action-buttons/ActivateModel';

const Companies = (props) => {
    const { companyConfig, isLoading, updateCompanyActiveStatus } = props;

    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const dispatch = useDispatch();

    const [activeModel, setActiveModel] = useState(false);
    const [isActive, setIsActive] = useState(null);

    const [deActiveModel, setDeActiveModel] = useState(false);
    const [isDeActive, setIsDeActive] = useState(null);

    const [selectedItem, setSelectedItem] = useState({});

    const onClickIsActiveModel = (item = null) => {
        setActiveModel(!activeModel);
        setIsActive(isActive);
        if (item != null) {
            setSelectedItem(item);
        }
    };

    const onClickDeActiveModel = (item = null) => {
        setDeActiveModel(!deActiveModel);
        setIsDeActive(isDeActive);
        if (item != null) {
            setSelectedItem(item);
        }
    };

    const updateStatus = (status) => {
        let payload = {
            id: selectedItem.companyId ? parseInt(selectedItem.companyId) : 0,
            status: status
        }
        if (status == true) {
            setActiveModel(!activeModel);
        } else {
            setDeActiveModel(!deActiveModel);
        }

        updateCompanyActiveStatus(payload);
    };

    useEffect(() => {
        dispatch(companyList());
    }, []);

    useEffect(() => {
        if (companyConfig.companyList && companyConfig.companyList.length > 0) {
            setCompanies(companyConfig.companyList);
        }
    }, [companyConfig.companyList]);

    const goToEditProduct = (item) => {
        const id = item.companyId
        navigate(`/app/company/edit/${id}`)
    };

    useEffect(() => {
        const storedFormData = localStorage.getItem("loginUserArray");
        if (JSON.parse(storedFormData).role?.toUpperCase() != "ADMINISTRATOR") {
          navigate("/app/dashboard");
        }
      }, []);

    const itemsValue = companies && companies.length >= 0 && companies.map(item => ({
        companyName: item.companyName,
        companyCode: item.companyCode,
        businessType: item.businessType,
        isActive: item.isActive,
        companyId: item.companyId
    })).filter((item) => item !== null);

    const columns = [
        {
            name: getFormattedMessage('companyconfig.column.companyname'),
            selector: row => row.companyName,
            sortField: 'companyName',
            sortable: true,
            cell: row => {
                return <div>
                    <div>{row.companyName}</div>
                </div>
            }
        },
        {
            name: getFormattedMessage('companyconfig.column.companycode'),
            selector: row => row.companyCode,
            sortField: 'companyCode',
            sortable: true,
            center: true,
            conditionalCellStyles: [
                {
                    when: (row) => row.companyCode,
                    classNames: ["justify-content-center"]
                }
            ]
        },
        {
            name: getFormattedMessage('companyconfig.column.businesstype'),
            selector: row => row.businessType,
            sortField: 'businessType',
            sortable: true,
            center: true,
            conditionalCellStyles: [
                {
                    when: (row) => row.businessType,
                    classNames: ["justify-content-center"]
                }
            ]
        },
        {
            name: getFormattedMessage('companyconfig.column.isactive'),
            selector: row => row.isActive,
            sortField: 'isActive',
            sortable: true,
            wrap: true,
            center: true,
            cell: row => {
                return <div>
                    <div>{row.isActive ? "Yes" : "No"}</div>
                </div>
            },
            conditionalCellStyles: [
                {
                    when: (row) => row.isActive,
                    classNames: ["justify-content-center"]
                }
            ]
        },
        {
            name: getFormattedMessage('react-data-table.action.column.label'),
            center: true,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: row => {
                let isActivat = row.isActive ? false : true;
                let isDeActivat = row.isActive ? true : false;
                return <ActionButton item={row} goToEditProduct={goToEditProduct} isDeleteMode={false} isEditMode={true}
                    isActiveMode={isActivat} onClickIsActiveModel={onClickIsActiveModel} isDeActiveMode={isDeActivat} onClickDeActiveModel={onClickDeActiveModel} />
            }
        }
    ];

    const handleSearchData = (e) => {
        const { value } = e.target;
        const filtered_companies =
            value.length > 0
                ? companyConfig.companyList.filter((item) =>
                    item?.companyName
                        ?.toLowerCase()
                        ?.includes(value?.toLowerCase())
                )
                : companyConfig.companyList;
        setCompanies(filtered_companies);
    };

    return (
        <MasterLayout>
            <TopProgressBar />
            <TabTitle title={placeholderText('companyconfig.list.title')} />
            <HeaderTitle title={getFormattedMessage("companyconfig.list.title")} />
            <SearchComponent
                handleSearchData={handleSearchData}
                autoComplete="off"
                // ButtonValue={getFormattedMessage("companyconfig.create.title")}
                to="#/app/company/create"
                totalRows={itemsValue?.length}
            />
            <ReactDataTable
                columns={columns}
                items={itemsValue}
                isLoading={isLoading}
                subHeader={false}
            />
            {deActiveModel && <DeactivateModel onClickDeleteModel={onClickDeActiveModel} deleteModel={deActiveModel}
                deleteUserClick={() => updateStatus(false)} title={"Company"}
                name={"Company"} />
            }
            {activeModel && <ActiveModel onClickDeleteModel={onClickIsActiveModel} deleteModel={activeModel}
                deleteUserClick={() => updateStatus(true)} title={"Company"}
                name={"Company"} />
            }

        </MasterLayout>
    )
};

const mapStateToProps = (state) => {
    const { isLoading, companyConfig } = state;
    return { isLoading, companyConfig }
};

export default connect(mapStateToProps, { updateCompanyActiveStatus })(Companies);


import { useEffect } from "react";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import MasterLayout from "../MasterLayout";
import { fetchLedger } from "../../store/action/ledgerAction";
import { connect } from "react-redux";
import {
  getFormattedMessage,
  placeholderText,
} from "../../shared/sharedMethod";
import ReactDataTable from "../../shared/table/ReactDataTable";
import ActionButton from "../../shared/action-buttons/ActionButton";
import { useNavigate } from "react-router";
import { useState } from "react";
import DeleteLedger from "./DeleteLedger";
import TabTitle from "../../shared/tab-title/TabTitle";
import SearchComponent from "../../shared/components/SearchComponent";
import HeaderTitle from "../header/HeaderTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import TableButton from "../../shared/action-buttons/TableButton";

const Ledger = (props) => {
  const { fetchLedger, ledger, totalRecord, isLoading, allConfigData } = props;

  const [editModel, setEditModel] = useState(false);
  const [importLedger, setImportLedger] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [isDelete, setIsDelete] = useState(null);
  const [formcode, setFormCode] = useState("M04");
  const [filterLedger,setFilterLedger] = useState([])

  const handleClose = () => {
    setEditModel(!editModel);
    setImportLedger(!importLedger);
  };

  const onClickDeleteModel = (isDelete = null) => {
    setDeleteModel(!deleteModel);
    setIsDelete(isDelete);
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchLedger();
  }, []);

  useEffect(() => {
    
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter(
          (item) =>
            item?.attributes?.formCode == formcode &&
            item?.attributes?.visibility
        );
        if (!formCodeItems.length > 0) {
          navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    }
  }, []);

  const goToEditLedger = (item) => {
    const id = item.id;
    navigate(`/app/ledgers/edit/${id}`);
  };

  useEffect(() => {
    setFilterLedger(ledger);
  }, [ledger]);

  const handleSearchData = (e) => {
    const { name, value } = e.target;

    const searchValue = value.toLowerCase().trim();
  
    const filtered_Ledger =
      searchValue.length > 0
        ? ledger?.filter((item) => {
            const ledgerName = item?.attributes?.ledgerName?.toLowerCase() || "";
            const mobileNo1 = item?.attributes?.mobileNo1?.toString().toLowerCase() || "";
  
            return ledgerName.includes(searchValue) || mobileNo1.includes(searchValue);
          })
        : ledger;
  
    setFilterLedger(filtered_Ledger);
  };

  const itemsValue =
    filterLedger&&
    filterLedger?.map((ledgers) => ({
      ledgerName:ledgers?.attributes?.salutation ? ledgers?.attributes?.salutation + ". "+ ledgers?.attributes?.ledgerName : ledgers?.attributes?.ledgerName  ,
      underGroup: ledgers?.attributes?.underGroup,
      mobileNo: ledgers?.attributes?.mobileNo1,
      email: ledgers?.attributes?.email,
      city: ledgers?.attributes?.city,
      id: ledgers?.id,
    }));


  const columns = [
    {
      name: getFormattedMessage("ledger.title"),
      selector: (row) => row.ledgerName,
      sortField: "ledgerName",
      sortable: true,
    },
    {
      name: getFormattedMessage("globally.input.underGroup.label"),
      selector: (row) => row.underGroup,
      sortField: "underGroup",
      sortable: true,
    },
    {
      name: getFormattedMessage("globally.input.MobileNo.label"),
      selector: (row) => row.mobileNo,
      sortField: "mobileNo",
      sortable: true,
      cell: (row) => {
        return (
          <span className="badge bg-light-info">
            <span>{row.mobileNo}</span>
          </span>
        );
      },
    },
    {
      name: getFormattedMessage("globally.input.email.label"),
      selector: (row) => row.email,
      sortField: "email",
      sortable: true,
      wrap: true,
    },
    {
      name: getFormattedMessage("globally.input.city.label"),
      selector: (row) => row.city,
      sortField: "city",
      sortable: true,
    },
    {
      name: getFormattedMessage("react-data-table.action.column.label"),
      right: true,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <ActionButton
          item={row}
          goToEditProduct={goToEditLedger}
          isEditMode={true}
          onClickDeleteModel={onClickDeleteModel}
        />
      ),
    },
  ];

  return (
    <div>
      <MasterLayout>
        <TopProgressBar />
        <TabTitle title={placeholderText("ledger.title")} />
        <HeaderTitle title={placeholderText("ListofLegers.title")} />
        <div className="row">
    <div className="col-md-6 mb-3 searchBox">
        <div className="position-relative d-flex width-320">

          <input
            className="form-control ps-8"
            type="search"
            name="searchData"
            id="search"
            placeholder={placeholderText(
              "react-data-table.searchbar.placeholder"
            )}
            aria-label="Search"
             onChange={(e) => handleSearchData(e)}
          />
          <span className="position-absolute d-flex align-items-center top-0 bottom-0 left-0 text-gray-600 ms-3">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
      </div>   
      <div className="col-md-6">
          <TableButton ButtonValue={getFormattedMessage('Ledger.create.title')} to='#/app/ledgers/create' />
      </div> 

</div>
        <ReactDataTable
          columns={columns}
          items={itemsValue}
          isLoading={isLoading}
        //   ButtonValue={getFormattedMessage("Ledger.create.title")}
          totalRows={itemsValue?.length}
        //   goToImport={handleClose}
        //   to="#/app/ledgers/create"
        isUnitFilter
        subHeader={false}
        />
        <DeleteLedger
          onClickDeleteModel={onClickDeleteModel}
          deleteModel={deleteModel}
          onDelete={isDelete}
        />
      </MasterLayout>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { ledger, totalRecord, isLoading, allConfigData } = state;
  return { ledger, totalRecord, isLoading, allConfigData };
};

export default connect(mapStateToProps, { fetchLedger })(Ledger);

import { Navigate, useNavigate } from "react-router"
import TopProgressBar from "../../shared/components/loaders/TopProgressBar"
import { getFormattedMessage } from "../../shared/sharedMethod"
import HeaderTitle from "../header/HeaderTitle"
import MasterLayout from "../MasterLayout"
import PosSupplierForm from "./PosSupplierForm"
import { connect } from "react-redux"
import { addSuppliers } from "../../store/action/PosSupplierAction"
import { Filters } from "../../constants"
import { useEffect, useState } from "react"

const PosCreateSupplier = (props) => {

    const {addSuppliers,handleClose} =props;

    const [formcode, setFormCode] = useState("M03");
    const navigate = useNavigate () ;
  useEffect(() => {
    ;
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility && item?.attributes?.permissionAdd);
        if(!formCodeItems.length > 0){
            navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    } 
  }, []);
    
    const addSuppliersData = (formValue) => {
        addSuppliers(formValue, navigate, Filters.OBJ);
    };

    return( 
        <div>
            <MasterLayout>
                <TopProgressBar/>
                {/* <HeaderTitle title={getFormattedMessage('supplier.create.title')} to='/app/supplier' /> */}
               
                <PosSupplierForm addSuppliersData={addSuppliersData} 
                title={getFormattedMessage('supplier.create.title')}  to='/app/supplier' handleClose={handleClose}
                />
            </MasterLayout>
        </div>
    )
}

export default connect(null,{addSuppliers}) (PosCreateSupplier)
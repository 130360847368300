import {
  faEye,
  faFileExcel,
  faFilePdf,
  faFilter,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import {
  // fetchMonthSales,
  fetchMonthSalesparam,
} from "../../../store/action/monthlySalesAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReactTabulator } from "react-tabulator";
import { connect, useDispatch } from "react-redux";
import ClosingStockReportForm from "../closingStockReport/ClosingStockReportForm";
import ClosingStockField from "../closingStockReport/ClosingStockField";
import { fetchAcYear } from "../../../store/action/acYearAction";
import { Button, InputGroup } from "react-bootstrap-v5";
import ReactSelect from "../../../shared/select/reactSelect";
import { placeholderText } from "../../../shared/sharedMethod";
import { fetchGstrReport } from "../../../store/action/gstrReportAction";
import { generatePDF, printPDF } from "../pdfUtil";
import { fetchCompanyConfig } from "../../../store/action/companyConfigAction";
import Loader from "../../loader/Loader";
import moment from "moment";
import { prepareGstArray } from "../../../shared/prepareArray/prepareGstArray";
import { addToast } from "../../../store/action/toastAction";
// import * as XLSX from 'xlsx';

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const MonthlySalesTab = (props) => {
  const { stockMovement, acYear, fetchAcYear, fetchGstrReport, monthlySales, fetchCompanyConfig, companyConfig, setFromDate, setDateto, fetchMonthSalesparam } = props;
  const today = new Date();
  const formattedDate = formatDate(today);
  const numOfDaysAdded = 0;
  const date = today.setDate(today.getDate() + numOfDaysAdded);
  const defaultValue = new Date(date).toISOString().split("T")[0]; // YYYY-MM-dd
  const dispatch = useDispatch();
  const [filterSales, setFilterSales] = useState([]);
  const [fieldShow, setFieldShow] = useState(false);
  const handleFieldClose = () => setFieldShow(!fieldShow);
  const [showField, setShowField] = useState();
  const [minYear, setMinYear] = useState();
  const [maxYear, setMaxYear] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(!show);
  const year = useMemo(() => acYear, [acYear]);
  const [label, setLabel] = useState(false);
  const [fetchRangeValue, setFetchRangeValue] = useState({
    category1Name: "",
    category2Name: "",
    category3Name: "",
    unitName: ""
  });
  const paySlipRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const nextYear = currentYear + 1;
  const yearRange = `${currentYear}-${nextYear}`;
  const [selectedYearRange, setSelectedYearRange] = useState({
    value: yearRange,
    label: yearRange,
  });
  const [totalData, setTotalData] = useState({});

  const [range, setRange] = useState({
    category1Name: "",
    category2Name: "",
    category3Name: "",
    unitName: "",
  });

  const [dates, setDate] = useState({
    fromDate: defaultValue,
    toDate: defaultValue,
  });
  const [acYears, setAcYears] = useState({
    acFrom: 0,
    acTo: 0,
    acYear: "",
  });

  const handleChange = (selectedOption) => {
    setSelectedYearRange(selectedOption);
    loadValues(selectedOption);
  };

  useEffect(() => {
    setAcYears({ ...acYears, acYear: acYear[0]?.attributes?.acYear });
  }, [acYear])

  const loadValues = (obj) => {
    console.log("onTaxChange obj", obj);
    const new_values = { value: obj?.value ?? 0, label: obj?.label };
    // setIsClearDropdown(false);
    // setIsDropdown(false);
    setAcYears({ ...acYears, acYear: new_values.label });
    console.log("New value => ", new_values)
    // setErrors("");
    let values =
      "?fromDate='" +
      new_values.label.substr(0, 4) +
      "-04-01'" +
      "&toDate='" +
      new_values.label.substr(5, 9) +
      "-03-31'";

    console.log(values);
    // fetchMonthSalesparam(values, filter, true);
  };

  const columns = [
    {
      title: "Month", field: "month", headerSort: false, bottomCalc: () => 'Total', width: 250, // Custom text for the bottom of this column
      bottomCalcFormatter: (cell) => {
        return '<strong>Total</strong>'; // Display 'Total' in bold
      }
    },
    {
      title: "Cash", field: "cash", headerSort: false, bottomCalc: () => 'Total', hozAlign: "center", headerHozAlign: "center", width: 150, // Custom text for the bottom of this column
      bottomCalc: (values, data) => {
        // Filter unique values
        const uniqueValues = [...new Set(values)];

        // Custom calculation logic
        let total = 0;
        uniqueValues.forEach(value => {
          total += value || 0; // Ensure values are parsed as numbers
        });

        return total; // Return total with two decimal places
      }
    },
    {
      title: "Credit", field: "credit", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 150, bottomCalc: (values, data) => {
        // Filter unique values
        const uniqueValues = [...new Set(values)];

        // Custom calculation logic
        let total = 0;
        uniqueValues.forEach(value => {
          total += value || 0; // Ensure values are parsed as numbers
        });

        return total; // Return total with two decimal places
      }
    },
    {
      title: "Bank", field: "bank", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 150, bottomCalc: (values, data) => {
        // Filter unique values
        const uniqueValues = [...new Set(values)];

        // Custom calculation logic
        let total = 0;
        uniqueValues.forEach(value => {
          total += value || 0; // Ensure values are parsed as numbers
        });

        return total; // Return total with two decimal places
      }
    },
    {
      title: "Total", field: "total", headerSort: false, hozAlign: "center", headerHozAlign: "center", width: 150, bottomCalc: (values, data) => {
        // Filter unique values
        const uniqueValues = [...new Set(values)];

        // Custom calculation logic
        let total = 0;
        uniqueValues.forEach(value => {
          total += value || 0; // Ensure values are parsed as numbers
        });

        return total; // Return total with two decimal places
      }
    },
    {
      title: "Sales Value", field: "salesValue", headerSort: false, hozAlign: "right", headerHozAlign: "right", width: 50, bottomCalc: (values, data) => {
        // Filter unique values
        const uniqueValues = [...new Set(values)];

        // Custom calculation logic
        let total = 0;
        uniqueValues.forEach(value => {
          total += parseFloat(value) || 0; // Ensure values are parsed as numbers
        });

        return total.toFixed(2); // Return total with two decimal places
      }
    },
    // { title: "Reverse Charges", field: "reverseCharges", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
    // { title: "Applicable % of Tax Rate", field: "applicableTaxRate", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
    // { title: "Invoice Type", field: "invoiceType", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
    // { title: "E-Commerce GSTIN", field: "ecommerceGstin", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
    // { title: "Rate", field: "rate", headerSort: false, hozAlign: "right", headerHozAlign: "right" },
    // {
    //   title: "Taxable Value", field: "taxableValue", headerSort: false, hozAlign: "right", headerHozAlign: "right", bottomCalc: (values, data) => {
    //     // Custom calculation logic
    //     let total = 0;
    //     values.forEach(value => {
    //       total += parseFloat(value) || 0; // Ensure values are parsed as numbers
    //     });
    //     return total.toFixed(2); // Return total with two decimal places
    //   }
    // },
    // {
    //   title: "Cess Amount", field: "cessAmount", headerSort: false, hozAlign: "right", headerHozAlign: "right", bottomCalc: (values, data) => {
    //     // Custom calculation logic
    //     let total = 0;
    //     values.forEach(value => {
    //       total += parseFloat(value) || 0; // Ensure values are parsed as numbers
    //     });
    //     return total.toFixed(2); // Return total with two decimal places
    //   }
    // }
  ];

  const companyDetails = {
    companyName: companyConfig?.companyName || 'Company Name',
    address: `${companyConfig?.attributes?.address1 || ''} , ${companyConfig?.attributes?.address2 || ''}`,
    phoneNumber: companyConfig?.attributes?.phoneNo || 'Phone Number'
  };

  const fromDate = useRef();
  const toDate = useRef();

  // const loadValues = (filter) => {
  //   return "?fromDate=" + filter + "&toDate=" + filter;
  // }

  useEffect(() => {
    fetchAcYear();
    fetchCompanyConfig();
    // fetchGstrReport(loadValues(defaultValue));
    setFromDate(defaultValue);
    setDateto(defaultValue);
  }, []);

  useEffect(() => {
    debugger
    setFilterSales(monthlySales);
  }, [monthlySales]);

  const reduceInvoiceTotals = (invoices) => {
    const invoiceTotals = invoices?.reduce((acc, invoice) => {
      const { invNo, netTotal } = invoice;
      if (!acc[invNo]) {
        acc[invNo] = parseFloat(netTotal);
      }
      return acc;
    }, {});

    if (invoiceTotals) {
      const totalValue = Object.values(invoiceTotals)?.reduce((sum, value) => sum + value, 0);
      return totalValue;
    }
  }

  useEffect(() => {
    setMinYear(selectedYearRange?.label?.split("-")[0] + "-04-01");
    setMaxYear(selectedYearRange?.label?.split("-")[1] + "-03-31");
  }, [selectedYearRange]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setDate({ ...dates, [name]: value });
    setFromDate(name === "fromDate" ? value : dates.fromDate);
    setDateto(name === "toDate" ? value : dates.toDate);
  }

  const fetchData = () => {
    // let values = "?fromDate=" + dates?.fromDate + "&toDate=" + dates?.toDate;
    // if (moment(dates?.fromDate).isAfter(dates?.toDate)) {
    //   dispatch(addToast({ text: 'Invalid date range.!', type: 'error' }));
    // } else {
    //   fetchGstrReport(values);
    // }
    fetchMonthSalesparam(acYears?.acYear);
  }

  const sortInvoices = (b2b) => {
    return b2b.sort((a, b) => {
      // Compare invDate (convert date strings to Date objects)
      const dateA = new Date(a.invDate);
      const dateB = new Date(b.invDate);
      if (dateA - dateB !== 0) {
        return dateA - dateB;
      }

      // Compare invNo (alphanumeric comparison)
      if (a.invNo !== b.invNo) {
        return a.invNo.localeCompare(b.invNo);
      }

      // Compare customerRegno (alphanumeric comparison)
      return a.customerRegno.localeCompare(b.customerRegno);
    });
  }

  const itemsValue = filterSales?.length > 0 &&
    filterSales?.map(data => ({
      month: data?.Month,
      cash: data?.Cash,
      credit: data?.Credit,
      bank: data?.Bank,
      total: data?.Total,
      salesValue: parseFloat(data?.TotalSalesValue).toFixed(2)
    })) || [];

  const handlePrintPDF = () => {
    printPDF(paySlipRef, companyDetails, 'Landscape', 'b2bReport', "GSTR1 Summary for B2B,SEZ,DE Invoices - 4A, 4B ,6B ,6C", itemsValue, columns);
  };

  const handleExportToPDF = () => {
    generatePDF(paySlipRef, companyDetails, 'Landscape', 'b2bReport', "GSTR1 Summary for B2B,SEZ,DE Invoices - 4A, 4B ,6B ,6C", itemsValue, columns)
      .then(pdf => {
        pdf.save('b2bReport.pdf');
      })
      .catch(error => {
        console.error('Error generating PDF:', error);
      });
  };

  const generateJSONFile = (data, filename) => {
    const jsonString = JSON.stringify(data);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <Loader />
      <div style={{ backgroundColor: "white", height: "100%" }}>

        {fieldShow && <ClosingStockField setShowField={setShowField} fieldShow={fieldShow}
          handleFieldClose={handleFieldClose} Title={"Stock Movement Report Form"} />}

        <div className="row">
          <div className="col-md-5">
            {/* <h2 style={{ color: "green" }}>
                            {"GSTR1 Summary for B2B,SEZ,DE Invoices - 4A, 4B ,6B ,6C"}
                        </h2> */}
            <div className="row" style={{ marginTop: "-10px" }}>
              <div className="col-md-4">
                <h4 className="mt-2">A/C Year</h4>
                <InputGroup className="flex-nowrap mb-4">
                  <ReactSelect
                    className="position-relative"
                    placeholder={placeholderText("globally.input.AcYear.label")}
                    // ref={acFrom}
                    defaultValue={selectedYearRange}
                    data={acYear}
                    onChange={handleChange}
                  />
                </InputGroup>
              </div>
              {/* <div className="col-md-4 mt-2">
                                <h4>To Date</h4>
                                <input
                                    id2="dateRequired2"
                                    type="date"
                                    className="form-control  rounded text-align-center  align-items-center mr-15 mb-5"
                                    ref={toDate}
                                    defaultValue={defaultValue}
                                    name="toDate"
                                    onChange={onChange}
                                    min={dates?.fromDate}
                                ></input>
                            </div> */}
              <div className="col-md-4 gstrGenerateBtn">
                <button
                  type="button"
                  className="btn btn-success "
                  onClick={() => fetchData()}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-5"></div>
          <div className="col-md-2 ">
            <button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "none",
                borderRadius: "10px",
                width: "220px",
                height: "60px",
                gap: "13px",
                background: "white",
                marginTop: "-20px",
                fontSize: "11px",
                marginLeft: "-20px"
              }}
              ref={paySlipRef}
            >
              <FontAwesomeIcon
                icon={faPrint}
                className="fa-2x search-icon"
                style={{ color: "black", paddingTop: "8px" }}
                onClick={handlePrintPDF}
              ></FontAwesomeIcon>

              <FontAwesomeIcon
                icon={faFileExcel}
                className="fa-2x search-icon "
                style={{
                  color: "green",
                  paddingLeft: "10px",
                  paddingTop: "7px",
                }}
              //   onClick={downloadUpdatedExcel}
              ></FontAwesomeIcon>

              <FontAwesomeIcon
                icon={faFilePdf}
                className="fa-2x search-icon"
                style={{ color: "red", paddingLeft: "10px", paddingTop: "7px" }}
                onClick={handleExportToPDF}
              ></FontAwesomeIcon>
              {/* <span onClick={handleGenerateJSON}>Json</span> */}
            </button>
          </div>
        </div>

        <h2
          style={{
            // color: "green",
            fontSize: "17px",
            fontWeight: "700"
          }}
        >
          {`Sales Report - Month wise for the Year of ${acYears.acYear}`}
        </h2>
        <div className="row">
          <div className="col-md-9"></div>
          <div
            className="col-md-3"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "-50px",
            }}
          >
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 w-100 tabulator-container gstrTable">
            {/* <div className="summary-row">
                            <span>No.of Recipients: <span className="fw-bold">{totalData?.totalRecipient ? totalData?.totalRecipient : 0}</span></span>
                            <span>No.of Invoices: <span className="fw-bold">{totalData?.invoice ? totalData?.invoice : 0}</span></span>
                            <span>Total Inv.Value: <span className="fw-bold">{totalData?.totalInvoiceValue ? parseFloat(totalData?.totalInvoiceValue).toFixed(2) : 0}</span></span>
                            <span>Total Taxable: <span className="fw-bold">{totalData?.totalTaxValue ? parseFloat(totalData?.totalTaxValue).toFixed(2) : 0}</span></span>
                            <span>Total Cess: <span className="fw-bold">{totalData?.totalCessValue ? parseFloat(totalData?.totalCessValue).toFixed(2) : 0}</span></span>
                        </div> */}
            <ReactTabulator
              columns={columns}
              data={itemsValue}
              // ref={pdfRef}
              options={{
                columnHeaderVertAlign: "bottom",
                layout: 'fitDataStretch',
                // responsiveLayout: "collapse",
                placeholder: "No records found",
                height: "420px",
              }}
            />
          </div>
        </div>

        {show ? <ClosingStockReportForm
          stockMovement={stockMovement}
          show={show} handleClose={handleClose}
          // setItemValues={setItemValues}
          singleStock={itemsValue}
          setRange={setRange}
          Title={"Stock Movement Report Form"}
        // search={search}
        /> : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { stockMovement, acYear, monthlySales, companyConfig } = state;
  return { stockMovement, acYear, monthlySales, companyConfig }
}

export default connect(mapStateToProps, { fetchAcYear, fetchGstrReport, fetchCompanyConfig, fetchMonthSalesparam })(MonthlySalesTab);

import React, { useEffect, useState } from "react";
import { Modal, Table, Image } from "react-bootstrap";
import { calculateProductCost } from "../../shared/SharedMethod";
import {
    currencySymbolHendling,
    getFormattedDate,
    getFormattedMessage,
} from "../../../shared/sharedMethod";
import { FONT_WEIGHT } from "ag-charts-community/dist/esm/es6/module-support";
import { Collapse } from "react-bootstrap-v5";
import { size } from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { customerData } from "../../../constants";
import moment from "moment";

const PaymentSlipModalSecond = (props) => {
    const {
        modalShowPaymentSlip,
        setModalShowPaymentSlip,
        updateProducts,
        printPaymentReceiptPdf,
        paymentType,
        frontSetting,
        paymentDetails,
        allConfigData,
        setPaymentValue,
        paymentTypeDefaultValue,
        cutomerDetails,
        userData,
        companyConfig,
        // cartItemsPrint,
        cart
    } = props;
    const dispatch = useDispatch();

    const userDetails = useSelector((state) => state.cutomerDetails);
    const cartitemsPrint = useSelector((state) => state.cartItemsPrint);
    const [cgst, setCgst] = useState(0);
    const [sgst, setSgst] = useState(0);
    const [totalTax, setTotalTax] = useState(0);
    const [grossTotal, setGrossTotal] = useState(0);
    const [less, setLess] = useState(0);
    const [round, setRound] = useState(0);
    const [bill, setBill] = useState(0);
    const [grand, setGrand] = useState(0);
    const [balance, setBalance] = useState(0);
    const [customerName, setCustomerName] = useState("");
    const [customerAddress, setCustomerAddress] = useState("");
    const [customerPhno, setCustomerPhno] = useState("");
    const [receivedAmount, setReceivedAmount] = useState(0);
    useEffect(() => {
        console.log("cartitems", cartitemsPrint);
    }, [cart]);

    useEffect(() => {
        // console.log("Modal show payment slip state changed:", modalShowPaymentSlip);
        // console.log("UserDetails from state:", userDetails);
        let cgst = 0;
        let name = "";
        userDetails[0]?.item?.map((items) => {
            // console.log("products", items);
            cgst = cgst + (items?.tax_amount);
        });

        setCgst(parseFloat(cgst / 2).toFixed(2));
        setSgst(parseFloat(cgst / 2).toFixed(2));
        setTotalTax(cgst);
        setGrossTotal(userDetails[0]?.gross ? userDetails[0]?.gross : 0);
        setLess(userDetails[0]?.less ? parseFloat(userDetails[0]?.less).toFixed(2) : 0);
        setRound(userDetails[0]?.round ? userDetails[0]?.round : 0);
        setBill(userDetails[0]?.calc?.bill ? userDetails[0]?.calc?.bill : 0);
        setGrand(userDetails[0]?.grand ? userDetails[0]?.grand : 0);
        setBalance(userDetails[0]?.balance ? userDetails[0]?.balance : 0);
        setCustomerName(userDetails[0]?.name ? userDetails[0]?.name : "");
        setCustomerAddress(userDetails[0]?.address ? userDetails[0]?.address : "");
        setCustomerPhno(userDetails[0]?.phno ? userDetails[0]?.phno : "");
        setReceivedAmount(userDetails[0]?.received_amount ? userDetails[0]?.received_amount : 0);

        dispatch({ type: customerData.CUSTOMER_DATA, payload: userDetails });

    }, [modalShowPaymentSlip]);

    const currency =
        updateProducts.settings &&
        updateProducts.settings.attributes &&
        updateProducts.settings.attributes.currency_symbol;

    const companyDetails = {
        companyName: "ABC Super Market",
        address1: "34, Anna Nagar East",
        address2: "Tirunelveli",
        contactInfo: "ph:0462 356247",
        gstin: "GSTIN : 965448787841787",
        logo: "http://cogitate-001-site18.gtempurl.com/Images/Logo_1.jpg",
        productDetails: [
            { productName: "Turmeric Powder 100G", mrp: 50.00, qty: 10, rate: 46.25, amount: 138.45 },
            { productName: "Turmeric Powder 50G", mrp: 50.00, qty: 10, rate: 46.25, amount: 138.45 },
            { productName: "Turmeric Powder 300G", mrp: 50.00, qty: 10, rate: 46.25, amount: 138.45 },
            { productName: "Turmeric Powder 100G", mrp: 50.00, qty: 10, rate: 46.25, amount: 138.45 },
            { productName: "Turmeric Powder 50G", mrp: 50.00, qty: 10, rate: 46.25, amount: 138.45 },
            { productName: "Turmeric Powder 100G", mrp: 50.00, qty: 10, rate: 46.25, amount: 138.45 },
            { productName: "Turmeric Powder 50G", mrp: 50.00, qty: 10, rate: 46.25, amount: 138.45 }
        ],
        // productDetails: userDetails[0]?.item?.map((item) => {
        //     return {
        //         productName: item.name,
        //         mrp: parseFloat(item.mrp).toFixed(2),
        //         qty: item.quantity,
        //         rate: item.netSalesRate,
        //         amount: item.netAmount
        //     }
        // }),
        cgstPercent: "",
        sgstPercent: "",
        cgstValue: cgst,
        sgstValue: sgst,
        totalTaxValue: totalTax,
        grossTotal: grossTotal,
        less: less,
        roundOff: round,
        netTotal: grand,
        invoiceNo: "ING242243",
        dateTime: "23/10/2022 10:00AM",
        billedBy: "Muthu Krishnan",
        customerName: "Anjalai agency chennai",
        customerAddress: "8/6 North st, keelbagam, moolaikaraipatti",
        customerPhoneNo: "Ph" + "1234566789",
        billValue: bill,
        points: "0.00",
        cashReceived: receivedAmount,
        balancePaid: balance
    }

    const handlePrint = () => {
        const printContent = document.getElementById("thermal-print-content");
        const printWindow = window.open("", "_blank", "width=288,height=600");
        const htmlContent = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <title>Print Bill</title>
                <style>
                    @media print {
                        @page {
                            size: auto;
                        }
                        
                        body {
                            margin: 0;
                            padding: 0;
                            width: 288px; /* Set the paper width to 288px */
                            font-family: monospace;
                            font-size: 12px;
                        }
                        .print-container {
                            width: 288px; /* Match the paper width */
                            margin: 0 auto;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                        }
                        th, td {
                            text-align: left;
                            padding: 4px;
                            word-wrap: break-word;
                        }
                        th {
                            border-bottom: 1px solid #000;
                        }
                        
                        /* Hide filename and date */
                        @page :first {
                            margin-top: 0;
                        }
                        @page :left {
                            margin-left: 0;
                        }
                        @page :right {
                            margin-right: 0;
                        }
                    }
                </style>
            </head>
            <body>
                <div class="print-container">
                    ${printContent.innerHTML}
                </div>
                <script>
                    window.onload = function() {
                        setTimeout(function() {
                            document.title = '';
                            window.print();
                            window.close();
                        }, 250);
                    }
                </script>
            </body>
            </html>
        `;

        printWindow.document.open();
        printWindow.document.write(htmlContent);
        printWindow.document.close();
    };

    const calculateSavings = () => {
        debugger;
        let totalMRP = 0;
        let totalSellingPrice = 0;

        cartitemsPrint?.attributes?.sales2?.forEach((item) => {
            if (item?.mrp > 0) {
                totalMRP += item.mrp * item.qty;
                totalSellingPrice += item.netAmount;
            }
        });

        const totalSavings = totalMRP - totalSellingPrice;

        // return {
        //   totalMRP: totalMRP.toFixed(2),
        //   totalSellingPrice: totalSellingPrice.toFixed(2),
        //   totalSavings: totalSavings.toFixed(2),
        // };
        return totalSavings.toFixed(2);
    };

    return (
        <Modal
            show={modalShowPaymentSlip}
            onHide={() => {
                setModalShowPaymentSlip(false);
                setPaymentValue({
                    payment_type: paymentTypeDefaultValue[0],
                });
            }}
            // size="sm"
            // style={{ width: "288px"}}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="pos-modal-thermal pos-modal-a5-temp2"
            style={{
                fontFamily: 'monospace'
            }}
        >
            <Modal.Header closeButton className="pb-3">
                {/* <Modal.Title id="contained-modal-title-vcenter">
                    {getFormattedMessage("pos-sale.detail.invoice.info")} POS
                </Modal.Title> */}
            </Modal.Header>
            <Modal.Body className="pt-0 pb-3" id="thermal-print-content">

                {/* <div className="row-center"><span style={{ fontSize: "20px", fontWeight: "bold" }}>{companyDetails.companyName}</span></div>
                <div className="row-center"><span>{companyDetails.address1}</span></div>
                <div className="row-center"><span>{companyDetails.address2}</span></div>
                <div className="row-center"><span>{companyDetails.contactInfo}</span></div>
                <div className="row-center"><span>{companyDetails.gstin}</span></div> */}

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* <img src={companyDetails.logo} alt="Company Logo" style={{ width: '25mm', height: '25mm' }} /> */}
                    <div style={{ textAlign: 'center', flex: 1 }}>
                        <div className="row-center"><span style={{ fontSize: "20px", fontWeight: "bold" }}>{companyConfig?.companyName}</span></div>
                        <div className="row-center"><span>{companyConfig?.attributes?.address1}</span></div>
                        <div className="row-center"><span>{companyConfig?.attributes?.address2}</span></div>
                        <div className="row-center"><span>{companyConfig?.attributes?.phoneNo}</span></div>
                        <div className="row-center"><span>{companyConfig?.attributes?.gstin}</span></div>
                    </div>
                </div>
                {/* <hr /> */}
                {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '40%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>No: {cartitemsPrint?.attributes?.invNo}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '60%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Date : {moment(cartitemsPrint?.attributes?.invDate).format("DD-MM-YYYY hh:mm A")}</p>
                    </div>
                </div> */}
                {/* <hr /> */}
                <table style={{ width: "100%", padding: "0", marginTop: "5px" }}>
                    <thead style={{ fontSize: "12px", fontWeight: "bold", color: "black" }}>
                        <tr>
                            {/* <th style={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', textAlign: 'right' }} scope="row" className="print1-col-1">Sl.No.</th> */}
                            <th style={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', textAlign: 'left' }} scope="row" className="print1-col-2">PRODUCT</th>
                            <th style={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', textAlign: 'right' }} scope="row" className="print1-col-3">MRP</th>
                            <th style={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', textAlign: 'right' }} scope="row" className="print1-col-5">RATE</th>
                            <th style={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', textAlign: 'right' }} scope="row" className="print1-col-4">QTY</th>
                            <th style={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', textAlign: 'right' }} scope="row" className="print1-col-6">AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize: "10px" }}>
                        {
                            cartitemsPrint?.attributes?.sales2.map((item, index) =>
                            (
                                <tr className="mt-4 mb-4 text-black">
                                    {/* <td scope="row" className="print1-col-1">{index + 1}</td> */}
                                    <td scope="row" className="print1-col-2">{item.itemName}</td>
                                    <td scope="row" style={{ textAlign: 'right' }} className="print1-col-3">{parseFloat(item.mrp).toFixed(2)}</td>
                                    <td scope="row" style={{ textAlign: 'right' }} className="print1-col-5">{parseFloat(item.netSalesRate).toFixed(2)}</td>
                                    <td scope="row" style={{ textAlign: 'right' }} className="print1-col-4">{item.qty + item.salesUnitName}</td>
                                    <td scope="row" style={{ textAlign: 'right' }} className="print1-col-6">{parseFloat(item.netAmount).toFixed(2)}</td>
                                </tr>
                            )
                            )
                        }

                    </tbody>
                </table>
                <div style={{ borderBottom: '1px dotted', margin: '2px 0' }} />

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '43%', fontSize: "10px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}><u>Tax Info</u></p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>CGST {companyDetails.cgstPercent} % : ₹ {(cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.taxAmount), 0) / 2).toFixed(2)}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>SGST {companyDetails.sgstPercent} % : ₹ {(cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.taxAmount), 0) / 2).toFixed(2)}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px", fontWeight: "bold" }}>Total   : ₹ {cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.taxAmount), 0).toFixed(2)}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '33%', fontSize: "11px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Gross Total:</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Less/Adj:</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Rounded off:</p>
                        <p style={{ fontSize: '12px', fontWeight: 'bold' }}>Total:</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '24%', fontSize: "11px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>₹ {(cartitemsPrint?.attributes?.salesValue)?.toFixed(2)}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>₹ {(cartitemsPrint?.attributes?.less)?.toFixed(2)}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>₹ {(cartitemsPrint?.attributes?.roundOff)?.toFixed(2)}</p>
                        {/* <p style={{ fontSize: '12px', fontWeight: 'bold' }}>₹ {cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.netAmount), 0)?.toFixed(2)}</p> */}
                        <p style={{ fontSize: '12px', fontWeight: 'bold' }}>₹ {(cartitemsPrint?.attributes?.netTotal)?.toFixed(2)}</p>
                    </div>
                </div>
                <div style={{ borderBottom: '1px dotted', margin: '2px 0' }} />

                {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', fontSize: "10px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Item : {cartitemsPrint?.attributes?.sales2?.length}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Qty : {cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.qty), 0)}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Baskets : {cartitemsPrint?.attributes?.sales2?.length > 0 ? 1 : 0}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '50%', fontSize: "11px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Tender: ₹ {cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.netAmount), 0).toFixed(2)}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Refund: ₹ {"0.00"}</p>
                    </div>
                </div> */}

                {/* <div style={{ borderBottom: '1px dotted', margin: '2px 0' }} /> */}

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.invNo ? "Inv No.:" + cartitemsPrint?.attributes?.invNo : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.createdAt ? "Date:" + moment(cartitemsPrint?.attributes?.createdAt).format("DD-MM-YYYY hh:mm A") : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.billedByName ? "Billed By:" + cartitemsPrint?.attributes?.billedByName : ""}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '50%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.customerName ? "Customer:" + cartitemsPrint?.attributes?.customerName : ""}</p>
                        {/* <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.customerAddress ? cartitemsPrint?.attributes?.customerAddress : ""}</p> */}
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.customerMobile ? cartitemsPrint?.attributes?.customerMobile : ""}</p>
                    </div>
                </div>

                <div style={{ borderBottom: '1px dotted', margin: '2px 0' }} />

                <div style={{ textAlign: "center", fontSize: "10px" }}>
                    <h4>TOTAL SAVINGS : ₹ {calculateSavings() > 0 ? calculateSavings() : "0.00"}</h4>
                    <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>No of Items : {cartitemsPrint?.attributes?.sales2?.length}</p>
                    {/* <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Cash Received :  ₹{cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.netAmount), 0).toFixed(2)} | Balance Paid : ₹0.00</p> */}
                    {/* <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Cash Received : {cartitemsPrint?.attributes?.received ? ("₹ " + (cartitemsPrint?.attributes?.received)?.toFixed(2) + " | Balance Paid :" + "₹ " + (cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.netAmount), 0) - cartitemsPrint?.attributes?.received).toFixed(2)) : "₹ " + cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.netAmount), 0).toFixed(2) + " | Balance Paid : ₹0.00"}</p> */}
                    <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>Cash Received : {cartitemsPrint?.attributes?.received ? ("₹ " + (cartitemsPrint?.attributes?.received)?.toFixed(2) + " | Balance Paid :" + "₹ " + ((cartitemsPrint?.attributes?.netTotal) - cartitemsPrint?.attributes?.received).toFixed(2)) : "₹ " + cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.netAmount), 0).toFixed(2) + " | Balance Paid : ₹0.00"}</p>

                    <div style={{ borderBottom: '1px dotted', margin: '10px 0' }} />

                    <h6>Thank You.! Visit Again.!</h6>
                </div>

                <div style={{ textAlign: "center", fontSize: "10px" }}>
                    <h4>Delivery Slip</h4>
                </div>

                {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.dateTime ? "Date :" : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.billedBy ? "Billed By :" : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.invoiceNo ? "Invoice No. :" : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.customerMobile ? "Mobile :" : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.customerName ? "Name :" : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{"Bill Amount :"}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '50%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.dateTime ? moment(cartitemsPrint?.attributes?.invDate).format("DD-MM-YYYY hh:mm A") : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.billedBy ? cartitemsPrint?.attributes?.billedBy : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.invoiceNo ? cartitemsPrint?.attributes?.invNo : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.customerMobile ? cartitemsPrint?.attributes?.customerMobile : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.customerName ? cartitemsPrint?.attributes?.customerName : ""}</p>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px", fontWeight: "bold" }}>₹ {cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.netAmount), 0)?.toFixed(2)}</p>
                    </div>
                </div> */}

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.dateTime ? "Date :" : ""}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '50%', fontSize: "12px", wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.dateTime ? moment(cartitemsPrint?.attributes?.invDate).format("DD-MM-YYYY hh:mm A") : ""}</p>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.invNo ? "Inv No.:" : ""}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '50%', fontSize: "12px", wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.invNo ? cartitemsPrint?.attributes?.invNo : ""}</p>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.createdAt ? "Date :" : ""}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '50%', fontSize: "12px", wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.createdAt ? moment(cartitemsPrint?.attributes?.createdAt).format("DD-MM-YYYY hh:mm A") : ""}</p>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.billedByName ? "Billed By :" : ""}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '50%', fontSize: "12px", wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.billedByName ? cartitemsPrint?.attributes?.billedByName : ""}</p>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.customerMobile ? "Mobile No:" : ""}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '50%', fontSize: "12px", wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.customerMobile ? cartitemsPrint?.attributes?.customerMobile : ""}</p>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.customerName ? "Name :" : ""}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '50%', fontSize: "12px", wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{cartitemsPrint?.attributes?.customerName ? cartitemsPrint?.attributes?.customerName : ""}</p>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', fontSize: "12px" }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px" }}>{"Bill Amount :"}</p>
                    </div>
                    <div style={{ textAlign: 'right', width: '50%', fontSize: "12px", wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                        <p style={{ padding: "0", margin: "0", marginBottom: "3px", fontWeight: "bold" }}>₹ {cartitemsPrint?.attributes?.netTotal?.toFixed(2)}</p>
                    </div>
                </div>


                <table style={{ width: "100%", padding: "0", marginTop: "5px" }}>
                    <thead style={{ fontSize: "12px", fontWeight: "bold", color: "black" }}>
                        <tr>
                            {/* <th style={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', textAlign: 'right' }} scope="row" className="print1-col-1">Sl.No.</th> */}
                            <th style={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', textAlign: 'center', width: "33%" }} scope="row" className="print1-col-2">Item</th>
                            <th style={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', textAlign: 'center', width: "33%" }} scope="row" className="print1-col-3">Qty</th>
                            <th style={{ borderBottom: '1px solid #000', borderTop: '1px solid #000', textAlign: 'center', width: "33%" }} scope="row" className="print1-col-5">Baskets</th>
                        </tr>
                    </thead>
                    <tbody style={{ fontSize: "10px" }}>
                        <tr className="mt-4 mb-4 text-black">
                            {/* <td scope="row" className="print1-col-1">{index + 1}</td> */}
                            <td scope="row" style={{ textAlign: 'center', width: "33%" }} className="print1-col-3">{cartitemsPrint?.attributes?.sales2?.length}</td>
                            <td scope="row" style={{ textAlign: 'center', width: "33%" }} className="print1-col-5">{cartitemsPrint?.attributes?.sales2?.reduce((a, b) => a + parseFloat(b.qty), 0)}</td>
                            <td scope="row" style={{ textAlign: 'center', width: "33%" }} className="print1-col-4">{cartitemsPrint?.attributes?.sales2?.length > 0 ? 1 : 0}</td>
                        </tr>
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer className="justify-content-center pt-2">
                <button
                    className="btn btn-primary text-white"
                    // onClick={printPaymentReceiptPdf}
                    onClick={handlePrint}
                >
                    {getFormattedMessage("print.title")}
                </button>
                <button
                    className="btn btn-secondary"
                    onClick={() => {
                        setModalShowPaymentSlip(false);
                        setPaymentValue({
                            payment_type: paymentTypeDefaultValue[0],
                        });
                    }}
                >
                    {getFormattedMessage("pos-close-btn.title")}
                </button>
            </Modal.Footer>
        </Modal>
    );
};
// export default PaymentSlipModal;

const mapStateToProps = (state) => {
    const {
        cutomerDetails
        // holdListData,
    } = state;
    return {
        // holdListData,
        cutomerDetails
    };
};

export default connect(mapStateToProps)(PaymentSlipModalSecond);

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Image, Table } from "react-bootstrap-v5";
import { useParams } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import MasterLayout from "../MasterLayout";
import TabTitle from "../../shared/tab-title/TabTitle";
import { fetchProduct } from "../../store/action/productAction";
import HeaderTitle from "../header/HeaderTitle";
import user from "../../assets/images/brand_logo.png";
import {
    getFormattedMessage,
    placeholderText,
    currencySymbolHendling,
} from "../../shared/sharedMethod";
import Spinner from "../../shared/components/loaders/Spinner";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import { fetchEmployee } from "../../store/action/employeeAction";
import { fetchDepartment } from "../../store/action/departmentAction";
import { fetchDesignation } from "../../store/action/designationAction";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import moment from "moment";
import ProfileTab from "./ProfileTab";
import Customer from "../../assets/images/Customer.png";
import LedgerReport from "./LedgerReport";
import { editCustomer, fetchCustomer, getZones } from "../../store/action/PosCustomerAction";
import InvoiceReport from "./InvoiceReport";
import StatementReport from "./StatementReport";

const CustomerDetail = (props) => {
    const { isLoading, posCustomer, fetchCustomer } =
        props;
    const { id } = useParams();
    const [mainTabValue, setMainTabValue] = useState('1');
    const [profileValue, setProfileValue] = useState('1');

    const handleMainTabChange = (event, newValue) => {
        setMainTabValue(newValue);
    };

    const handleProfileTabChange = (event, newValue) => {
        setProfileValue(newValue);
    };

    useEffect(() => {
        fetchCustomer(id);
    }, []);

    // const sliderImage = product &&
    //     product?.attributes &&
    //     product?.attributes?.empImgUrl !== "" && [
    //         product?.attributes?.empImgUrl,
    //     ]; // product?.attributes?.product_image?.imageUrls?.map((img) => img);

    const itemsValue = posCustomer?.customerDetails && {
        customerName: posCustomer?.customerDetails?.attributes?.ledgerName,
        salutation: posCustomer?.customerDetails?.attributes?.salutation,
        customerCode: posCustomer?.customerDetails?.attributes?.ledgerCode,
        address: posCustomer?.customerDetails?.attributes?.address,
        underGroup: posCustomer?.customerDetails?.attributes?.underGroup,
        area: posCustomer?.customerDetails?.attributes?.area,
        city: posCustomer?.customerDetails?.attributes?.city,
        state: posCustomer?.customerDetails?.attributes?.state,
        location: posCustomer?.customerDetails?.attributes?.location,
        gstNo: posCustomer?.customerDetails?.attributes?.regNo,
        email: posCustomer?.customerDetails?.attributes?.email,
        mobileNo: posCustomer?.customerDetails?.attributes?.mobileNo1,
        entry: posCustomer?.customerDetails?.attributes?.entryFrom,
        isActive: posCustomer?.customerDetails?.attributes?.isActive,
        // id: posCustomer?.customerDetails?.id,
    };

    return (
        <MasterLayout>
            <TopProgressBar />
            {/* <HeaderTitle
                title={getFormattedMessage("Customer Details")}
                to="/app/posCustomer"
            /> */}
            <div className="card card-body">
                <div className="d-flex mb-4 row">
                    <div className="col-lg-4 col-md-6  col-sm-12 d-flex">
                        <Image src={Customer} className="img-fluid" style={{ height: '50px', width: '50px' }} />
                        <div>
                            <h3 className="fw-bolder ">
                                {itemsValue?.customerName}
                            </h3>
                            <span className="ms-2">{"[" + itemsValue?.customerCode + "]"}</span>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-6 col-sm-12 customer-main-tab">
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={mainTabValue}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleMainTabChange} aria-label="lab API tabs example">
                                        <Tab label="Profile" value="1" style={{ color: 'black' }} />
                                        <Tab label="Account" value="2" style={{ color: 'black' }} />
                                        <Tab label="Invoices" value="3" style={{ color: 'black' }} />
                                        <Tab label="Reports" value="4" style={{ color: 'black' }} />
                                        <Tab label="Orders" value="5" style={{ color: 'black' }} />
                                    </TabList>
                                </Box>
                            </TabContext>
                        </Box>
                        <span style={{ float: "inline-end" }}><FontAwesomeIcon icon={faPenToSquare} style={{ color: "blue", marginRight: "5px" }} />Edit</span>
                    </div>
                </div>
                {mainTabValue == 1 && <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={profileValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleProfileTabChange} aria-label="lab API tabs example">
                                <Tab label="Profile" value="1" style={{ color: 'black' }} />
                                {/* <Tab label="Ledger" value="4" style={{ color: 'black' }} /> */}
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <ProfileTab isLoading={isLoading} itemsValue={itemsValue} />
                        </TabPanel>
                        {/* <TabPanel value="4">
                            <LedgerReport />
                        </TabPanel> */}
                    </TabContext>
                </Box>}

                {mainTabValue == 2 && <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={profileValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleProfileTabChange} aria-label="lab API tabs example">
                                <Tab label="Outstandings" value="1" style={{ color: 'black' }} />
                                <Tab label="Statement" value="2" style={{ color: 'black' }} />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            Outstanding
                        </TabPanel>
                        <TabPanel value="2">
                            <StatementReport />
                        </TabPanel>
                    </TabContext>
                </Box>}

                {mainTabValue == 3 && <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={profileValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleProfileTabChange} aria-label="lab API tabs example">
                                <Tab label="Invoices" value="1" style={{ color: 'black' }} />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <InvoiceReport />
                        </TabPanel>
                    </TabContext>
                </Box>}

                {mainTabValue == 4 && <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={profileValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleProfileTabChange} aria-label="lab API tabs example">
                                <Tab label="Sales Summary" value="1" style={{ color: 'black' }} />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <LedgerReport />
                        </TabPanel>
                        {/* <TabPanel value="4">
                            <LedgerReport />
                        </TabPanel> */}
                    </TabContext>
                </Box>}

                {mainTabValue == 5 && <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={profileValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleProfileTabChange} aria-label="lab API tabs example">
                                <Tab label="Order Summary" value="1" style={{ color: 'black' }} />
                                {/* <Tab label="Ledger" value="4" style={{ color: 'black' }} /> */}
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <ProfileTab isLoading={isLoading} itemsValue={itemsValue} />
                        </TabPanel>
                        {/* <TabPanel value="4">
                            <LedgerReport />
                        </TabPanel> */}
                    </TabContext>
                </Box>}
            </div>
        </MasterLayout>
    );
};

const mapStateToProps = (state) => {
    const { posCustomer } = state;
    return { posCustomer }
};

export default connect(mapStateToProps, { fetchCustomer })(CustomerDetail);

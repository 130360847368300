import apiConfig from "../../config/apiConfig";
import { setLoading } from "./loadingAction";
import {
  apiBaseURL,
  brandsActionType,
  getItemPriceHistory,
  priceLvlListActionType,
  toastType,
} from "../../constants";
import { addToast } from "./toastAction";
import { getFormattedMessage } from "../../shared/sharedMethod";
import { useNavigate } from "react-router";
import { setLoader } from "./loaderAction";

export const fetchItemPriceHistory =
  (isLoading = true, PriceListHistoryItemId, mrp, batchNo) =>
  async (dispatch) => {
    ;
    const url =
      batchNo == ""
        ? `/getItemPriceHistory?itemId=${PriceListHistoryItemId}&mrp=${mrp}&batchNo=`
        : `/getItemPriceHistory?itemId=${PriceListHistoryItemId}&mrp=${mrp}&batchNo=${batchNo}`;
    await apiConfig
      .get(url)
      .then((response) => {
        if (response?.data?.success == false) {
          dispatch(
            addToast({ text: response?.data?.message, type: toastType.ERROR })
          );
        } else {
          dispatch({
            type: getItemPriceHistory.FETCH_ITEM_PRICE_HISTORY,
            payload: response?.data?.data,
          });
          if (isLoading) {
            dispatch(setLoading(false));
          }
        }
      })
      .catch((response) => {
        if (response?.data?.message) {
          dispatch(
            addToast({
              text: response?.data?.message,
              type: toastType.ERROR,
            })
          );
        }
      });
  };

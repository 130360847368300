import { connect } from "react-redux"
import TopProgressBar from "../../shared/components/loaders/TopProgressBar"
import MasterLayout from "../MasterLayout"
import { useEffect, useState } from "react"
import TabTitle from "../../shared/tab-title/TabTitle"
import { getFormattedMessage, placeholderText } from "../../shared/sharedMethod"
import SearchComponent from "../../shared/components/SearchComponent"
import ReactDataTable from "../../shared/table/ReactDataTable"
import ActionButton from "../../shared/action-buttons/ActionButton"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap-v5"
import { useNavigate } from "react-router"
import { fetchZones } from "../../store/action/ZoneAction"
import ZoneCreate from "./zoneCreate"
import EditZone from "./EditZone"
import DeleteZone from "./DeleteZone"

const Zone = (props) => {

    const {zone,isLoading,fetchZones}=props;
    const [importEmpDeaprtment, setimportEmpDeaprtment] = useState(false);

    const [editModel, setEditModel] = useState(false);
    const [zones, setZones] = useState();
    const [deleteModel, setDeleteModel] = useState(false);
    const [isDelete, setIsDelete] = useState(null);
    
  const [filterZone, setFilterZone] = useState([]);
  const [formcode, setFormCode] = useState("M04");
  const navigate =useNavigate()
  useEffect(() => {
    ;
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility );
        if(!formCodeItems.length > 0){
            navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    } 
  }, []);

  useEffect(() => {
    setZones(zone);
    setFilterZone(zone);
  }, [zone]);


    const handleClose = (item) => {
        setEditModel(!editModel);
        setZones(item);
        // console.log("Item =>" ,item)
      };

      const onClickDeleteModel = (isDelete = null) => {
        setDeleteModel(!deleteModel);
        setIsDelete(isDelete);
      };

     
    useEffect(() => {
        fetchZones();
    },[fetchZones])

   
    const handleSearchData = (e) => {
        const { name, value } = e.target;
        const filtered_zone =
          value.length > 0
            ? zones.filter((item) =>
                item?.attributes?.name
                  ?.toLowerCase()
                  ?.includes(value?.toLowerCase())
              )
            : zones;
            setFilterZone(filtered_zone);
      };
    

    const itemsValue =
    filterZone?.filter((item)=> item?.zoneId != 0 && item != null).map((zone) => ({
        zoneId:zone?.zoneId,
        zoneName:zone?.attributes?.name,
        isActive: zone?.attributes?.isActive == true ? "Yes" : "No" ,
        createdAt:zone?.attributes?.createdAt,
        updatedAt:zone?.attributes?.updatedAt
    }))  ;

    
    const columns = [
        {
            name: getFormattedMessage('ZoneName.title'),
            selector: row => row.zoneName,
            sortField: 'zoneName',
            sortable: true,
        },
        {
          name: getFormattedMessage('globally.input.isActive.label'),
          selector: row => row.isActive,
          sortField: 'isActive',
          sortable: true,
        },
        {
            name: getFormattedMessage('react-data-table.action.column.label'),
            right: true,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: row => <ActionButton item={row} 
            goToEditProduct={handleClose}
          isEditMode={true}
             onClickDeleteModel={onClickDeleteModel} 
                 />
        }
    ];

    const [show, setShow] = useState(false);
    const handleCloseCreate = () => setShow(!show);

    const  onClick = () => {
        setShow(true)
    }


    return (
        <MasterLayout>
            <TopProgressBar/>
            <TabTitle title={placeholderText('zone.title')} />

            <div>
                <h1 className="text-white fw-bolder">List of Zones</h1>
            </div>

<div className="row">
    <div className="col-md-5 mb-3 searchBox">
        <div className="position-relative d-flex width-320">

          <input
            className="form-control ps-8"
            type="search"
            name="searchData"
            id="search"
            placeholder={placeholderText(
              "react-data-table.searchbar.placeholder"
            )}
            aria-label="Search"
            onChange={(e) => handleSearchData(e)}
            autoComplete="off"
          />
          <span className="position-absolute d-flex align-items-center top-0 bottom-0 left-0 text-gray-600 ms-3">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
      </div>    

<div className="col-md-4"></div>
<div className="col-md-3  d-flex justify-content-end" >
        <Button type="button" variant="primary" className="crt_product" onClick={onClick} >
              Create Zone
        </Button>
</div>
</div>

            <div  >
                <ReactDataTable 
                    columns={columns}
                    items={itemsValue ? itemsValue : []}
                    isLoading={isLoading}
                    totalRows={itemsValue?.length}
                    isUnitFilter
                    subHeader={false}
                />
            </div>

 {show?<ZoneCreate show={show} handleClose={handleCloseCreate}/>:""}


 
{editModel?
<EditZone
        handleClose={handleClose}
        show={editModel}
        zones={zones}
      /> : "" 
}     

<DeleteZone
        onClickDeleteModel={onClickDeleteModel}
        deleteModel={deleteModel}
        onDelete={isDelete}
      />

{/* /* <EditEmpDepartment showEdit={showEdit} handleCloseEdit={handleCloseEdit}/>:""}  */}
        </MasterLayout>
        
    )
}

const mapStateToProps = (state) => {
    const {zone,isLoading} = state
    return  {zone,isLoading}
}

export default connect(mapStateToProps,{fetchZones}) (Zone)
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import MasterLayout from "../MasterLayout";
import { editTaxSetup, fetchTaxSetup } from "../../store/action/TaxSetupAction";
import ReactDataTable from "../../shared/table/ReactDataTable";
import TopProgressBar from "../../shared/components/loaders/TopProgressBar";
import TabTitle from "../../shared/tab-title/TabTitle";
import {
  getFormattedDate,
  getFormattedMessage,
  placeholderText,
} from "../../shared/sharedMethod";
import ActionButton from "../../shared/action-buttons/ActionButton";
import CreateTaxSetup from "./CreateTaxSetup";
import EditTaxSetup from "./EditTaxSetup";
import DeleteTaxSetup from "./DeleteTaxSetup";
import { useNavigate } from "react-router";
import HeaderTitle from "../header/HeaderTitle";
import SearchComponent from "../../shared/components/SearchComponent";

const TaxSetup = (props) => {
  const { fetchTaxSetup, taxSetup, totalRecord, isLoading, allConfigData } =
    props;
  const [deleteModel, setDeleteModel] = useState(false);
  const [isDelete, setIsDelete] = useState(null);
  const [editModel, setEditModel] = useState(false);
  const [taxsetup, setTaxsetup] = useState();
  const [filteredTaxSetup, setFilteredTaxSetup] = useState([]);
  const [formcode, setFormCode] = useState("M01");
  const navigate =useNavigate()
  useEffect(() => {
    ;
    const storedFormData = localStorage.getItem("UserFormCode");

    if (storedFormData) {
      const parsedFormData = JSON.parse(storedFormData);

      if (parsedFormData.length > 0) {
        const formCodeItems = parsedFormData.filter((item) => item?.attributes?.formCode == formcode && item?.attributes?.visibility );
        if(!formCodeItems.length > 0){
            navigate("/app/dashboard");
        }
      } else {
        navigate("/app/dashboard");
      }
    } 
  }, []);

  const handleClose = (item) => {
    setEditModel(!editModel);
    setTaxsetup(item);
    
  };

  const onClickDeleteModel = (isDelete = null) => {
    setDeleteModel(!deleteModel);
    setIsDelete(isDelete);
  };

  const onChange = (filter) => {
    fetchTaxSetup(filter, true);
  };

  useEffect(() => {
    fetchTaxSetup();
  },[])

  useEffect(() => {
    setFilteredTaxSetup(taxSetup);
  }, [taxSetup]);

  const handleSearchData = (e) => {
    const { name, value } = e.target;
  
    const searchValue = value.toLowerCase().trim();
  
    const filtered_TaxSetup =
      searchValue.length > 0
        ? taxSetup.filter((item) => {
            const taxName = item?.attributes?.taxName?.toLowerCase() || "";
            const taxPercentage = item?.attributes?.taxPercentage?.toString().toLowerCase() || "";
  
            return taxName.includes(searchValue) || taxPercentage.includes(searchValue);
          })
        : taxSetup;
  
    setFilteredTaxSetup(filtered_TaxSetup);
  };
  

  const itemsValue =
    filteredTaxSetup &&
    filteredTaxSetup?.map((taxsetup) => {
      return {
        taxPercentage: taxsetup?.attributes?.taxPercentage,
        taxName: taxsetup?.attributes?.taxName,
        taxId: taxsetup?.taxId,
      };
    });

  const columns = [
    {
      name: getFormattedMessage("globally.input.taxpercentage.label"),
      selector: (row) => row.taxPercentage,
      sortField: "taxPercentage",
      sortable: true,
    },
    {
      name: getFormattedMessage("taxsetup.tax-name.info.label"),
      sortField: "taxName",
      sortable: true,
      cell: (row) => {
        return (
          <span className="badge bg-light-info">
            <span>{row.taxName}</span>
          </span>
        );
      },
    },
    {
      name: getFormattedMessage("react-data-table.action.column.label"),
      right: true,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      cell: (row) => (
        <ActionButton
          item={row}
          goToEditProduct={handleClose}
          isEditMode={true}
          onClickDeleteModel={onClickDeleteModel}
        />
      ),
    },
  ];

  return (
    <MasterLayout>
      <TopProgressBar />
      <TabTitle title={placeholderText("TaxSetup.title")} />
      <HeaderTitle title={placeholderText("ListofTaxSetUp.title")} />
      <SearchComponent
        handleSearchData={handleSearchData}
        
        AddButton={<CreateTaxSetup />}
      />
      <ReactDataTable
        columns={columns}
        items={itemsValue}
        onChange={onChange}
        isLoading={isLoading}
        AddButton={<CreateTaxSetup  />}
        // ButtonValue={getFormattedMessage("product.create.title")}
        title={getFormattedMessage("TaxSetup.title")}
        // buttonImport={true}
        totalRows={itemsValue?.length}
       to="/app/taxSetup"
        subHeader={false}
      />
      <EditTaxSetup
        handleClose={handleClose}
        show={editModel}
        taxsetup={taxsetup}
      />
      <DeleteTaxSetup
        onClickDeleteModel={onClickDeleteModel}
        deleteModel={deleteModel}
        onDelete={isDelete}
      />

      {/* <h1>Welcome</h1> */}
    </MasterLayout>
  );
};

const mapStateToProps = (state) => {
  const { taxSetup, totalRecord, isLoading, allConfigData } = state;
  return { taxSetup, totalRecord, isLoading, allConfigData };
};

export default connect(mapStateToProps, { fetchTaxSetup })(TaxSetup);

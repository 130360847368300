import { getFormattedMessage, placeholderText } from "../../shared/sharedMethod";
import { Form, InputGroup, Button } from "react-bootstrap-v5";
import { connect, useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import ReactSelect from "../../shared/select/reactSelect";
import { addCustomer, editCustomer, getZones } from "../../store/action/PosCustomerAction";
import * as EmailValidator from 'email-validator';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faPlus, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import LocationPopup from "../../shared/LocationPopup";
import ZoneForm from "../zones/ZoneForm";
import { posCustomerActionType, companyConfigActionType } from "../../constants/index";

const PosCustomerForm = (props) => {
  const { id, action, editCustomer, singleCustomer, addCustomer, to, title, getZones, posCustomer, companyConfig } = props

  const initialCenter = { lat: 8.6870727, lng: 77.7050785 };
  const [formData, setFormData] = useState({
    keywords: '',
    latitude: '',
    longitude: '',
    geoUrl: '',
    radius: 0,
    markerPosition: initialCenter,
    address: '',
    street: '',
    area: '',
    city: '',
    state: '',
    companycord: { lat: 8.6870727, lng: 77.7050785 },
    distance: ''
  });

  const [companyDetails, setCompanyDetails] = useState({});
  const [zoneList, setZoneList] = useState([]);

  const dispatch = useDispatch();

  const [CustomerValue, setCustomerValue] = useState({
    id: 0,
    customerName: "",
    customerCode: "",
    salutation:"",
    address: "",
    area: "",
    city: "",
    state: "Tamil Nadu",
    location: "Local State",
    gstNo: "",
    email: "",
    mobileNo: "",
    entry: "",
    remarks: "",
    isActive: true,
    zone: { value: 0, label: "Not Applicable" },
    searchonMap: false,
    latitude: 0,
    longitude: 0,
    geoUrl: "",
    radius: 0,
    fullAddress: "",
    distance: 0,
    zoneId: 0
  });
  const inputRefs = {
    customerName: useRef(null),
    customerCode: useRef(null),
    // salutation: useRef(null),
    address: useRef(null),
    area: useRef(null),
    city: useRef(null),
    state: useRef(null),
    location: useRef(null),
    gstNo: useRef(null),
    email: useRef(null),
    mobileNo: useRef(null),
    remarks: useRef(null),
    zone: useRef(null)
  }
  const [lastAddedZone, setLastAddedZone] = useState();

  useEffect(() => {
    if (companyConfig.companyDetail && companyConfig.companyDetail.attributes) {
      setFormData(prev => ({
        ...prev,
        companycord: { lat: companyConfig.companyDetail.attributes?.latitude || 0, lng: companyConfig.companyDetail.attributes?.longitude || 0 }
      }));
      setCompanyDetails(companyConfig.companyDetail);
      dispatch({
        type: companyConfigActionType.FETCH_COMPANY_CONFIG_SPECIFIC,
        payload: null,
      });
    }
  }, [companyConfig.companyDetail]);

  useEffect(() => {
    if (action === "edit") {
      if (posCustomer && posCustomer?.customerDetails && Object.keys(posCustomer?.customerDetails).length > 0) {
        setCustomerValue((prev) => ({
          ...prev,
          id: posCustomer?.customerDetails?.id || 0,
          customerName: posCustomer?.customerDetails?.attributes?.ledgerName || "",
          salutation: posCustomer?.customerDetails?.attributes?.salutation || "",
          customerCode: posCustomer?.customerDetails?.attributes?.ledgerCode || "",
          address: posCustomer?.customerDetails?.attributes?.address || "",
          area: posCustomer?.customerDetails?.attributes?.area || "",
          city: posCustomer?.customerDetails?.attributes?.city || "",
          state: posCustomer?.customerDetails?.attributes?.state || "Tamil Nadu",
          location: posCustomer?.customerDetails?.attributes?.location || "Local State",
          gstNo: posCustomer?.customerDetails?.attributes?.regNo || "",
          email: posCustomer?.customerDetails?.attributes?.email || "",
          mobileNo: posCustomer?.customerDetails?.attributes?.mobileNo1 || "",
          entry: posCustomer?.customerDetails?.attributes?.entryFrom || "",
          remarks: posCustomer?.customerDetails?.attributes?.remarks || "",
          isActive: posCustomer?.customerDetails?.attributes?.isActive  || true,

          // zone: zoneObj,
          zoneId: posCustomer?.customerDetails?.attributes?.zoneId || 0,
          searchonMap: posCustomer?.customerDetails?.attributes?.searchonMap || false,
          latitude: posCustomer?.customerDetails?.attributes?.latitude || 0,
          longitude: posCustomer?.customerDetails?.attributes?.longitude || 0,
          geoUrl: posCustomer?.customerDetails?.attributes?.geoUrl || "",
          radius: posCustomer?.customerDetails?.attributes?.radius || 0,
          fullAddress: posCustomer?.customerDetails?.attributes?.fullAddress || "",
          distance: posCustomer?.customerDetails?.attributes?.distance || 0
        }));
        setFormData({
          keywords: "",
          latitude: posCustomer?.customerDetails?.attributes?.latitude || initialCenter.lat,
          longitude: posCustomer?.customerDetails?.attributes?.longitude || initialCenter.lng,
          geoUrl: posCustomer?.customerDetails?.attributes?.geoUrl || "",
          radius: posCustomer?.customerDetails?.attributes?.radius || 0,
          markerPosition: { lat: posCustomer?.customerDetails?.attributes?.latitude || initialCenter.lat, lng: posCustomer?.customerDetails?.attributes?.longitude || initialCenter.lng },
          address: posCustomer?.customerDetails?.attributes?.fullAddress || "",
          distance: posCustomer?.customerDetails?.attributes?.distance || 0
        });
        dispatch({
          type: posCustomerActionType.FETCH_CUSTOMER,
          payload: null,
        });
      }
    }
  }, [posCustomer?.customerDetails]);

  useEffect(() => {
    debugger
    if (posCustomer && posCustomer?.zonesData && posCustomer?.zonesData.length > 0) {
      // let zoneObj = { value: 0, label: "Not Applicable" };
      let updatedData = [{ zoneId: 0, attributes: { name: "Not Applicable" } }, ...posCustomer?.zonesData];
      updatedData = updatedData.sort((a, b) => {
        const nameA = a?.attributes?.name?.trim()?.toLowerCase();
        const nameB = b?.attributes?.name?.trim()?.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });

      // if (lastAddedZone && Object.keys(lastAddedZone).length > 0) {
      //   const filtered = updatedData
      //     ?.filter(x => x.attributes.name === lastAddedZone.zonename)
      //     .map(x => ({ value: x.zoneId, label: x.attributes.name }));

      //   if (filtered?.length > 0) {
      //     zoneObj = filtered[0];
      //   }
      //   setCustomerValue(prev => {
      //     return { ...prev, zone: zoneObj };
      //   });
      //   setTimeout(() => {
      //     setLastAddedZone(null);
      //   }, 2000)
      // } else {
      //   setCustomerValue(prev => {
      //     return { ...prev, zone: zoneObj };
      //   });
      // }

      setZoneList(updatedData);

      dispatch({
        type: posCustomerActionType.ZONES,
        payload: null,
      });
    }
  }, [posCustomer?.zonesData]);

  useEffect(() => {
    debugger
    let zoneObj = { value: 0, label: "Not Applicable" };
    if (zoneList.length > 0) {
      if (lastAddedZone && Object.keys(lastAddedZone).length > 0) {
        const filtered = zoneList
          ?.filter(x => x.attributes.name === lastAddedZone.zonename)
          .map(x => ({ value: x.zoneId, label: x.attributes.name }));

        if (filtered?.length > 0) {
          zoneObj = filtered[0];
        }
        setCustomerValue(prev => {
          return { ...prev, zone: zoneObj };
        });
        // setTimeout(() => {
        //   setLastAddedZone(null);
        // }, 2000)
        setLastAddedZone(null);
      } else if (CustomerValue?.zoneId) {
        const filtered = zoneList
          ?.filter(x => x.zoneId === CustomerValue.zoneId)
          .map(x => ({ value: x.zoneId, label: x.attributes.name }));

        if (filtered?.length > 0) {
          zoneObj = filtered[0];
        }
        setCustomerValue(prev => {
          return { ...prev, zone: zoneObj };
        });
      } else {
        setCustomerValue(prev => {
          return { ...prev, zone: zoneObj };
        });
      }
    }
  }, [zoneList, CustomerValue.zoneId]);

  const [errors, setErrors] = useState({
    customerName: '',
    state: '',
    location: '',
    email: ""
  });

  const [zoneModel, setZoneModel] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleValidation = () => {
    let errors = {};
    let isValid = false;

    if (!CustomerValue["customerName"]) {
      errors["customerName"] = getFormattedMessage("CustomerName.input.name.validate.label");
    }

    // if (!CustomerValue["customerCode"]) {
    //   errors["customerCode"] = getFormattedMessage("CustomerName.input.code.validate.label");
    // }

    // else if (!CustomerValue["state"]) {
    //   errors["state"] = getFormattedMessage("globally.input.state.validate.label");
    // }

    else if (!CustomerValue['location']) {
      errors["location"] = getFormattedMessage("globally.input.location.validate.label");
    }

    else if (CustomerValue["email"] && !EmailValidator.validate(CustomerValue['email'])) {
      errors["email"] = getFormattedMessage("email.input.invalidate.label")
    }

    else {
      isValid = true;
    }
    setErrors(errors);
    return isValid;
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setCustomerValue((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value
    }));
  }

  const handleSearchOnChange = (e) => {
    setCustomerValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked
    }));
    setShow(true);
  }

  const locationType = [
    { value: "Local State", label: "Local State" },
    { value: "Other State", label: "Other State" },
    { value: "Other Country", label: "Other Country" }
  ];


  const handleDropdownChange = (option) => {
    setCustomerValue((prev) => ({
      ...prev,
      location: option.value
    }));
  }

  const prepareFormData = (data) => {
    let formData = {
      id: data.id,
      ledgerName: data.customerName,
      salutation:data.salutation,
      code: data.customerCode,
      altlanguage: "",
      undergroup: "CUSTOMERS",
      dob: "",
      mobileno1: data.mobileNo,
      mobileno2: "",
      regno: data.gstNo,
      email: data.email,
      address: data.address,
      area: data.area,
      city: data.city,
      state: data.state,
      country: "",
      location: data.location,
      isactive: data.isActive,
      remarks: data.remarks,
      entryfrom: data.entry,
      forSales: false,
      searchonMap: data.searchonMap,
      fullAddress: data.fullAddress,
      zoneId: data.zone ? data.zone.value : 0,
      latitude: data.latitude ? data.latitude : 0,
      longitude: data.longitude ? data.longitude : 0,
      geoUrl: data.geoUrl,
      radius: data.radius ? parseInt(data.radius) : 0,
      distance: data.distance
    }
    return formData;
  }
  const onSubmit = (e) => {
    e.preventDefault();
    const valid = handleValidation();
    if (valid) {
      if (action == "edit") {
        debugger
        editCustomer(id, prepareFormData(CustomerValue), navigate);
      }
      else {
        debugger
        addCustomer(prepareFormData(CustomerValue), navigate);
      }
      // clearField();
    }
  }
  const clearField = () => {
    navigate("/app/posCustomer")
  };

  const fetchFormData = (formValue) => {
    setFormData(formValue);
    setCustomerValue(prev => ({
      ...prev,
      latitude: formValue?.latitude ? formValue?.latitude : 0,
      longitude: formValue?.longitude ? formValue?.longitude : 0,
      geoUrl: formValue ? formValue?.geoUrl : "",
      radius: formValue?.radius ? formValue?.radius : 0,
      fullAddress: formValue ? formValue?.address : "",
      address: formValue?.street ? formValue?.street : prev?.address,
      area: formValue?.area ? formValue?.area : prev?.area,
      city: formValue?.city ? formValue?.city : prev?.city,
      state: formValue?.state ? formValue?.state : prev?.state,
      distance: formValue?.distance ? formValue?.distance : prev?.distance
    }));
  };



  const mobileNo1handleChange = (e) => {
    const value = e.target.value;

    // Allow only digits and limit the length to 15 characters
    if (/^\d*$/.test(value) && value.length <= 15) {
      setCustomerValue({ ...CustomerValue, [e.target.name]: value });
    }
  };

  const mobileNo1handleKeyDown = (e) => {
    if (e.target.name === 'mobileNo' && (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-' || e.key === "ArrowUp" || e.key === "ArrowDown")) {
      e.preventDefault();
    }
  };

  const onZoneChange = (obj) => {

    setCustomerValue({ ...CustomerValue, zone: obj });
  }

  const fetchZones = () => {
    getZones();
  };

  const handleZoneClose = (formValue) => {
    setLastAddedZone(formValue);

    setZoneModel(!zoneModel);
  };

  const handleKeyDown = (e) => {
    const { key, target } = e;
    const { name } = target;
    if (name === 'mobileNo' && (key === 'e' || key === 'E' || key === '+' || key === '-' || key === "ArrowUp" || key === "ArrowDown")) {
      e.preventDefault();
    }
    if (key === 'Enter' || key === 'ArrowDown' || key === 'Tab' || key === 'ArrowRight') {
      if (name == "customerName") {
        inputRefs.customerCode.focus();
      } else if (name == "customerCode") {
        inputRefs.address.focus();
      } else if (name == "address") {
        inputRefs.area.focus();
      } else if (name == "area") {
        inputRefs.city.focus();
      } else if (name == "city") {
        inputRefs.state.focus();
      } else if (name == "state") {
        inputRefs.location.focus();
      } else if (name == "location") {
        inputRefs.gstNo.focus();
      } else if (name == "gstNo") {
        inputRefs.email.focus();
      } else if (name == "email") {
        inputRefs.mobileNo.focus();
      } else if (name == "mobileNo") {
        inputRefs.zone.focus();
      } else if (name == "zone") {
        inputRefs.remarks.focus();
      } else if (name == "remarks") {

      }
    } else if (key === 'ArrowUp' || key === 'ArrowLeft') {
      if (name == "customerCode") {
        inputRefs.customerName.focus();
      } else if (name == "address") {
        inputRefs.customerCode.focus();
      } else if (name == "area") {
        inputRefs.address.focus();
      } else if (name == "city") {
        inputRefs.area.focus();
      } else if (name == "state") {
        inputRefs.city.focus();
      } else if (name == "location") {
        inputRefs.state.focus();
      } else if (name == "gstNo") {
        inputRefs.location.focus();
      } else if (name == "email") {
        inputRefs.gstNo.focus();
      } else if (name == "mobileNo") {
        inputRefs.email.focus();
      } else if (name == "zone") {
        inputRefs.mobileNo.focus();
      } else if (name == "remarks") {
        inputRefs.zone.focus();
      }
    }
  }

  return (
    <div>
      <div className="d-md-flex align-items-center justify-content-between mb-3">
        {title ? <h1 className="mb-0 create-title">{title}</h1> : ""}
        <div className="text-end mt-4 mt-md-0">

          {action == "edit" ? (
            <Link to={singleCustomer} className="btn btn-primary me-3 save-btn"
              style={{ width: "120px" }} onClick={onSubmit} >
              {getFormattedMessage("globally.update-btn")}
            </Link>
          ) :
            <Link to={""} className="btn btn-primary  me-3 save-btn"
              style={{ width: "120px" }} onClick={onSubmit} >
              {getFormattedMessage("globally.save-btn")}
            </Link>
          }
          {to ? (
            <Link to={to} style={{ minWidth: "120px" }} className="btn btn-outline-primary back-btn">
              {getFormattedMessage("globally.back-btn")}
            </Link>
          ) : null}
        </div>

        {/* <button className="btn btn-outline-primary back-btn">
            {getFormattedMessage("globally.back-btn")}
          </button>
         */}
        {/*       </div> */}
      </div>

      <div className="card">
        <div className="card-body py-3 pb-4">
          <Form>
            <div className="row">
              <div className="col-md-12">
                <div style={{ textAlign: "-webkit-right" }} >
                  <label className="form-check form-check-custom form-check-solid form-check-inline d-flex align-items-center my-4 mx-0 p-0 cursor-pointer custom-label">
                    <input
                      type="checkbox"
                      name="isActive"
                      className="me-3 form-check-input cursor-pointer"
                      style={{ marginLeft: "10px", width: "22px", height: "22px", marginTop: "1px" }}
                      checked={CustomerValue.isActive}
                      // ref={(el) => (inputRefs.isActive = el)}
                      // onKeyDown={(e) => handleKeyDown(e)}
                      placeholder={placeholderText(
                        "globally.input.remarks.placeholder.label"
                      )}
                      onChange={(e) => handleInputChange(e)}
                    />



                    <div className="control__indicator" />{" "}
                    {getFormattedMessage("product.input.isactive.label")}
                  </label>
                </div>

              </div>
            </div>
            <div className="row">

              <div className="col-md-6 mb-1">
                <label className="form-label">
                  {getFormattedMessage("globally.input.customerName.label"
                  )}
                </label>
                <span className="required" />
                {/* <input
                  type="text"
                  name="customerName"
                  value={CustomerValue.customerName}
                  maxLength={100}
                  // placeholder={placeholderText(
                  //   "globally.input.Ledgername.placeholder.label"
                  // )}
                  ref={(el) => (inputRefs.customerName = el)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  autoComplete="off"
                  className="form-control"
                  onChange={handleInputChange}
                  autoFocus
                /> */}


<InputGroup >
                  <Form.Control
                    as="select"
                    name="salutation"
                    //  value={supplierValue.salutation}
                    // onChange={handleChange}
                    value={CustomerValue.salutation}
                    onChange={handleInputChange}
                    style={{ color: "white", background: "#6571FF" ,width:"10%"}}
                    // className="mb-2"
                  >
                    <option style={{ color: "black", background: "white" }}>
                      Mr
                    </option>
                    <option style={{ color: "black", background: "white" }}>
                      Mrs
                    </option>
                    <option style={{ color: "black", background: "white" }}>
                      Ms
                    </option>
                  </Form.Control>

                  <Form.Control
                   type="text"
                   name="customerName"
                   value={CustomerValue.customerName}
                   maxLength={100}
                   // placeholder={placeholderText(
                   //   "globally.input.Ledgername.placeholder.label"
                   // )}
                   ref={(el) => (inputRefs.customerName = el)}
                   onKeyDown={(e) => handleKeyDown(e)}
                   autoComplete="off"
                   className="form-control"
                   onChange={handleInputChange}
                   autoFocus
                   style={{
                    width: "80%"
                  }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.customerName}
                  </Form.Control.Feedback>
                </InputGroup>

                <span className="text-danger d-block fw-400 fs-small mt-2">
                  {errors["customerName"] ? errors["customerName"] : null}
                </span>
              </div>

              <div className="col-md-6 mb-1">
                <label className="form-label">
                  {getFormattedMessage("customerCode.title")}
                </label>
                {/* <span className="required" /> */}
                <input
                  type="text"
                  name="customerCode"
                  value={CustomerValue.customerCode}
                  maxLength={50}
                  ref={(el) => (inputRefs.customerCode = el)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  // placeholder={placeholderText(
                  // "globally.input.alterLanguage.placeholder.label"
                  // )}
                  autoComplete="off"
                  className="form-control"
                  onChange={handleInputChange}
                />
                {/* <span className="text-danger d-block fw-400 fs-small mt-2">
                  {errors["customerCode"] ? errors["customerCode"] : null}
                </span> */}
              </div>

              <div className="col-md-12 mb-1" hidden={companyDetails?.attributes?.geoTracking == false}>
                <div className="d-flex justify-content-end"><i className="distance-label">Distance: {CustomerValue.distance} </i></div>
                <div className="row">
                  <div className="col-md-3 mb-4">
                    <div className="d-flex align-items-center h-100">
                      <label style={{ marginTop: 20 }} className="p-0 form-check m-0 form-check-custom form-check-solid form-check-inline d-flex align-items-center cursor-pointer custom-label">
                        <input
                          type="checkbox"
                          name="searchonMap"
                          className="me-3 form-check-input cursor-pointer"
                          style={{
                            marginLeft: "5px",
                            width: "22px",
                            height: "22px",
                            marginTop: "1px"
                          }}
                          checked={CustomerValue.searchonMap}
                          placeholder={placeholderText(
                            "globally.input.remarks.placeholder.label"
                          )}
                          autoComplete="off"
                          onChange={handleSearchOnChange}
                        />
                        <div className="control__indicator" />{" "}
                        {getFormattedMessage("companyconfig.input.issearchonmap.label")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-9" hidden={!CustomerValue.searchonMap || companyDetails?.attributes?.isSearchOn == false}>
                    <div className="location-addr-container">
                      <p className="m-0">{`${formData.address}, Lat: ${formData.latitude}, Long: ${formData.longitude}`}</p><span onClick={() => setShow(true)}> <FontAwesomeIcon color={"#6571ff"} size={"lg"} icon={faPenToSquare} /></span>
                    </div>
                  </div>
                  <LocationPopup fetchFormData={fetchFormData} mapData={formData} handleClose={() => setShow(false)} show={show}
                    title={getFormattedMessage('customer.location.popup.title')} />
                </div>
              </div>

              <div className="col-md-6 mb-1">
                <label className="form-label">
                  {getFormattedMessage("globally.input.address.label")}
                </label>

                <input
                  type="text"
                  name="address"
                  autoComplete="off"
                  className="form-control"
                  value={CustomerValue.address}
                  maxLength={100}
                  ref={(el) => (inputRefs.address = el)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  // placeholder={placeholderText(
                  // "globally.input.address.placeholder.label"
                  // )}
                  disabled={CustomerValue.searchonMap}
                  onChange={handleInputChange}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2"></span>
              </div>

              <div className="col-md-6 mb-1">
                <label className="form-label">
                  {getFormattedMessage("globally.input.area.label")}
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    name="area"
                    autoComplete="off"
                    className="form-control"
                    ref={(el) => (inputRefs.area = el)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    value={CustomerValue.area}
                    maxLength={50}
                    onChange={handleInputChange}
                    disabled={CustomerValue.searchonMap}
                  />
                </div>
                <span className="text-danger d-block fw-400 fs-small mt-2"></span>
              </div>

              <div className="col-md-6 mb-1">
                <label className="form-label">
                  {getFormattedMessage("globally.input.city.label")}
                </label>

                <input
                  type="text"
                  name="city"
                  autoComplete="off"
                  className="form-control"
                  value={CustomerValue.city}
                  ref={(el) => (inputRefs.city = el)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  maxLength={50}
                  // placeholder={placeholderText(
                  //   "globally.input.city.placeholder.label"
                  // )}
                  disabled={CustomerValue.searchonMap}
                  onChange={handleInputChange}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2"></span>
              </div>

              <div className="col-md-6 mb-1">
                <label className="form-label">
                  {getFormattedMessage("globally.input.state.label")}
                </label>
                {/* <span className="required" /> */}
                <input
                  type="text"
                  name="state"
                  autoComplete="off"
                  className="form-control"
                  ref={(el) => (inputRefs.state = el)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  value={CustomerValue.state}
                  maxLength={50}
                  disabled={CustomerValue.searchonMap}
                  onChange={handleInputChange}
                />
                {/* <span className="text-danger d-block fw-400 fs-small mt-2">
                  {errors["state"] ? errors["state"] : null}
                </span> */}
              </div>

              <div className="col-md-6 mb-1">
                <label className="form-label">
                  {getFormattedMessage("globally.input.location-label")}
                </label>
                <span className="required" />
                <ReactSelect
                  name='location'
                  value={locationType.find(option => option.value === CustomerValue.location)}
                  maxLength={50}
                  data={locationType}
                  ref={(el) => (inputRefs.location = el)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={handleDropdownChange}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2">
                  {errors["location"] ? errors["location"] : null}
                </span>
              </div>

              <div className="col-md-6 mb-1">
                <label className="form-label">
                  {getFormattedMessage("globally.input.gstNo.label")}
                </label>

                <input
                  type="text"
                  name="gstNo"
                  className="form-control"
                  autoComplete="off"
                  value={CustomerValue.gstNo}
                  maxLength={15}
                  ref={(el) => (inputRefs.gstNo = el)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  // placeholder={placeholderText(
                  //   "globally.input.gstNo.placeholder.label"
                  // )}
                  onChange={handleInputChange}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2"></span>
              </div>

              <div className="col-md-6 mb-1">
                <label className="form-label">
                  {getFormattedMessage("globally.input.email.label")}
                </label>

                <input
                  type="text"
                  name="email"
                  autoComplete="off"
                  className="form-control"
                  value={CustomerValue.email}
                  ref={(el) => (inputRefs.email = el)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  maxLength={100}
                  // placeholder={placeholderText(
                  //   "globally.input.email.placeholder.label"
                  // )}
                  onChange={handleInputChange}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2">
                  {errors["email"] ? errors["email"] : null}
                </span>
              </div>

              <div className="col-md-6 mb-1">
                <label className="form-label">
                  {getFormattedMessage("globally.input.Mobileno.label")}
                </label>

                <input
                  type="number"
                  name="mobileNo"
                  autoComplete="off"
                  className="form-control"
                  pattern="[0-9]*"
                  value={CustomerValue.mobileNo}
                  ref={(el) => (inputRefs.mobileNo = el)}
                  onKeyDown={(e) => handleKeyDown(e)}
                  htmlSize={15}
                  // placeholder={placeholderText("globally.input.phone-number1.placeholder.label")}
                  // onChange={handleInputChange}
                  onChange={(e) => mobileNo1handleChange(e)}
                // onKeyDown={mobileNo1handleKeyDown}
                // onKeyDown={mobileNo1handleKeyDown}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2"></span>
              </div>

              <div className="col-md-6 mb-1">
                <InputGroup className="flex-nowrap dropdown-side-btn">
                  <ReactSelect
                    className="position-relative"
                    id="zone"
                    // isOpen={brandIsOpen}
                    title={getFormattedMessage(
                      "customer.input.zone.label"
                    )}
                    placeholder={placeholderText(
                      "customer.input.zone.placeholder.label"
                    )}
                    defaultValue={CustomerValue.zone}
                    value={CustomerValue.zone}
                    data={zoneList}
                    errors={errors["zone"]}
                    ref={(el) => (inputRefs.zone = el)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onChange={onZoneChange}
                  />
                  <Button
                    onClick={() => setZoneModel(true)}
                    className="position-absolute model-dtn"
                    style={{ height: "45px" }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                  <span
                    className="position-absolute model-dtn1"
                  // onClick={() => { setBrandOpen(!brandIsOpen); setCatOpen(false), setGroupOpen(false); setTaxOpen(false); setPurchaseOpen(false); setSalesOpen(false) }}
                  >
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                </InputGroup>
              </div>

              {zoneModel && (
                <ZoneForm
                  data={zoneList}
                  fetchZone={fetchZones}
                  zone={CustomerValue.zone}
                  title={getFormattedMessage("customer.create.zone.title")}
                  show={zoneModel}
                  handleClose={handleZoneClose}
                />
              )}

              <div className="col-md-6 mb-1">
                <label className="form-label">
                  {getFormattedMessage("globally.input.remarks.label")}
                </label>

                <input
                  type="text"
                  name="remarks"
                  autoComplete="off"
                  className="form-control"
                  // pattern="[0-9]*"
                  value={CustomerValue.remarks}
                  // placeholder={placeholderText("globally.input.phone-number2.placeholder.label")}
                  onChange={handleInputChange}
                // onKeyDown={mobileNo2handleKeyDown}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2"></span>
              </div>

              {/* <div className="col-md-6 mb-3">
                <label className="form-label">
                  {getFormattedMessage("globally.input.entryFrom.label")}
                </label>

                <input
                  type="text"
                  name="entry"
                  autoComplete="off"
                  className="form-control"
                  // pattern="[0-9]*"
                  value={CustomerValue.entry}
                  // placeholder={placeholderText("globally.input.phone-number2.placeholder.label")}
                  onChange={handleInputChange}
                // onKeyDown={mobileNo2handleKeyDown}
                />
                <span className="text-danger d-block fw-400 fs-small mt-2"></span>
              </div> */}
            </div>


            {/* <ModelFooter
                  onEditRecord={singleLedger}
                  onSubmit={onSubmit}
                  editDisabled={false}
                  addDisabled={!ledgerValue.ledgerName}
                  to="/app/ledger"
                  clearField={clearField}
                /> */}

          </Form>
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  const { posCustomer, companyConfig } = state;
  return { posCustomer, companyConfig }
}
export default connect(mapStateToProps, { addCustomer, editCustomer, getZones })(PosCustomerForm);


import React, { useEffect, useRef, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';
import { FaBox } from 'react-icons/fa';
import { fetchCompanyConfig } from '../../store/action/companyConfigAction';
import { fetchOrderStatus } from '../../store/action/orderStatusAction';
import { addLog, fetchSalesOrderLog } from '../../store/action/salesOrderLog';
import { connect, useSelector } from 'react-redux';
import moment from 'moment';

const ChangeStatus = (props) => {
    const { show, handleClose, item, companyConfig, fetchCompanyConfig, fetchOrderStatus, fetchSalesOrderLog, salesOrderLog, singleSalesOrder, addLog, singleOrder, singleItem } = props;
    const [orderStatusOption, setOrderStatusOption] = useState([]);
    const [orderStatus, setOrderStatus] = useState(null);
    const [logHistory, setLogHistory] = useState();
    const [productList, setProductList] = useState();
    const [userId, setUserId] = useState(null);
    const [remarks, setRemarks] = useState("");
    const [hasFetched, setHasFetched] = useState(false);
    const orderStatusData = useSelector(state => state.orderStatus);

    const colorStyles = {
        control: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: orderStatusOption?.find((item) => item?.value == orderStatus)?.color,
                // borderColor: "transparent",
                color: "black",
            };
        },
        option: (styles, { data }) => {
            return {
                ...styles,
                // backgroundColor: data.color,
                // color: "black",
            };
        },
    };

    useEffect(() => {
        let data = localStorage.getItem("loginUserArray");
        setUserId(JSON.parse(data)['id']);
    }, []);

    useEffect(() => {
        if (show) {
            fetchCompanyConfig();
        }
    }, [show, fetchCompanyConfig]);

    // Fetch order status based on company config when the modal is shown
    useEffect(() => {
        if (show && companyConfig?.attributes && !hasFetched) {
            fetchOrderStatus(companyConfig.attributes.companyId, 1, 0);
            setHasFetched(true);
        }
    }, [show, companyConfig, fetchOrderStatus, hasFetched]);

    // Update order status options when order status data changes
    useEffect(() => {
        if (show && orderStatusData) {
            setOrderStatusOption(orderStatusData?.sort((a, b) => a.attributes.sequenceNo - b.attributes.sequenceNo)?.map((item) => {
                if (item.statusId == 1) {
                    return { value: item?.statusId, label: item?.attributes?.statusName, color: "rgb(115 115 115 / 50%)" }
                } else if (item.statusId == 2) {
                    return { value: item?.statusId, label: item?.attributes?.statusName, color: "rgb(7 255 213 / 50%)" }
                } else if (item.statusId == 3) {
                    return { value: item?.statusId, label: item?.attributes?.statusName, color: "rgb(40 167 69 / 50%)" }
                } else if (item.statusId == 4) {
                    return { value: item?.statusId, label: item?.attributes?.statusName, color: "rgb(255 0 24 / 65%)" }
                } else {
                    return { value: item?.statusId, label: item?.attributes?.statusName, color: "rgb(7 255 213 / 50%)" }
                }
            }));
        }
    }, [show, orderStatusData]);

    useEffect(() => {
        setLogHistory(salesOrderLog?.map((log, ind) => ({
            date: log.attributes.updateAt.split(' ')[0],
            time: log.attributes.updateAt.split(' ')[1] + ' ' + log.attributes.updateAt.split(' ')[2],
            action: ind == 0 ? `Created on ${moment(log.attributes.updateAt).format('DD-MM-YYYY')}` :`Status changed to ${log.attributes.OrderStatusName}`,
            user: log.attributes.userName,
            remarks: log.attributes.statusRemarks
        })))
    }, [salesOrderLog]);

    useEffect(() => {
        // console.log("singleSalesOrder", singleSalesOrder);
        if (singleSalesOrder) {
            setProductList(singleSalesOrder?.attributes?.salesOrder2?.map((item) => ({
                name: item?.itemName,
                description: item?.itemDesc,
                price: "₹" + parseFloat(item?.netAmount).toFixed(2),
                quantity: item?.qty + " " + item?.salesUnitName
            }))
            );
            setOrderStatus(singleSalesOrder?.attributes?.orderStatusId);
        }
    }, [singleSalesOrder]);

    const handleSave = async () => {
        let data = {
            "txNo": singleSalesOrder?.txNo,
            "slNo": 0,
            "orderStatusId": orderStatus,
            "updatedBy": userId,
            "statusRemarks": remarks,
        }
        const response = await addLog(data);
        if (response?.data?.success === true) {
            handleClose();
            setRemarks('');

        }
    }

    return (
        <Modal show={show} onHide={handleClose} size="xl" centered>
            <Modal.Body>
                {/* Close Button */}
                <Button
                    variant="light"
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        color: '#888',
                        border: 'none',
                        background: 'transparent'
                    }}
                >
                    &times;
                </Button>

                {/* Title */}
                <h1 className='fw-bolder mb-6'>{singleSalesOrder?.attributes?.orderNo} | {singleSalesOrder?.attributes?.customerName} | ₹{singleSalesOrder?.attributes?.orderValue ? parseFloat(singleSalesOrder?.attributes?.orderValue).toFixed(2) : "0.00"}</h1>

                <Row>
                    {/* Left Section: Product List and Status Remarks */}
                    <Col xs={12} md={6} style={{ borderRight: '1px solid #ccc', paddingRight: '15px' }}>
                        {/* Product List */}
                        <div style={{ minHeight: '200px', maxHeight: '200px', overflowY: 'auto', marginBottom: '15px' }}>
                            {productList?.map((product, index) => (
                                <div key={index} className="d-flex align-items-center mb-2">
                                    <FaBox style={{ fontSize: '1.5em', marginRight: '10px', color: '#888' }} />
                                    <div>
                                        <div><strong>{product.name}</strong> | {product.quantity} | {product.price}</div>
                                        <div style={{ fontSize: '0.9em', color: '#888' }}>{product.description}</div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Status Remarks */}
                        {singleOrder?.attributes?.statusRemark &&
                            <Form.Group controlId="statusRemarks" className={singleItem?.billStatus == 1 ? "d-none" : ""}>
                                <Form.Label>Status Remarks</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={(e) => setRemarks(e.target.value)} disabled={singleItem?.billStatus == 1}/>
                            </Form.Group>}
                    </Col>

                    {/* Right Section: Order Status Dropdown and Log History */}
                    <Col xs={12} md={6} style={{ paddingLeft: '15px' }}>
                        {/* Order Status Dropdown */}
                        <div className="d-flex justify-content-end mb-3">
                            <Select
                                className="w-50 orderStatus"
                                value={orderStatusOption.find(option => option.value === orderStatus)}
                                options={orderStatusOption}
                                onChange={(selected) => setOrderStatus(selected?.value)}
                                placeholder="Status"
                                styles={colorStyles}
                                isDisabled={singleItem?.billStatus == 1}
                            />
                        </div>

                        {/* Log History */}
                        <div style={{ maxHeight: '270px', minHeight: '200px', overflowY: 'auto' }}>
                            <Timeline position="right">
                                {logHistory?.map((log, index) => (
                                    <TimelineItem key={index}>
                                        <TimelineOppositeContent style={{ flex: 0.2, fontSize: '0.8em', color: '#888' }}>
                                            {log.date} {log.time}
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot />
                                            {index < logHistory.length - 1 && <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent style={{ fontSize: '0.9em' }}>
                                            {log.action} by <strong>{log.user}</strong>
                                            {log.remarks && <div style={{ marginTop: '5px', fontStyle: 'italic' }}> {"(" + log.remarks + ")"}</div>}
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>

            {/* Modal Footer */}
            { singleItem?.billStatus != 1 &&
                <Modal.Footer className='justify-content-center'>
                    <Button variant="success" onClick={handleSave}>Save</Button>
                    <Button variant="danger" onClick={handleClose}>Cancel</Button>
                </Modal.Footer>
            }
        </Modal>
    );
};

const mapStateToProps = (state) => {
    const { companyConfig, singleOrder } = state;
    return { companyConfig, singleOrder };
};

export default connect(mapStateToProps, { fetchCompanyConfig, fetchOrderStatus, fetchSalesOrderLog, addLog })(ChangeStatus);
